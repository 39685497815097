<template>
  <div>
    <div>
      <van-popup v-if="false" v-model="showMap" position="right" :style="{ height: '100%' ,width:'100%'}">
        <!--      <baiduMap v-bind:popup-show.sync="showMap"-->
        <!--                v-bind:map-search.sync="mapSearchData"-->
        <!--                :map-placeholder.sync="mapPlaceholder">-->
        <!--      </baiduMap>-->
        <!--      <baiduMap :popup-show.sync="showMap"-->
        <!--                :direction="showType"-->
        <!--                :mapPlaceholder="mapPlaceholder"-->
        <!--                :mapSearch="mapSearchData"-->
        <!--                :onMapSuccess="onMapSuccess">-->
        <!-- <baiduMap v-bind:popup-show.sync="showMap"
                  :direction="showType"
                  :center="center"
                  :city="city"
                  :defaultAddress="defaultAddress"
                  @onMapSuccess="onMapSuccess(arguments)">
        </baiduMap> -->
      </van-popup>
      <van-notice-bar style="color:#E65041;font-size:12px;"
                      wrapable
                      background="#FFF9F3"
                      :scrollable="false">
        温馨提醒：填写充足且准确的信息，更有利于匹配车辆找回失物
      </van-notice-bar>
      <van-popup v-model="showSexPicker"
                 overlay-class="popup"
                 :close-on-click-overlay="false"
                 position="bottom"
                 :overlay="true">
        <van-picker title="选择性别" show-toolbar
                    :columns="sexPickerColumns"
                    @cancel="showSexPicker=false"
                    @confirm="onConfirmSex"></van-picker>
      </van-popup>
      <van-popup v-model="showGoodPositionPicker"
                 :close-on-click-overlay="false"
                 position="bottom" :overlay="true">
        <van-picker title="选择物品所在位置" show-toolbar
                    :columns="goodPositionColumns"
                    @cancel="showGoodPositionPicker = false"
                    @confirm="onConfirmGoodPosition"></van-picker>
      </van-popup>
      <van-popup v-model="showTimePicker"
                 :close-on-click-overlay="true"
                 position="bottom"
                 :overlay="true">
        <van-datetime-picker
          v-model="onTimePicker"
          type="datetime"
          @cancel="showTimePicker=false"
          @confirm="onConfirmOnTime"
          :min-date="minDate"
          :max-date="maxDate"></van-datetime-picker>
      </van-popup>
      <van-popup v-model="showPassengerPositionPicker"
                 :close-on-click-overlay="false"
                 position="bottom" :overlay="true">
        <van-picker title="选择乘客位置"
                    show-toolbar
                    :columns="passengerPositionColumns"
                    @cancel="showPassengerPositionPicker = false"
                    @confirm="onConfirmPassengerPosition"></van-picker>
      </van-popup>
      <!--失主信息-->
      <div class="divider">
        <div class="title">
          <img class="title-img" src="@/assets/img/lost/owner-icon.png" alt="">
          失主信息
        </div>
      </div>
      <van-cell-group>
        <van-field
          v-model="form.city"
          required
          label="所在城市"
          placeholder="请选择"
          is-link
          readonly
          @click="showCityPicker = true">
        </van-field>
        <van-field
          required
          v-model="form.owner"
          label="姓名"
          clearable
          maxlength="6"
          placeholder="请输入">
        </van-field>
        <van-field
          v-if="layout === 'full' "
          v-model="form.sex"
          required
          label="性别"
          placeholder="请选择"
          is-link
          readonly
          @click="showSexPicker = true">
        </van-field>
        <van-field
          class="field-phone"
          required
          type="digit"
          maxlength="11"
          v-model="form.phone"
          label="联系电话"
          clearable
          placeholder="请输入能联系上您的手机号码"
          >
        </van-field>
      </van-cell-group>
      <!--失物信息-->
      <div class="divider">
        <div class="title">
          <img class="title-img" src="@/assets/img/lost/article-icon.png" alt="">
          失物信息
        </div>
      </div>
      <van-cell-group>
        <!--失物描述-->
        <van-field
          required
          v-model="form.propertyName"
          label="物品描述"
          clearable
          placeholder="例如：一个黑色的背包"
          rows="1"
          autosize></van-field>
  
        <!--失物数量-->
        <van-field
          v-if="layout === 'full'"
          v-model="form.amount"
          label="失物数量"
          clearable
          type="number"
          placeholder="请输入">
        </van-field>
  
        <van-field
          v-model="form.propertyPosition"
          label="物品位置"
          placeholder="请选择"
          is-link
          readonly
          @click="showGoodPositionPicker = true"></van-field>
      </van-cell-group>
      <!--行程信息-->
      <div class="divider">
        <div class="title">
          <img class="title-img" src="@/assets/img/lost/location-icon.png" alt="">
          行程信息
        </div>
      </div>
      <van-cell-group>
        <van-field
          :required="layout === 'full'"
          v-if="layout === 'full' "
          v-model="form.getOnDateMinute"
          label="上车时间"
          @click="showTimePickerMode = 'onTime';showTimePicker  = true"
          placeholder="请选择"
          readonly
          is-link></van-field>
        <van-field
          :required="layout === 'full'"
          v-if="layout === 'full' "
          v-model="form.getOffDateMinute"
          label="下车时间"
          @click="showTimePickerMode = 'offTime'; showTimePicker  = true"
          readonly
          placeholder="请选择"
          is-link></van-field>
        <van-field
          readonly
          @focus="onUpSite('upsite')"
          required
          v-model="form.getOnLocation"
          label="上车地点"
          placeholder="地点请描述至具体单位或建筑"
          rows="1"
          autosize
          type="textarea"
          clearable></van-field>
  
        <van-field
          readonly
          @focus="onDownSite('downsite')"
          required
          v-model="form.getOffLocation"
          label="下车地点"
          placeholder="地点请描述至具体单位或建筑"
          rows="1"
          autosize
          type="textarea"
          clearable></van-field>
  
        <van-field
          v-if="!form.invoice"
          type="textarea"
          v-model="form.line"
          label="乘车路线"
          required
          placeholder="描述途经地点更利于匹配到车辆，例如：车子大概下午3点经过民族大道金湖广场，然后从竹溪立交桥上厢竹大道"
          rows="3"
          autosize
          clearable></van-field>
  
        <van-field
          v-if="layout === 'full' "
          v-model="form.cost"
          label="车费(元)"
          type="number"
          placeholder="准确的车费金额更利于匹配车辆"
          clearable></van-field>
  
      </van-cell-group>
      <!--发票-->
      <div class="divider" v-if="layout === 'simple' ">
        <div class="title">
          <img class="title-img" src="@/assets/img/lost/invoice-icon.png" alt="">
          上传发票
        </div>
  
        <div class="content upload-box" style="position: relative">
          <van-uploader
            v-model="image1FileList"
            preview-size="100%"
            :after-read="onClickUploadFile"
            accept="image/*"
            :max-count="1"
            class=" flex"
            v-if="image1FileList.length == 0"
            style="width: 100%;height: 100%;align-items: center;justify-content: center"
          >
            <div class="flex" style="justify-content: center;align-items: center">
              <div class="icon-upload"></div>
              <div class="tip">点击上传</div>
            </div>
          </van-uploader>
          <img :src="item.url" v-for="(item,index) of image1FileList" :key="index"
               style="width: 80%;height: 80%;object-fit: contain" @click.stop="preImg(item.url)">
          <van-icon name="cross" v-if="image1FileList.length" color="#fcf9f9"
                    style="position: absolute;top: 5px;right: 10px;background-color: gray;border-radius: 50%"
                    @click="delet"/>
        </div>
      </div>
      <div class="divider" v-if="layout === 'full' ">
        <div class="title">
          <img class="title-img" src="@/assets/img/lost/info-icon.png" alt=""> 
          乘客、司机、车辆信息
        </div>
      </div>
      <van-cell-group v-if="layout === 'full' ">
        <van-field
          v-model="form.passengerDesc"
          label="乘客描述"
          placeholder="例如：1男1女"
          clearable>
        </van-field>
        <!-- <van-field
          v-model="form.driverName"
          label="司机姓名"
          placeholder="如不记得，可不填"
          clearable>
        </van-field> -->
        <van-field
          v-model="form.seat"
          label="乘客位置"
          placeholder="请选择"
          is-link
          readonly
          @click="showPassengerPositionPicker = true"></van-field>
          <!--选择车身颜色-->
          <van-popup v-model="showCarColorPicker"
                     :close-on-click-overlay="false"
                     position="bottom" :overlay="true">
            <van-picker title="选择车身颜色" show-toolbar
                        :columns="carColorPickerColumns"
                        @cancel="showCarColorPicker = false"
                        @confirm="onConfirmCarColor"></van-picker>
          </van-popup>
          <!--选择司机性别-->
          <van-popup v-model="showDriverSexPicker"
                     :close-on-click-overlay="false"
                     position="bottom" :overlay="true">
            <van-picker title="选择司机性别" show-toolbar
                        :columns="sexPickerColumns"
                        @cancel="showDriverSexPicker = false"
                        @confirm="onConfirmDriverSex"></van-picker>
          </van-popup>
          <!--司机性别-->
          <van-field
            v-model="form.driverSex"
            label="司机性别"
            placeholder="请选择"
            @click="showDriverSexPicker = true"
            is-link
            readonly>
          </van-field>
          <!-- 车牌号   -->
          <!-- <van-field
            v-model="form.plateNumber"
            label="车牌号码"
            placeholder="如不记得，可不填"
            clearable>
          </van-field> -->
          <!--车身颜色-->
          <van-field
            v-model="form.carColor"
            label="车身颜色"
            placeholder="请选择"
            @click="showCarColorPicker = true"
            is-link
            readonly>
          </van-field>
      </van-cell-group>
      
      <!-- <van-cell-group v-if="layout === 'full' ">
      </van-cell-group> -->
      <van-row style="background-color: #fff;margin-top: 20px;">
        <van-col :span="showBackButton ? 8 : 24" v-if="showBackButton">
          <van-button
            round class="back-button" block @click="onClickPreviousStep">
            返回修改
          </van-button>
        </van-col>
        <van-col :span="showBackButton ? 16 : 24">
          <van-button
            round class="submit" block @click="onClickNextToCheckPage">
            {{ submitButtonText }}
          </van-button>
        </van-col>
      </van-row>
      <!-- 城市选择 -->
      <van-popup v-model="showCityPicker"
        overlay-class="popup"
        :close-on-click-overlay="false"
        position="bottom"
        :overlay="true">
          <van-picker title="选择城市" 
            show-toolbar
            :default-index="curCityIndex"
            value-key="placeName"
            :columns="cityList"
            @cancel="onCancelCity"
            @confirm="onConfirmCity">
          </van-picker>
      </van-popup>
    </div>
    <!-- TODO 使用高德地图 -->
    <!-- 选上下车点 高德地图 -->
    <van-popup  v-model="showMap" position="right" :style="{ height: '100%' ,width:'100%'}">
      <div class="contents">
        <div class="search" style="margin-right: 16px">
          <van-icon name="arrow-left" size="24px" style="padding: 8px" @click="searchCancel"/>
          <div style="flex:1; border: 1px solid #ccc; border-radius: 8px; overflow: hidden;">
            <van-field
              v-model="searchWord"
              clearable
              :placeholder="placeholderWorld"
              @input="onSearchChange">
            </van-field>
          </div>
          <div class="sure" @click="onSure">确认</div>
          <a v-if="!ifMap" @click="ifMap = true;" style=" min-width: 40px; margin-left: 8px; margin-right: 0px; color: green">地图</a>
        </div>
        <div id="mymap" v-show="ifMap">
          <img id="controlImg" src="@/img/down@2x.png" alt="" style="width:40px;height:40px;position: absolute;z-index: 999;left: 50%;top:50%;transform: translate(-20px,-40px)">
        </div>
        <div id="myresult">
          <div class="resultItem" v-for="item in searchData" :key="item.title+item.lng" @click="choseItem(item)">
            <span class="resultP">{{ item.title }}</span>
            <span class="resultP2">{{ item.address }}</span>
          </div>
        </div>
      </div>
    </van-popup>



  </div>
</template>

<script>
  import OSS from 'ali-oss'
  import moment from 'moment';
  import Vue from 'vue'
  import {
    ActionSheet,
    Cell,
    CellGroup,
    DatetimePicker,
    Dialog,
    Field,
    Icon,
    ImagePreview,
    List,
    NoticeBar,
    Picker,
    Popup,
    Tab,
    Tabs,
    Tag,
    Toast,
    Uploader
  } from 'vant';
  import strulil from '@/utils/strutil';
  import {mapActions, mapState} from 'vuex';
  import * as _ from 'lodash';
  import baiduMap from "@/views/taxi/map";
  import lostApi from "@/store/lostApi";
  Vue.use(ImagePreview);
  Vue.use(Uploader);
  Vue.use(Dialog);
  Vue.use(Tag);
  Vue.use(Icon);
  Vue.use(Toast);
  Vue.use(NoticeBar);
  Vue.use(List);
  Vue.use(Tab).use(Tabs);
  Vue.use(DatetimePicker);
  Vue.use(Popup);
  Vue.use(Toast);
  Vue.use(ActionSheet);
  Vue.use(Picker);
  Vue.use(Field);
  Vue.use(Cell).use(CellGroup);


  export default {
    components: {baiduMap},
    props: {
      data: Object,
      layout: {
        type: String,
        default: 'full'
      },
      confirmText: {
        type: String,
        default: '下一步'
      },
      showBackButton: {
        type: Boolean,
        default: false
      }
    },
    name: "LostForm",
    data() {
      return {
        form: this.data || {
          cityCode: '', //城市码
          city: '', //城市名
          owner: '', // 姓名
          sex: '', // 性别
          phone: '', // 手机号
          getOnDateMinute: '', // 上车时间

          getOffDateMinute: null, // 下车时间
          getOnLocation: null, // 上车点
          getOnLat: null,
          getOnLng: null,
          getOffLocation: null, // 下车点
          getOffLat: null,
          getOffLng: null,

          line: '', // 乘车路线
          driverSex: '',
          driverName:'', //司机名字
          plateNumber: '', //车牌号
          carColor: '', // 车身颜色
          propertyName: '', // 物品描述
          propertyPosition: '', // 物品所在位置
          amount: '', // 物品数量
          passengerDesc: '', // 乘客描述
          seat: '',// 乘客位置
          cost: undefined, // 车费
          invoiceImgHttpPath: null, // 上传图片域名
          invoiceImgPath: null, // 上传图片地址
        },
        showMap: false,
        mapSearchData: {},
        showType: '',
        mapPlaceholder: '',

        submitButtonText: this.confirmText || '下一步',

        submittedOrder: null,
        onTimePicker: new Date(),
        maxDate: new Date(),
        minDate: moment().subtract(30, 'days').toDate(),

        showSexPicker: false,
        sexPickerColumns: ['男', '女'],
        showTimePickerMode: 'onTime', // onTime, offTime

        sexActions: [
          {
            name: '男'
          }, {
            name: '女'
          },
        ],
        showTimePicker: false,
        showDriverSexPicker: false,
        showCarColorPicker: false,
        carColorPickerColumns: [
          {
            text: '未知'
          },
          {
            text: '绿色'
          }, {
            text: '红灰'
          }, {
            text: '绿灰'
          }, {
            text: '红色'
          }, {
            text: '阳光金'
          }, {
            text: '柠檬黄闪光银'
          }, {
            text: '薄荷蓝'
          }, {
            text: '蓝灰'
          }, {
            text: '蓝色'
          }, {
            text: '橘色'
          },
        ],
        showGoodPositionPicker: false,
        goodPositionColumns: [
          {
            text: '前座'
          },
          {
            text: '后座'
          },
          {
            text: '后尾箱'
          }
        ],
        showPassengerPositionPicker: false,
        passengerPositionColumns: [
          {
            text: '前座'
          },
          {
            text: '后座'
          }, {
            text: '前座和后座'
          },
        ],
        client: null, // oss
        clientToast: null,
        image1FileList: [], // 图片预览数组
        imgHttp: null, // oss 协议头 http:// xxxxxx


        cityCenterData: {},
        center: {
          lat: 22.8177,
          lng: 108.366407
        },
        city: '南宁市',
        defaultAddress: null,
        cityList: [], //城市列表
        showCityPicker: false, //显示城市列表选择器
        curCityIndex: null, //确认选择了的城市索引


        // 高德地图相关 -s
        placeholderWorld: '',
        searchWord: null,
        //搜索数据
        searchData: [],
        ifMap: true,
        cpoint: [],//中心点
        cpointAddress: null, //中心点地址
        map: null,
        currentCity: {}, //当前城市
        currentLnglat: [], //当前定位
        // 高德地图相关 -e
      }
    },
    computed: {
      ...mapState({
        userInfo: 'userInfo',
      }),
      isPhoneInputValid() {
        return strulil.isMobile(this.form.phone);
      },

    },
    watch: {
      'data': {
        handler(val) {
          console.log('[LostForm] form changed', val);
          this.form = val;
        },
        immediate: true,
        deep: true
      },
      'mapSearchData': {
        handler(val) {
          if (!_.isEmpty(val)) {
            if (this.showType == 'upsite') {
              this.form.getOnLocation = val.address;
              this.form.getOnLat = val.lat;
              this.form.getOnLng = val.lng;
            } else if (this.showType == 'downsite') {
              this.form.getOffLocation = val.address;
              this.form.getOffLat = val.lat;
              this.form.getOffLng = val.lng;
            }
          }

        },
        deep: true,
      },
      //  监听上传图片的变化
      'form.invoiceImgPath': {
        handler(val) {
          console.log('invoiceImgPath变化==>', val)
          if (!val) {
            this.form.invoiceImgHttpPath = null
            this.image1FileList = []
          } else {
            this.form.invoiceImgHttpPath = this.imgHttp + val
            // this.image1FileList = [ { url: this.form.invoiceImgHttpPath }]
          }
        },
      },
      'form.invoiceImgHttpPath': {
        handler(val) {
          console.log('HTTPval==>', val)
          if (val) {
            this.image1FileList = [{url: val.replace(/http:\/\//g, 'https://')}]
          } else {
            this.image1FileList = []
          }
        },
        immediate: true
      },
      image1FileList: {
        handler(val) {
          console.log('watch image1FileList', val)
          if (_.isEmpty(val)) {
            this.form.invoiceImgPath = null
          }
        }
      },

      cityCenterData: {
        handler(val) {
          const {address, lat, lng} = val;
          if (address) {
            this.$set(this, 'defaultAddress', address);
          }
          if (lat && lng)
            this.center = {lat, lng};
        },
        // immediate: true,
        deep: true
      },
      // 'searchWord': {
      //   handler(newVal, val) {
      //     if (newVal == '') {//&&newVal!=undefined
      //       // this.suggestion(newVal);
      //     } else {
      //       if (this.ifMap) {
      //           //加载输入提示插件
      //         var autoComplete
      //         AMap.plugin('AMap.AutoComplete', () => {
      //           console.log('当前城市',this.currentCity.name );
      //           let autoOptions = {
      //               city: this.currentCity.name || '南宁市'
      //           };
      //           autoComplete = new AMap.AutoComplete(autoOptions);
      //           autoComplete.search(newVal, (status, result) => {
      //               console.log('result=>', result)
      //               // 搜索成功时，result即是对应的匹配数据
      //               this.searchData = [];
      //               (result.tips || []).forEach(item => {
      //                   let obj = {
      //                       title: item.name,
      //                       address: item.address.length == 0 ? item.district : item.address,
      //                       lng: item.location.lng,
      //                       lat: item.location.lat,
      //                   }
      //                   this.searchData.push(obj)
      //                 })
      //                 console.log('this.searchData：', this.searchData)
      //           })
      //         });
      //       }
      //     }
      //   },
      // },
      'cpoint': {
        handler(newVal, val) {
          var placeSearch = new AMap.PlaceSearch({
              city: this.city
          })
          console.log('cpoint变化了',newVal);

          this.geocoder(newVal);
        },
        deep: true,
      },
    },
    mounted() {
      this.getCurrentCity()
      this.initCityList() //初始化城市列表数据
      this.loadOSSClient();
    },
    methods: {
      ...mapActions({
        prepayLostOrder: 'prepayLostOrder',
        loadLostOrders: 'loadLostOrders',
        loadLostServicePrice: 'loadLostServicePrice'
      }),
      onSearchChange(val){
        if (val == '') {//&&val!=undefined
            // this.suggestion(val);
          } else {
            if (this.ifMap) {
                //加载输入提示插件
              var autoComplete
              AMap.plugin('AMap.AutoComplete', () => {
                console.log('当前城市',this.currentCity.name );
                let autoOptions = {
                    city: this.currentCity.name || '南宁市'
                };
                autoComplete = new AMap.AutoComplete(autoOptions);
                autoComplete.search(val, (status, result) => {
                    console.log('result=>', result)
                    // 搜索成功时，result即是对应的匹配数据
                    this.searchData = [];
                    (result.tips || []).forEach(item => {
                        let obj = {
                            title: item.name,
                            address: item.address.length == 0 ? item.district : item.address,
                            lng: item.location.lng,
                            lat: item.location.lat,
                        }
                        this.searchData.push(obj)
                      })
                      console.log('this.searchData：', this.searchData)
                })
              });
            }
          }
      },


      //初始化可选城市列表
      async initCityList(){
        try {
          const { status,data } = await lostApi.getSwAdministrativeDivision()
          if(status === '0'){
            console.log('---可选城市---', data);
            this.cityList = data
          }
        } catch (error) {}
      },
      //确认选择城市
      onConfirmCity(value,index){
        this.form.cityCode = value.areaNumber
        this.form.city = value.placeName
        this.curCityIndex = index
        this.showCityPicker = false
      },
      // 取消选择城市，回滚到上一个选择了的城市
      onCancelCity(){
        this.showCityPicker = false
      },
      /**
       * 获取当前城市信息
       */
      getCurrentCity(){
        console.log('获取当前位置城市');
        // if(!_.isEmpty(city.value)){
        //   console.log('---缓存有当前城市信息--刷新页面不重新获取当前定位城市---');
        //   return 
        // }
        AMap.plugin("AMap.Geolocation", () => {
          var geolocation = new AMap.Geolocation({
            // 是否使用高精度定位，默认：true
            enableHighAccuracy: true,
            // 设置定位超时时间，默认：无穷大
            timeout: 10000,
            buttonOffset: new AMap.Pixel(10, 20), // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
            zoomToAccuracy: true, // 定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
            buttonPosition: 'RB' // 定位按钮的排放位置, RB表示右下
          });
          //只能获取当前用户所在城市和城市的经纬度
          geolocation.getCityInfo((status, result) => {  
            if (status == "complete") {
              // console.log("---城市和城市的经纬度---",result)
              this.currentCity =  {
                adcode: result.adcode,
                name: result.city,
                province: result.province,
                location:{
                  longitude: result.position[0],
                  latitude: result.position[1],
                }
              }
              console.log('---当前城市信息---',this.currentCity);
            }
          })
          //获取用户当前的精确位置
          geolocation.getCurrentPosition((status, result) => {  
            console.log('精准位置状态',status);
            if (status == "complete") {
              console.log("精准位置信息",result)
              const { position={} } = result
              if(!_.isEmpty(position)){
                this.currentLnglat = [position.lng,position.lat]
              }
              
              
              
            }
          })
        })
      },
      // TODO 使用高德地图
      // 初始化地图
      initMap() {
        return new Promise((resolve, reject) => {
          if( !this.map ){
            this.map = new AMap.Map("mymap", {
              resizeEnable: true,
              zoom: 13,
              
            });
            if(!_.isEmpty(this.currentLnglat)){
              this.$nextTick(()=>{
                console.log('初始化地图，移动到当前位置',this.currentLnglat);
                this.map.setCenter(this.currentLnglat); //设置地图中心点
              })
            }
            this.map.on('moveend', () => {
              let location = this.map.getCenter();
              console.warn('zoom：', this.map.getZoom())
              this.cpoint = [location.getLng(), location.getLat()];
              console.log('移动');
            });
            resolve()
          }else{
            this.map.resize();
          }
        })
        
      },
      searchCancel() {
        this.showMap = false
      },
      focus() {
        this.ifMap = false;
      },
      onSure(){
        if(this.searchWord){
          if (this.showType == 'upsite') {
            this.form.getOnLocation = this.cpointAddress;
            this.form.getOnLng = this.cpoint[0];
            this.form.getOnLat = this.cpoint[1];
          } else if (this.showType == 'downsite') {
            this.form.getOffLocation = this.cpointAddress;
            this.form.getOffLng = this.cpoint[0];
            this.form.getOffLat = this.cpoint[1];
          }

        }else{
          if (this.showType == 'upsite') {
            this.form.getOnLocation = null;
            this.form.getOnLng = null;
            this.form.getOnLat = null;
          } else if (this.showType == 'downsite') {
            this.form.getOffLocation = null;
            this.form.getOffLng = null;
            this.form.getOffLat = null;
          }
        }
        this.showMap = false
      },
      choseItem(info) {
        console.log('地点信息数据', info);
        if (this.showType == 'upsite') {
          this.form.getOnLocation = info.title || null;
          this.form.getOnLng = info.lng;
          this.form.getOnLat = info.lat;
          
        } else if (this.showType == 'downsite') {
          this.form.getOffLocation = info.title || null;
          this.form.getOffLng = info.lng;
          this.form.getOffLat = info.lat;
        }
        this.map.setCenter([info.lng,info.lat]); //设置地图中心点
        this.showMap = false
      },
      //地理解析
      geocoder(lnglat,mode) {
        let Geocoder = new AMap.Geocoder({extensions: 'all'});
        Geocoder.getAddress(lnglat, (status, result) => {
          console.log('逆地理解析', result);
          if (status === 'complete') {
            let formattedAddress = result.regeocode.formattedAddress.replace(`广西壮族自治区${this.city}`, '');
            if(mode != 'init'){
              this.searchWord = formattedAddress
              this.cpointAddress = formattedAddress
              // if (this.showType == 'upsite') {
              //   this.form.getOnLocation = formattedAddress;
              //   this.form.getOnLng = this.cpoint[0];
              //   this.form.getOnLat = this.cpoint[1];
              // } else if (this.showType == 'downsite') {
              //   this.form.getOffLocation = formattedAddress;
              //   this.form.getOffLng = this.cpoint[0];
              //   this.form.getOffLat = this.cpoint[1];
              // }
            }
            this.searchData = [];
            result.regeocode.pois.forEach(item => {
                let obj = {
                    title: item.name,
                    address: item.address,
                    lng: item.location.lng,
                    lat: item.location.lat,
                }
                this.searchData.push(obj);
            })

          }
        })
      },
      // TODO 使用高德地图
      

      onMapSuccess(data) {
        if (!_.isEmpty(data)) {
          const mapSearchData = data[0];
          const type = data[1]
          if (this.showType == 'upsite') {
            this.form.getOnLocation = mapSearchData.title ? mapSearchData.title : mapSearchData.address || null;
            this.form.getOnLat = mapSearchData.lat;
            this.form.getOnLng = mapSearchData.lng;
          } else if (this.showType == 'downsite') {
            this.form.getOffLocation = mapSearchData.title ? mapSearchData.title : mapSearchData.address || null;
            this.form.getOffLat = mapSearchData.lat;
            this.form.getOffLng = mapSearchData.lng;
          }
          if (this.showType != type)
            this.defaultAddress = null;
        }
      },

      onUpSite(type) {
        this.showMap = true;
        this.showType = type;
        this.placeholderWorld = '搜索上车地点';
        this.searchWord = this.form.getOnLocation
        this.onSearchChange(this.searchWord)
        this.$nextTick( async ()=>{
          //初始化高德地图
          this.initMap() 
          if (this.form.getOnLocation) {
            this.cpoint = [this.form.getOnLng, this.form.getOnLat]; //中心点坐标
            this.$nextTick(()=>{
              this.map.setCenter(this.cpoint); //设置地图中心点
            })
          }else{
            const cityLnglat = [this.currentCity?.location?.longitude, this.currentCity?.location?.latitude]
            //如果取不到当前位置，那就用当前城市中心点
            const lnglat = !_.isEmpty(this.currentLnglat) ? this.currentLnglat : cityLnglat
            this.$nextTick(()=>{
              this.map.setCenter(lnglat); //设置地图中心点
            })
            this.geocoder(lnglat, 'init') //获取附近位置列表
          }
        })
        
        
        this.mapPlaceholder = '搜索上车地点';
        this.mapSearchData = {
          address: this.form.getOnLocation,
          lat: this.form.getOnLat,
          lng: this.form.getOnLng,
        }
        if (this.form.getOnLat && this.form.getOnLng) {
          this.cityCenterData = {
            address: this.form.getOnLocation,
            lat: this.form.getOnLat,
            lng: this.form.getOnLng,
          }
        } else {
          this.cityCenterData = {}
        }
      },
      onDownSite(type) {
        console.log('点击下车点');
        this.showMap = true;
        this.showType = type;
        this.placeholderWorld = '搜索下车地点'
        this.searchWord = this.form.getOffLocation
        this.onSearchChange(this.searchWord)
        this.$nextTick( ()=>{
          console.log('进来了');
          //初始化高德地图
          this.initMap() 
          console.log('下车点',this.form.getOffLocation);
          if (this.form.getOffLocation) {
            this.cpoint = [this.form.getOffLng, this.form.getOffLat]; //中心点坐标
            console.log('地图中心点',this.cpoint);
            this.$nextTick(()=>{
              this.map.setCenter(this.cpoint); //设置地图中心点
            })
          }else{
            const cityLnglat = [this.currentCity?.location?.longitude, this.currentCity?.location?.latitude]
            const lnglat = !_.isEmpty(this.currentLnglat) ? this.currentLnglat : cityLnglat
            console.log('城市',cityLnglat);
            console.log('当前',this.currentLnglat);
            this.$nextTick(()=>{
              console.log('要移动到的位置',lnglat);
              this.map.setCenter(lnglat); //设置地图中心点
            })
            this.geocoder(lnglat, 'init')
          }
        })


       
        this.mapPlaceholder = '搜索下车地点';
        this.mapSearchData = {
          address: this.form.getOffLocation,
          lat: this.form.getOffLat,
          lng: this.form.getOffLng,
        }
        if (this.form.getOffLat && this.form.getOffLng) {
          this.cityCenterData = {
            address: this.form.getOffLocation,
            lat: this.form.getOffLat,
            lng: this.form.getOffLng,
          }
        } else {
          this.cityCenterData = {}
        }
      },
      onSelectSex(item) {
        this.showSexPicker = false;
        Toast(item.name);
      },
      onConfirmSex(value) {
        this.form.sex = value;
        this.showSexPicker = false;
      },
      onConfirmOnTime(value) {
        switch (this.showTimePickerMode) {
          case "onTime":
            this.form.getOnDateMinute = moment(value).format("YYYY-MM-DD HH:mm");
            break;
          case "offTime":
            if (this.form.getOnDateMinute) {
              try {
                if (moment(value).isBefore(this.form.getOnDateMinute)) {
                  Toast.fail('下车时间应晚于上车，请重新选择');
                } else {
                  this.form.getOffDateMinute = moment(value).format("YYYY-MM-DD HH:mm");
                  break;
                }
              } catch (e) {

              }
            }
        }
        this.showTimePicker = false;

      },
      onConfirmDriverSex(value) {
        this.form.driverSex = value;
        this.showDriverSexPicker = false;
      },
      onConfirmCarColor(value) {
        this.form.carColor = value.text;
        this.showCarColorPicker = false;
      },
      onConfirmGoodPosition(value) {
        this.form.propertyPosition = value.text;
        this.showGoodPositionPicker = false;
      },
      onConfirmPassengerPosition(value) {
        this.form.seat = value.text;
        this.showPassengerPositionPicker = false;
      },
      onClickPreviousStep() {
        this.$emit('on-cancel', this.form);
      },
      onClickNextToCheckPage() {
        // console.log('---表单---',this.form);
        // console.log('---名字---',_.isEmpty(this.form.owner));
        // console.log('---手机---',_.isEmpty(this.form.phone));
        // console.log('---城市---',_.isEmpty(this.form.cityCode));
        // console.log('---上车---',_.isEmpty(this.form.getOnLocation));
        // console.log('---下车---',_.isEmpty(this.form.getOffLocation));
        // console.log('---物品---',_.isEmpty(this.form.propertyName));
        // console.log('---发票---',_.isEmpty(this.form.invoiceImgPath));
        if (!this.form.invoice) { 
          // if (
          //   _.isEmpty(this.form.owner) ||
          //   _.isEmpty(this.form.phone) ||
          //   _.isEmpty(this.form.sex) ||
          //   !this.form.cityCode ||
          //   _.isEmpty(this.form.getOnDateMinute) ||
          //   _.isEmpty(this.form.getOffDateMinute) ||
          //   _.isEmpty(this.form.getOnLocation) ||
          //   _.isEmpty(this.form.getOffLocation) ||
          //   _.isEmpty(this.form.propertyName) ||
          //   _.isEmpty(this.form.line) 
          // ) {
          //   Toast('请填写所有必填项');
          //   return;
          // }
          if( !this.form.cityCode ){
            Toast('请选择所在城市');
            return;
          }
          if( _.isEmpty(this.form.owner) ){
            Toast('请填写姓名');
            return;
          }
          if( _.isEmpty(this.form.sex) ){
            Toast('请选择性别');
            return;
          }
          if( _.isEmpty(this.form.phone) ){
            Toast('请填写联系电话');
            return;
          }
          if( _.isEmpty(this.form.propertyName) ){
            Toast('请填写物品描述');
            return;
          }
          if( _.isEmpty(this.form.getOnDateMinute) ){
            Toast('请选择上车时间');
            return;
          }
          if( _.isEmpty(this.form.getOffDateMinute) ){
            Toast('请选择下车时间');
            return;
          }
          if( _.isEmpty(this.form.getOnLocation) ){
            Toast('请选择上车地点');
            return;
          }
          if( _.isEmpty(this.form.getOffLocation) ){
            Toast('请选择下车地点');
            return;
          }
          if( _.isEmpty(this.form.line) ){
            Toast('请填写乘车路线');
            return;
          }

        } else { //发票
          // if (_.isEmpty(this.form.owner) ||
          //   _.isEmpty(this.form.phone) ||
          //   !this.form.cityCode ||
          //   _.isEmpty(this.form.getOnLocation) ||
          //   _.isEmpty(this.form.getOffLocation) ||
          //   _.isEmpty(this.form.propertyName) ||
          //   _.isEmpty(this.form.invoiceImgPath)
          // ) {
          //   Toast('请填写所有必填项');
          //   return;
          // }
          if( !this.form.cityCode ){
            Toast('请选择所在城市');
            return;
          }
          if( _.isEmpty(this.form.owner) ){
            Toast('请填写姓名');
            return;
          }
          if( _.isEmpty(this.form.phone) ){
            Toast('请填写联系电话');
            return;
          }
          if( _.isEmpty(this.form.propertyName) ){
            Toast('请填写物品描述');
            return;
          }
          if( _.isEmpty(this.form.getOnLocation) ){
            Toast('请选择上车地点');
            return;
          }
          if( _.isEmpty(this.form.getOffLocation) ){
            Toast('请选择下车地点');
            return;
          }
          if( _.isEmpty(this.form.invoiceImgPath) ){
            Toast('请上传发票');
            return;
          }
        }
        if (this.form.id && this.form.invoice) {
          this.form.getOffDate = null
          this.form.getOffDateMinute = null
          this.form.getOffDateStr = null
          this.form.getOnDate = null
          this.form.getOnDateMinute = null
          this.form.getOnDateStr = null
        }
        if (this.form.getOnDateMinute && this.form.getOffDateMinute) {
          this.form.getOnDateStr = this.form.getOnDateMinute;
          this.form.getOffDateStr = this.form.getOffDateMinute;
          this.form.getOnDate = moment(this.form.getOnDateMinute).format("YYYY-MM-DD HH:mm:ss");
          this.form.getOffDate = moment(this.form.getOffDateMinute).format("YYYY-MM-DD HH:mm:ss");
        } else {
          this.form.getOffDate = null
          this.form.getOffDateMinute = null
          this.form.getOffDateStr = null
          this.form.getOnDate = null
          this.form.getOnDateMinute = null
          this.form.getOnDateStr = null
        }
        this.form = {...this.form};

        console.log('失物查找表单', this.form);
        this.$emit('on-confirm', this.form);
      },
      // 删除图片
      delet() {
        this.image1FileList = [];
      },
      async onClickUploadFile(file) {
        if (file.file.size / 1024 / 1024 > 10) {
          Toast.fail('上传照片不能大于10M')
          return false
        }
        this.beforeUploadFile(file)
        return true
      },
      // 上传图片
      async beforeUploadFile(file) {
        this.clientToast = Toast.loading({
          mask: true,
          message: '上传图片中...',
          duration: 0
        })
        let params = {
          fileType: 'ticket',
        }
        try {
          let {status, data: filename} = await lostApi.getFileName(params)
          if (status === '0') {
            await this.multipartUpload(file, filename)
          } else {
            Dialog.alert({title: '获取上传文件名称失败'})
          }
        } catch (e) {
          Toast.clear()
          Dialog.alert({title: '获取上传文件名称异常：' + e})
        }
      },
      async multipartUpload(option, data) {
        let client = this.client
        try {
          var result = await client.multipartUpload(data, option.file, {
            progress: async function (p, checkpoint, resp) {
              // 断点记录点。 浏览器重启后无法直接继续上传，需用户手动触发进行设置。
              let tempCheckpoint = checkpoint
              let e = {}
              e.percent = p * 100
            },
            meta: {year: 2020, people: 'test'},
            mime: 'image/jpg',
            // 回调
            // callback: {
            //   url: data.callBackUrl,
            //   body: '{}',
            //   contentType: 'application/json'
            // }
          })
          if (result.res.statusCode === 200) {
            this.form.invoiceImgPath = result.name
            console.log('this.form==>', this.form)
          } else {
            this.form.invoiceImgPath = null
            Dialog.alert({
              title: '上传oss失败',
              message: result.res.statusCode
            }).then(() => {
              // on close
            })
          }
        } catch (e) {
          this.form.invoiceImgPath = null
          Dialog.alert({
            title: '上传oss异常',
            message: e
          }).then(() => {
          })
        } finally {
          Toast.clear()
        }
      },
      //阿里云oss鉴权
      async loadOSSClient() {
        const {data, status, message} = await lostApi.getAliyunOSSAccessToken()
        this.imgHttp = data.bucketUrl // 获取上传图片的域名
        if (status === '0') {
          this.client = new OSS({
            accessKeyId: data.accessKeyId,
            accessKeySecret: data.accessKeySecret,
            secure: true,//*这句话很重要！！！！！！！！！
            stsToken: data.securityToken,
            endpoint: data.endpoint,
            bucket: data.bucketName,
            refreshSTSToken: async () => {
              return {
                accessKeyId: data.accessKeyId,
                accessKeySecret: data.accessKeySecret,
                stsToken: data.securityToken
              }
            }
          });
        } else {
          Toast.fail('oss报备数据获取失败：', message);
        }
      },
      // 图片预览
      preImg(item) {
        ImagePreview({
          images: this.image1FileList.map(res => res.url),
          closeable: true,
        });
      },
    }
  }
</script>

<style lang="scss" scoped>
  
  .field-phone{
    &::v-deep{
      input{
        &::-webkit-input-placeholder { /* WebKit browsers */
          color:#FFA9A1;
        }
        &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
          color:#FFA9A1;
        }
        &::-moz-placeholder { /* Mozilla Firefox 19+ */
          color:#FFA9A1;
        }
        &:-ms-input-placeholder { /* Internet Explorer 10+ */
          color:#FFA9A1;
        }
      }
    }
  }
  
  .submit:hover {
    opacity: 0.75;
  }

  .submit:focus {
    opacity: 0.85;
  }

  .submit {
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-bottom: 30px;

    height: 50px;
    background: rgba(77, 114, 233, 1);
    box-shadow: 0px 15px 30px rgba(188, 187, 180, 0.2);
    opacity: 1;
    border-radius: 4px;


    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 22px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    vertical-align: center;

  }


  .back-button:active {
    opacity: 0.75;
  }

  .back-button {
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-bottom: 30px;
    height: 50px;

    border: 1px solid rgba(77, 114, 233, 1);
    box-shadow: 0px 15px 30px rgba(188, 187, 180, 0.2);
    border-radius: 4px;

    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 50px;
    text-align: center;
    vertical-align: center;
    color: rgba(77, 114, 233, 1);
    opacity: 1;
  }

  .divider {
    margin-top: 20px;
    padding: 8px 16px;
    background-color: #ffffff;
  }

  .divider .title {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    color: #222222;
    margin-bottom: 10px;
    .title-img{
      width: 18px;
      height: 18px;
      margin-right: 5px;
    }
  }

  .upload-box {
    background: #FFFFFF;
    border: 1px dashed #BBBFC4;
    opacity: 1;
    flex-direction: row;
    height: 85px;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-bottom: 15px;
    display: flex;
    border-radius: 8px;
  }

  .upload-box .icon-upload {
    width: 18px;
    height: 16px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url("../../../assets/img/lost/lost_form_upload.png");
  }

  .upload-box .tip {
    margin-left: 10px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: #4C70E4;
    opacity: 1;
  }



.contents {
  width: 100%;
  height: calc(100vh);
  display: flex;
  flex-direction: column;
}

.search {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 58px;
  flex-shrink: 0;
}

.searchWord {
  width: 100%;
  /*height: 35px;*/
  // border: none;
  margin-right: auto;
  margin-left: auto;

  /*width:823px;*/
  padding-left: 15px;
  
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 15px 30px rgba(188, 187, 180, 0.16);
  -moz-box-shadow: 0px 15px 30px rgba(188, 187, 180, 0.16);;
  -webkit-box-shadow: 0px 15px 30px rgba(188, 187, 180, 0.16);;
  opacity: 1;
  border-radius: 6px;
}
.sure {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 40px;
  margin-left: 10px;
  border-radius: 5px;
  color: #ffffff;
  font-size: 14px;
  font-weight: bolder;
  background-color: rgba(77, 114, 233);
}
.line {
  width: 1px;
  height: 20px;
  background: #000000;
  margin: 0 8px;
}

.searchCancel {
  font-size: 13px;
  color: #cccccc;
}

#mymap {
  width: 100%;
  height: 400px;
  flex-shrink: 0;
  position: relative;
  z-index: 99999999;
}

#myresult {
  width: 98%;
  /*flex-grow: 1;*/
  overflow-y: auto;
}

.resultItem {
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px solid #f5f5f5;

}

.resultP {
  font-size: 14px;
  color: #444444;
  margin: 0 10px;
  font-weight: bold;
}

.resultP2 {
  font-size: 12px;
  color: #999999;
  margin: 1px 10px 0 10px;

}

</style>
