const http = {
    get(request, url, params) {
        return request({
            url,
            method: 'get',
            params
        })
    },
    post(request, url, data, params) {
        return request({
            url,
            method: 'post',
            data,
            params
        })
    },

    delete(request, url, params) {
        return request({
            url,
            method: 'delete',
            params
        })
    }
}
export default http;
