<template>
  <div class="root-layout">
    <div class="content-container">
      <keep-alive v-if="$route.meta.keepAlive">
        <router-view style="height: 100%"></router-view>
      </keep-alive>
      <router-view v-else></router-view>
      <!--底部tab-->
      <van-tabbar
        v-if="appType === 'full' && showMenu"
        v-model="active"
        :fixed="siteFixed"
        active-color="#222222"
        :z-index="999"
      >
        <van-tabbar-item :to='route.to' :key="route.key" v-for="route in tabMenu">
          <span>{{route.name}}</span>
          <div slot="icon">
            <img v-if="active ===route.key" :src="route.activeImage">
            <img v-if="active !==route.key" :src="route.inactiveImage">
          </div>

        </van-tabbar-item>
      </van-tabbar>
    </div>
  </div>

</template>

<style scoped>
  .link {
    color: #222222
  }

  .content-container {
    max-width: 660px;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    background-color: rgba(238, 238, 238, 1)
  }

  .content-tab {
    max-width: 660px;
    margin-left: auto;
    margin-right: auto;
  }
</style>

<script>

  import Vue from 'vue';

  import {Cell, CellGroup, Col, Icon, PullRefresh, Row, Tabbar, TabbarItem} from 'vant';
  import {mapActions, mapState} from 'vuex';
  import router from '../router/router'

  Vue.use(PullRefresh);
  Vue.use(Icon);
  Vue.use(Cell).use(CellGroup);
  Vue.use(Tabbar).use(TabbarItem);
  Vue.use(Row).use(Col);

  export default {
    name: "MainLayout",
    components: {},
    data() {
      return {
        active: undefined,
        siteFixed: true,
        showMainTab: false,
        icon: {
          normal: require('@/assets/img/taxi.png'),
          active: require('@/assets/img/taxi.png')
        },
        tabMenu: [],
        routePath: undefined
      }
    },
    mounted() {
      this.tabMenu = router.options.routes
        .filter(route => {
          return route.meta.isTabMenu === true
        })
        .map(route => {
          return {
            name: route.name,
            to: route.path,
            activeImage: route.meta.activeImage,
            inactiveImage: route.meta.inactiveImage,
          }
        });
      this.tabMenu.forEach((currentValue, index, array) => {
        currentValue.key = index;
      });

      if (this.routePath) {
        this.routeChange(this.routePath);
      }
    },
    computed: {
      ...mapState({
        showMenu: 'showMenu',
        showTabMenu: 'showTabMenu',
        appType: 'appType',
        smsAccessToken: 'smsAccessToken'
      }),
      routeState() {
        return this.$route
      }
    },
    watch: {
      "active": {
        handler(newVal, val) {
        },
        immediate: true,
      },
      "routeState": {
        handler(newVal, val) {
          this.routePath = newVal.path;
          this.routeChange(this.routePath);
        },
        immediate: true,
      },
      "smsAccessToken": {
        handler(val, old) {
          console.info('smsNew==>>', val)
          console.info('smsOld==>>', old)
          this.loadSmsCallInfo()
        },
      }
    },
    methods: {
      ...mapActions({
        loadSmsCallInfo: 'loadSmsCallInfo'
      }),
      routeChange(path) {
        if (path === undefined) {
          return;
        }
        let menu = this.tabMenu.find(item => item.to === path);
        if (menu !== undefined) {
          this.active = menu.key;
        }
      },
    },

  }
</script>

