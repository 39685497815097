<template>
  <div class="root">
    <van-nav-bar :title="navTitle" fixed placeholder left-arrow @click-left="onBack"></van-nav-bar>
    <div>
      <img class="stepImg" src="../../img/first@2x.png" v-if="activePage === 'form'" alt="">
      <img class="stepImg" src="../../img/second@2x.png" v-if="activePage === 'confirm'" alt="">
      <img class="stepImg" src="../../img/third@2x.png" v-if="activePage === 'result'" alt="">
    </div>
    <div v-show="activePage === 'form'">
      <lost-form :data="form" :layout="form.invoice ? 'simple' : 'full' " @on-confirm="onFormConfirm"></lost-form>
    </div>

    <div v-show="activePage === 'confirm'">
      <lost-order-info :order="form"></lost-order-info>
      <van-row style="background-color: #fff;">
        <van-col span="8">
          <van-button :disabled="submitting"
                      round class="back-button" block @click="onClickPreviousStep">
            返回修改
          </van-button>
        </van-col>
        <van-col span="16">
          <van-button
              :loading="submitting"
              round class="submit" block @click="onClickConfirmAndSubmit">
            确认并提交
          </van-button>
        </van-col>
      </van-row>

    </div>


    <!--提交成功-->
    <van-popup v-if="false" v-model="activePageSuccess"
               style="display: flex;flex-direction: column;align-items: center;border-radius: 4px;width:80%;padding-bottom: 30px">
               <img src="@/assets/img/lost/success-icon.png" class="popup_img" alt="">
      <span class="popup_t1">信息已提交</span>
      <span class="popup_t2">正在为您匹配车辆线路......</span>
    </van-popup>

    <!-- 订单提交结果 -->
    <div v-show="activePage === 'result'" class="result-wrapper">

      <van-row type="flex" justify="center">
        <van-col span="12">
          <img src="@/assets/img/lost/success-icon.png" class="popup_img" alt="">
        </van-col>

      </van-row>


      <van-row type="flex" justify="center" style="margin-top: 20px">
        <van-col span="20">

          <div class="result-title">您的订单已提交成功！</div>
        </van-col>

      </van-row>


      <van-row type="flex" justify="center">
        <van-col span="20">
          <p class="result-text"> 
            请耐心等候处理
          </p>
        </van-col>
      </van-row>

      <div class="success_card" v-if="bizConfig.enableVip">
        <div class="success_card_title">
          <span>选择订单的服务类型</span>
          <div style="color:#4C70E4;font-size: 14px;font-weight: 400;" @click="returnServeNotice">
            <span>了解更多</span>
            <van-icon name="arrow" color="#4C70E4" />
          </div>
        </div>
        <div style="padding: 20px">
          <van-radio-group v-model="successRadio" style="display: flex;flex-direction: column">
            <van-radio name="noPay" checked-color="#4C70E4" style="display: flex;">
              免费服务 <span class="successRadio_right">标准流程</span>
            </van-radio>
            <!-- 柳州的不显示 -->
            <van-radio v-if="orderDetail.cityCode && orderDetail.cityCode != '450200' " name="pay" checked-color="#4C70E4" style="display: flex;margin-top: 20px">
              付费VIP服务<span style="background: #FE6A4D" class="successRadio_right">一对一全程专属服务</span>
            </van-radio>
          </van-radio-group>
        </div>
      </div>

      <div v-if="bizConfig.enableVip">
        <!-- 选择付费vip的确认按钮 -->
        <van-button type="info" v-if="successRadio=='pay'" @click="ownerServiceType(2)"
                    style="margin-top: 20px;width:100%;height:55px;border-radius:8px;background-color: #4C70E4;">确定
        </van-button>
        <!-- 选择免费服务的确认按钮 -->
        <van-button type="info" v-if="successRadio=='noPay'" @click="ownerServiceType(1)"
                    style="margin-top: 20px;width:100%;height:55px;border-radius:8px;background-color: #4C70E4;">确定
        </van-button>

      </div>
      <div v-else>
        <van-button type="info" @click="ownerServiceType(1)"
                    style="margin-top: 20px;width:100%;height:55px;border-radius:8px;background-color: #4C70E4;">查看结果
        </van-button>
      </div>

      <!--免费服务流程-->
      <div style="margin-top: 30px" v-if="successRadio == 'noPay'">
        <van-row type="flex" justify="center">
          <van-col :span="7" class="c-c">
            <div class="notice_line"></div>
          </van-col>

          <van-col :span="10">
            <span class="notice_title"><span v-if="bizConfig.enableVip">免费</span>服务流程</span>
          </van-col>
          <van-col :span="7" class="c-c">
            <div class="notice_line"></div>
          </van-col>
        </van-row>

        <div style="display: flex;flex-direction: column;">
                                <span class="content_title">
                                    1、平台匹配车辆
                                </span>
          <span class="content_value">
                                    平台根据您提交的失物查找订单信息，查找行驶轨迹符合乘车路线的车辆，并将结果反馈至微信端（请在微信端留意订单处理进度），同时将匹配车辆的车牌号反馈至出租车公司。
                                </span>

          <span class="content_title">
                                    2、出租车公司联系司机
                                </span>
          <span class="content_value">
                                    出租车公司的失物查找负责人跟进联系司机并核实是否发现失物，若找到失物，将通知司机把失物送回出租车公司指定地点进行保管；并电话通知您失物查找结果。（请注意保持电话畅通）
                                </span>

          <span class="content_title">
                                    3、乘客领取失物
                                </span>
          <span class="content_value">
                                    待出租车公司联系您后，携带身份证自行到出租车公司指定地点领取失物。
                                </span>
        </div>
      </div>

      <!--付费服务流程-->
      <div style="margin-top: 30px" v-if="bizConfig.enableVip && successRadio == 'pay'">
        <van-row type="flex" justify="center">
          <van-col :span="7" class="c-c">
            <div class="notice_line"></div>
          </van-col>

          <van-col :span="10">
            <span class="notice_title">VIP服务流程</span>
          </van-col>
          <van-col :span="7" class="c-c">
            <div class="notice_line"></div>
          </van-col>
        </van-row>

        <div style="display: flex;flex-direction: column;">
                                <span class="content_title">
                                    1、平台匹配车辆
                                </span>
          <span class="content_value">
                                    平台根据您提交的失物查找订单信息，查找行驶轨迹符合乘车路线的车辆，并将结果反馈至微信端（请在微信端留意订单处理进度）。
                                    <span style="color:#E65041">相对于免费服务，您的失物订单将由平台专属客服一对一全程跟进 ，并且优先加急处理。</span>
                                </span>

          <span class="content_title">
                                    2、平台联系司机
                                </span>
          <span class="content_value">
                                    <span style="color:#E65041">平台专属客服直接联系司机，</span>
                                    跟进并核实是否发现失物，如找到失物，将电话通知和微信端反馈您失物查找结果（请注意保持电话畅通），并且直接通知司机免费送还失物给乘客。
                                </span>

          <span class="content_title">
                                    3、乘客等待失物送还
                                </span>
          <span class="content_value">
                                    与司机沟通协商好指定地点后，等候
                                    <span style="color:#E65041">司机免费送还失物。</span>
                                </span>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

  import moment from 'moment';
  import qs from 'qs';
  import axios from 'axios'
  import Vue from 'vue'
  import {
    ActionSheet,
    Cell,
    CellGroup,
    DatetimePicker,
    Dialog,
    Field,
    Icon,
    List,
    NoticeBar,
    Picker,
    Popup,
    Radio,
    RadioGroup,
    Tab,
    Tabs,
    Tag,
    Toast
  } from 'vant';

  import {mapActions, mapState} from 'vuex';
  import ua from '../../utils/agent';
  import * as _ from 'lodash';
  import lostApi from '@/store/lostApi';
  import wx from 'weixin-js-sdk';
  import LostOrderInfo from "./components/LostInfo";
  import LostForm from "./components/LostForm";

  import ClipboardJS from 'clipboard';
  import routeutil from "@/utils/routeutil";
  import store from '@/store/store'
  Vue.use(RadioGroup);
Vue.use(Radio);

Vue.use(Dialog);
Vue.use(Tag);

Vue.use(Icon);
Vue.use(Toast);
Vue.use(NoticeBar);
Vue.use(List);
Vue.use(Tab).use(Tabs);
Vue.use(DatetimePicker);
Vue.use(Popup);
Vue.use(Toast);
Vue.use(ActionSheet);
Vue.use(Picker);
Vue.use(Field);
Vue.use(Cell).use(CellGroup);


export default {
  name: "CreateOrder",
  props: ['page', 'orderId'],
  components: {LostForm, LostOrderInfo},
  data() {

    console.log('before created data ', this.$route.params);

    return {
      navTitle: '', //导航栏标题
      form: {},
      contactPhone: '', // 结果查询手机号

      hasInvoice: this.$route.params.invoice === 'yes',

      activePage: 'form', // form, result, confirm
      activeTab: 0,
      activeStep: 0,

      activePageSuccess: false,

      clickedTab: -1,

      submitting: false,
      submittingFind: false,

      losts: [],
      loadingLostOrders: false,
      loadedFindResult: false,

      showLostForm: false,

      showAboutVip: false,

      successRadio: 'noPay',
      orderDetail: {}, //订单详情
    }
  },
  mounted() {
    this.navTitle = this.$route.meta.navTitle //初始化导航栏标题名
    //this.appConfig.isDev && this.$route.params.orderId === 'null'
    if (this.$route.params.orderId === 'null') {
      let form = {};
      form.phone = this.callInfo.phone ? this.callInfo.phone : '';
      form.getOnDate = moment().subtract(1, "hours").format("YYYY-MM-DD HH:mm:ss");
      form.getOffDate = moment().subtract(1, "minutes").format("YYYY-MM-DD HH:mm:ss");
      form.invoiceImgHttpPath = '';
      form.invoiceImgPath = ''
      this.contactPhone = '';
      this.form = form;
    }

    if (this.appConfig.isDev && this.$route.params.orderId === 'null') {
        let form = {};
        form.owner = '王家卫';
        form.cityCode = '450100';
        form.city = '南宁市';
        form.sex = '男';
        form.phone = '13400011111';
        form.propertyName = '电影剧本';
        form.amount = '1';
        form.getOnDate = moment(moment().valueOf()-1000).format("YYYY-MM-DD HH:mm:ss");
        form.getOffDate = moment().format("YYYY-MM-DD HH:mm:ss");
        form.getOnLocation = "国电智能大厦";
        form.getOffLocation = "南宁站";
        form.line = "大陆";
        form.cost = 30;
        form.passengerDesc = '1男1女';
        form.seat = '后座';
        form.propertyPosition = '后尾箱';

        form.driverSex = '男';
        form.carColor = '绿灰相间';

        this.contactPhone = 13400001111;

        this.form = form;
        console.log('1', this.form);
    }

    if (this.$route.params.orderId != 'null') {
      this.form.orderId = this.$route.params.orderId;
    }
    this.form.invoice = this.$route.params.invoice === 'yes'

    console.warn('this.form：', this.form)

    


    this.initWeChatPay();

    // 加载失物查找服务的价格
    this.loadLostServicePrice();

    let clipboard = new ClipboardJS('.clip-djcx');
    clipboard.on('success', function (e) {
      Toast.success("已复制");
      e.clearSelection();
    });

    clipboard.on('error', function (e) {
      Toast.fail("复制失败");
    });

    let clipboard2 = new ClipboardJS('.clip-djcx2');
    clipboard2.on('success', function (e) {
      Toast.success("已复制");
      e.clearSelection();
    });
    clipboard2.on('error', function (e) {
      Toast.fail("复制失败");
    });
  },
  watch: {
    lostid: {
      handler(val, oldVal) {
        console.log('lostid changed', this.lostid, val);
        if (_.isEmpty(val)) {
        }
      },
      immediate: true
    },
    "validFindResultForm": {
      handler(val, oldVal) {
        if (val === false) {
          this.loadedFindResult = false;
        }
      }
    },
    "page": {
      handler(val, oldVal) {
        this.activePage = val;
      },
      immediate: true
    },
    "orderId": {
      handler(val, oldVal) {
        this.form.orderId = val;
      },
      immediate: true
    },

    "activePage": {
      handler(val, oldVal) {
        
        this.$router.push(routeutil.getCreateLostOrderPath(val, this.hasInvoice ? 'yes' : 'no', this.form.orderId||null))
        //动态修改导航栏标题
        const title = {
          form: this.$route.meta.navTitle,
          confirm: '确认订单',
          result: '选择服务类型'
        }
        this.navTitle = title[val] || this.$route.meta.navTitle
      },
      // immediate: true
    },

    "activeTab": {
      handler(val, oldVal) {
        if (val === 0) {
          this.showLostForm = false;
          this.showAboutVip = false;
        }
      }
    },
    form: {
      handler(val, oldVal) {},
      immediate: true
    },
    'lostOrders': {
      handler(val, oldVal) {
        // console.log('失物查找订单列表变化', val);
        if (_.isArray(val)) {
          val.forEach(lost => {

            // 失物查找结果文字解析
            if (_.isEmpty(lost.handleResult)) {
              lost.resultColor = 'red';
            } else {
              if (lost.handleResult.search(/查询不到/)) {
                lost.resultColor = 'red';
              } else if (lost.b2.search(/现查询到/)) {
                lost.resultColor = 'green';
              }
            }

            if (lost.handleStatus != null) {
              switch (lost.handleStatus) {
                case 0:
                  lost.orderStatusText = '未处理';
                  lost.orderStatusTextClassName = 'unhandled';
                  break;
                case 1:
                  lost.orderStatusText = '处理中';
                  lost.orderStatusTextClassName = 'handling';
                  break;
                case 2:
                  lost.orderStatusText = '已处理';
                  lost.orderStatusTextClassName = 'handled';
                  break;
              }
            }

            lost.isVip = _.isEqual(lost.payStatus, 1);
          });
        }
        this.losts = val;
        console.log('-------',val);
        //取订单详情，没有订单详情接口只能从列表里面找
        if(this.activePage === 'result'){
          console.log('获取当前订单详情');
          console.log('订单id',this.form.orderId); 
          console.log('订单列表',val); 
          if(_.isArray(val)){
            this.orderDetail = val.find(item=>item.id ===this.form.orderId) || {}
            console.log('当前订单详情',this.orderDetail);
          }

        }
        // if (!this.showLostForm) {
        //     this.showLostForm = (this.unhandledCount === 0 && this.handlingCount === 0);
        // }
      },
      immediate: true
    },
    openId: {
      handler(val, oldVal) {
        if (val) {
          this.loadingLostOrders = true;
          this.loadLostOrders({})
              .then(res => {
              })
              .finally(() => {
                this.loadingLostOrders = false;
              });
        }
      },
      immediate: true
    },
    activeTabs: {
      handler(val, oldVal) {
        if (val == 'myOrder') {
          this.activeTab = 1
        } else {
          this.activeTab = 0
        }
      },
      immediate: true
    },
  },
  computed: {
    ...mapState({
      userInfo: 'userInfo',
        callInfo: 'callInfo',
        openId: state => state.openId,
      lostServicePrice: 'lostServicePrice',
      lostOrders: 'lostOrders',
      routerPaths: 'routerPaths',
    })
    ,
    unhandledCount() {
      if (this.lostOrders) {
        if (this.lostOrders.length === 0) {
          return 0;
        } else {
          return this.lostOrders.filter(item => item.handleStatus === 0).length;
        }
      } else {
        return 0;
      }
    },

    handlingCount() {
      if (this.lostOrders) {
        if (this.lostOrders.length === 0) {
          return 0;
        } else {
          return this.lostOrders.filter(item => item.handleStatus === 1).length;
        }
      } else {
        return 0;
      }
    },

    isWeChat() {
      return ua.wechat;
    },

    emptyFindResult() {
      return _.isEmpty(this.losts)
    },
    validFindResultForm() {
      return /(0|86|17951)?(13[0-9]|15[0-9]|17[678]|18[0-9]|14[57]|19[0-9]|16[0-9])[0-9]{8}$/
          .test(this.contactPhone);
    },
  },
  methods: {
    ...mapActions({
      prepayLostOrder: 'prepayLostOrder',
      loadLostOrders: 'loadLostOrders',
      loadLostServicePrice: 'loadLostServicePrice'
    }),
    /**
     * 高度坐标转百度
     * @param gg_lng
     * @param gg_lat
     * @returns {{bd_lng: number, bd_lat: number}}
     */
    bd_encrypt(gg_lng, gg_lat) {
      var X_PI = Math.PI * 3000.0 / 180.0;
      var x = gg_lng, y = gg_lat;
      var z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * X_PI);
      var theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * X_PI);
      var bd_lng = z * Math.cos(theta) + 0.0065;
      var bd_lat = z * Math.sin(theta) + 0.006;
      return {
        bd_lat: bd_lat,
        bd_lng: bd_lng
      }
    },
    //导航栏返回按钮
    onBack(){
        // let routeHistory=history.length-1;
        switch (this.activePage) {
          case 'confirm': //确认下单界面
            this.onClickPreviousStep()
            break;
          case 'result': //下单结果页
            let lastRoutePath = this.routerPaths[this.routerPaths.length - 2] //上一个路由
            if(_.startsWith(lastRoutePath, '/createOrder/')){
              store.commit('CHANGE_ROUTER', []);
              this.$router.replace('/lost')
            }else{
              this.$router.back()
            }
            // this.$router.go(-routeHistory);
            // this.$router.replace({
            //   path: `/lostList/null`,
            //   query: {
            //     showActive: 0
            //   }
            // })
            break;

          default:
            this.$router.back()
            // this.$router.go(-routeHistory);
            break;
        }
    },  
    reloadOrders() {
      const _this = this;
      this.loadLostOrders({phone: null})
          .then(res => {
            let order = res.find(lost => lost.id == _this.lostId);
            // _this.form = order;
          })
          .catch(reason => {
            Toast.fail(reason);
          })
          .finally(() => {
            _this.isLoading = false;
          });
    },

    returnVip() {
      this.$router.push('/aboutVip')
    },
    returnServeNotice() {
      this.$router.push('/serveNotice')
    },

    aboutVip() {
      this.activeTab = 1;
    },
    initWeChatPay() {},
    // 在蛋卷出行系统中创建失物查找订单
    commitLostOrder() {
      this.submittedLostOrder = null;
      this.submitting = true;

      //高德坐标转百度坐标
      // let onLocation = this.bd_encrypt(this.form.getOnLng, this.form.getOnLat);
      // this.form.getOnLat = onLocation.bd_lat;
      // this.form.getOnLng = onLocation.bd_lng;
      // let offLocation = this.bd_encrypt(this.form.getOffLng, this.form.getOffLat);
      // this.form.getOffLat = offLocation.bd_lat;
      // this.form.getOffLng = offLocation.bd_lng;
      const _this = this;
      console.warn('this.form==>>', this.form)
      axios({
        method: 'post',
        url: '/wx/lost/orders',
        baseURL: this.$store.state.taxiApiUrl,
        headers: {"content-type": "application/json"},
        data: this.form
      })
        .then(resp => {
            console.log('创建失物查找订单结果', resp);
            if (resp.data.status === "0") {
              _this.activeStep = 2;
              _this.activePage = 'result';
              _this.form.orderId = resp.data.data.id;
              _this.activePageSuccess = true;
              _this.submittedLostOrder = resp.data.data;
              _this.getRoadData();
              _this.reloadLostOrders();
            } else {
              Dialog.alert({
                message: resp.data.message
              }).then(() => {
                // on close
              });
            }
          })
          .catch(reason => {
            Dialog.alert({
              message: reason
            }).then(() => {
              // on close
            });
          })
          .finally(() => {
            this.submitting = false;
          });
    },

    // 根据提交上下车点获取司机线路
    getRoadData() {
      this.activePage = "result";
    },
    // 在GPS调度系统中创建失物查找订单
    commitLostOrderGPS() {
      let form = {
        phone: this.form.phone,
        lostOwner: this.form.owner,
        getOnDate: moment(this.form.getOnDateMinute).format("YYYY-MM-DD HH:mm:ss"),
        getOnLocation: this.form.getOnLocation,
        getOffDate: moment(this.form.getOffDateMinute).format("YYYY-MM-DD HH:mm:ss"),
        getOffLocation: this.form.getOffLocation,
        carColor: this.form.carColor,
        goodsName: this.form.propertyName,
        goodsAmount: this.form.amount,
        goodsPosition: this.form.propertyPosition,
        seat: this.form.seat,
        line: this.form.line,
        sex: this.form.sex,
        passengerDescription: this.form.passengerDesc,
        driverSex: this.form.driverSex,
        cost: this.form.cost
      };

      this.submitting = true;
      axios({
        method: 'post',
        baseURL: this.$store.state.cheApiUrl,
        url: '/api/LostInfo/CommitOrder',
        headers: {"content-type": "application/x-www-form-urlencoded"},
        data: qs.stringify(form)
      }).then(resp => {
        console.log(resp);
        if (resp.data.status == "0") {
          this.activePage = "result"
        } else {
          Toast.fail(resp.data.message);
        }
      }).finally(() => {
        this.submitting = false;
      });
    },
    onClickTab(index, title) {
      if (this.clickedTab === index) {
        if (index === 1) {
          this.reloadLostOrders();
        }
      } else {
        this.clickedTab = index;
      }
    },
    onClickViewVipDetail() {},
    async ownerServiceType(value) {
      var _this = this;
      if (!_this.form.orderId) {
        alert('获取不到orderId，请重试');
        return;
      }
      let toast = Toast.loading({
        mask: true,
        message: '加载中...',
        duration: 0,
      });

      let form = {
        value: value, //0.未选择（默认） 1.免费服务 2.付费服务
      }
      
      if(value == 2 && this.$route.params.isVipNotPay === '1'){ //已经是vip订单但没付钱
        _this.$router.push('/lostUpgrade/' + _this.form.orderId);
        toast.clear();
        return
      }
      try {
        let {status, message} = await lostApi.ownerServiceType(_this.orderId, form);

        if (status === '0') {
          if (value == 2) {
            _this.$router.push('/lostUpgrade/' + _this.form.orderId);
          } else {
            //vip功能打开
            if(this.bizConfig.enableVip){
              Dialog.alert({title:'提示',message:'您的本次订单将为免费服务，后续仍可升级服务。'}).then(()=>{
                _this.$router.push('/lostDetail/' + _this.form.orderId);
              })
            }else{
              _this.$router.push('/lostDetail/' + _this.form.orderId);
            }
          }
          _this.$store.dispatch('loadLostOrders', {})
        } else {
          Dialog.confirm({message: '修改服务类型失败：' + message});
        }
      } catch (e) {
        Dialog.confirm({message: '修改服务类型异常：' + e});
      } finally {
        // 加载状态结束
        toast.clear();
      }

    },
    onClickUpgrade(lost) {
      console.log('onClickUpgrade', lost);
      this.$router.push(`/lostUpgrade/${lost.id}`);
    },
    onClickOrderCard(lost) {
      this.$router.push(`/lostDetail/${lost.id}`)
    },
    onClickPreviousStep() {
      this.activePage = 'form';
      this.activeStep = 0;
      this.$router.back()
    },
    // 点击下一步
    onFormConfirm(form) {
      console.log('onFormConfirm', form);

      this.form = form;

      this.activePage = 'confirm';
      this.activeStep = 1;
    },
    onClickConfirmAndSubmit() {
      this.commitLostOrder();
    },
    onClickBuyVIPService(lostId) {
      Toast.loading("正在提交...");
      let _this = this;

      this.prepayLostOrder(lostId)
          .then(res => {
            console.log('创建预支付订单成功', res);
            // 支付确认对话框
            _this.onWeChatReadyToPay(res);
            // _this.wechatPay()
          })
          .catch(reason => {
            Dialog.alert({
              title: '支付失败',
              message: reason
            }).then(() => {
              // on close
            });
          })
          .finally(() => {
            Toast.clear();
          });
    },
    onWeChatReadyToPay(params) {
      // https://mp.weixin.qq.com/wiki?t=resource/res_main&id=mp1421141115
      // 所有需要使用JS-SDK的页面必须先注入配置信息，否则将无法调用（同一个url仅需调用一次，对于变化url的SPA的
      // web app可在每次url变化时进行调用,目前Android微信客户端不支持pushState的H5新特性，所以使用pushState来
      // 实现web app的页面会导致签名失败，此问题会在Android6.2中修复）。
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，
        // 可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: params.appId, // 必填，公众号的唯一标识
        timestamp: params.timeStamp, // 必填，生成签名的时间戳
        nonceStr: params.nonceStr, // 必填，生成签名的随机串
        signature: params.paySign,// 必填，签名
        jsApiList: ['chooseWXPay'] // 必填，需要使用的JS接口列表 // https://mp.weixin.qq.com/wiki?t=resource/res_main&id=mp1421141115
      });

      wx.ready(function () {

        console.log("微信wx.ready()被调用");

        // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的
        // 异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。
        // 对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
        wx.chooseWXPay({
          appId: params.appId,
          timestamp: params.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          nonceStr: params.nonceStr, // 支付签名随机串，不长于 32 位
          package: params.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          signType: params.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
          paySign: params.paySign, // 支付签名
          success: function (res) {
            console.log('支付成功', res);
            // use($('.forbid'));
            // 支付成功后的回调函数
            //window.location.href = 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzU4MzIzMzM3OA==&scene=126#wechat_redirect'
            // window.location.href = '/wx/lucky/index'
          },
          fail: function (res) {
            console.log('支付失败', res);
            // use($('.forbid'));
            // alert('支付失败，请重新支付');
          },
          cancel: function (res) {
            console.log('支付取消', res);
            // use($('.forbid'));
          }
        });
      });
      wx.error(function (res) {
        // config信息验证失败会执行error函数，如签名过期导致验证失败，
        // 具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        console.log('wx.error', res);
        alert('支付失败（Config失败），请重新支付' + res);
      });
    },
    wechatPay(params) {
      function onBridgeReady() {

        WeixinJSBridge.invoke(
            'getBrandWCPayRequest', {
              // "appId": "wx2421b1c4370ec43b",     //公众号名称，由商户传入
              // "timeStamp": "1395712654",         //时间戳，自1970年以来的秒数
              // "nonceStr": "e61463f8efa94090b1f366cccfbbb444", //随机串
              // "package": "prepay_id=u802345jgfjsdfgsdg888",
              // "signType": "MD5",         //微信签名方式：
              // "paySign": "70EA570631E4BB79628FBCA90534C63FF7FADD89" //微信签名
              appId: params.appId,
              timestamp: params.timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
              nonceStr: params.nonceStr, // 支付签名随机串，不长于 32 位
              package: params.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
              signType: params.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
              paySign: params.paySign, // 支付签名
            },
            function (res) {
              if (res.err_msg == "get_brand_wcpay_request:ok") {
                // 使用以上方式判断前端返回,微信团队郑重提示：
                //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              }
            });
      }

      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
          document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
        }
      } else {
        onBridgeReady();
      }
    },
    upgradeSubmittedLostOrder() {
      if (this.submittedLostOrder && this.submittedLostOrder.id) {
        this.$router.push("/lostUpgrade/" + this.submittedLostOrder.id);
      }
    },
    reloadLostOrders() {
      console.log("重新加载失物查找订单");
      this.loadLostOrders({});
    },
    onLoadFindResult() {},
    onSubmitFind() {
      this.submittingFind = true;
      this.loadedFindResult = false;
      this.loadLostOrders({phone: this.contactPhone})
          .then(res => {
            this.loadedFindResult = true;
          })
          .catch(reason => {
            Toast.fail(reason);
          })
          .finally(() => {
            this.submittingFind = false;
          });
    }
  }
}
</script>


<style scoped>

.result-vip-card {
  padding: 1px;

  width: auto;
  margin-left: 15px;
  margin-right: 15px;
  height: 153px;
  background: linear-gradient(180deg, rgba(66, 66, 78, 1) 0%, rgba(30, 30, 39, 1) 100%);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.16);
  opacity: 1;
  border-radius: 4px;
}

.result-vip-card .title {
  padding: 0;
  margin-top: 20px;
  position: relative;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: bold;
  color: rgba(255, 214, 147, 1);
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
  opacity: 1;
}

.result-vip-card .title2 {
  margin-top: 10px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 300;
  line-height: 20px;
  color: rgba(255, 214, 147, 1);
  opacity: 1;
}

.result-title {

  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 25px;
  color: rgba(34, 34, 34, 1);
  opacity: 1;
}

.result-text {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 26px;
  color: rgba(153, 153, 153, 1);
  opacity: 1;
}

.notice-item {
  margin: 10px 10px;

}

.notice-item .van-button {
  min-width: 200px;
}

.order-exist-notice {
  margin-top: 20px;
}

.dot {
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 8px;
  display: inline-block;
  background: #c66d03;
  vertical-align: center;
  margin-right: 16px;
}

.dot-off {
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 8px;
  display: inline-block;
  background: #13a831;
  vertical-align: center;
  margin-right: 16px;
}

.dot-text {
  display: block;
  color: #FFFFFF;
  height: 20px;
  line-height: 20px;
  font-size: 8px;
  vertical-align: center;
  margin: 0;
  text-align: center
}

.card-item-val {
  font-weight: bold;
  font-size: 14px;
  font-family: PingFang SC;
  line-height: 20px;
  color: rgba(34, 34, 34, 1);
  opacity: 1;

}

.card-item-time {
  color: darkgray;
}

.card-item-title {
  height: 20px;
  font-size: 14px;
  font-family: PingFang SC;

  line-height: 20px;
  color: rgba(153, 153, 153, 1);
  opacity: 1;
}

.find-result-wrapper {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.result-wrapper {
  padding-top: 30px;
  padding-left: 20px;
  text-align: center;
  padding-right: 20px;
}

.call-success-text {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  text-align: center;
  line-height: 25px;
  color: rgba(95, 203, 167, 1);
  opacity: 1;
}

.call-success-text2 {
  font-size: 16px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 25px;
  color: rgba(34, 34, 34, 1);
  opacity: 1;
}

.call-success-text3 {
  font-size: 14px;
  font-family: PingFang SC;
  line-height: 25px;
  color: rgba(153, 153, 153, 1);
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  width: 252px;
}

.not-wechat-notice {
  margin-left: 16px;
  margin-right: 16px;
}

.not-wechat-notice .guide {
  font-size: 14px;
}

.not-wechat-notice .keyword {
  font-size: 18px;
  font-weight: bold;
  color: goldenrod;
}

.bottom-pad {
  height: 38px;
}

.popup {

  background-color: green;
}


.card {
  background: white;

  /*font-size: 14px;*/
  /*border-left-color: gold;*/
  /*border-left-width: 4px;*/
  /*border-left-style: solid;*/
  margin-top: 20px;
  border-radius: 5px;

  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 5px 10px rgba(188, 187, 180, 0.16);
  opacity: 1;
  border-radius: 6px;
}

.card:active {
  background-color: #f3f3f3ff;
}

.card-content {
  padding: 15px;
}

.card-footer {
  height: 60px;
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(188, 187, 180, 0.16);
  opacity: 1;
  border-radius: 0px 0px 6px 6px;
  border-top: 1px solid rgba(241, 241, 241, 1);;
}

.card-footer .text {
  margin: 0;
  alignment: center;
  text-align: center;
  height: 60px;
  line-height: 60px;

  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: rgba(77, 114, 233, 1);
  opacity: 1;
}

.card-footer .text.unhandled {

}

.card-footer .text.handled {
  background: rgba(204, 204, 204, 1);
  box-shadow: 0px 5px 10px rgba(188, 187, 180, 0.16);
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
}

.card-footer .upgrade-button {
  height: 60px;
  background: rgba(255, 71, 71, 1);
  opacity: 1;
  line-height: 60px;
  text-align: center;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
}

.card-footer .upgrade-button:active {
  opacity: 0.75;
}

.content-header {
  /*margin-top: 5px;*/
  /*margin-bottom: 5px;*/
  /*line-height: 20px;*/
  /*vertical-align: middle;*/
  /*padding: 0;*/
  display: flex;
  align-items: center;
  height: 53px;
  background: rgba(230, 80, 65, 1);
  opacity: 1;
  border-radius: 4px 4px 0px 0px;
  padding-left: 15px;
  margin: 0;
}

.vip-icon {
  width: 44px;
  opacity: 1;
}

.vip-text {
  margin-left: 4px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 22px;
  color: rgba(255, 255, 255, 1);
  opacity: 1;
}

.order-status-text.unhandled {

}

.order-status-text.handling {
  height: 20px;
  font-size: 14px;
  font-family: PingFang SC;
  line-height: 20px;
  color: rgba(255, 71, 71, 1);
  opacity: 1;
}

.order-status-text.handled {

}

.divider {
  padding: 8px 16px;
  background-color: #f5f5f5;
}

.h-divider {
  height: 1px;
  background-color: #f5f5f5;
}

.root {
  text-align: left;
  alignment: left;
  padding-bottom: 20px;
}

.card-row {
  margin-bottom: 5px;
}

.back-button:active {
  opacity: 0.75;
}

.back-button {
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  margin-bottom: 30px;
  height: 50px;

  border: 1px solid rgba(77, 114, 233, 1);
  box-shadow: 0px 15px 30px rgba(188, 187, 180, 0.2);
  border-radius: 4px;

  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 50px;
  text-align: center;
  vertical-align: center;
  color: rgba(77, 114, 233, 1);
  opacity: 1;
}

.submit:hover {
  opacity: 0.75;
}

.submit:focus {
  opacity: 0.85;
}

.submit {
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  margin-bottom: 30px;

  height: 50px;
  background: rgba(77, 114, 233, 1);
  box-shadow: 0px 15px 30px rgba(188, 187, 180, 0.2);
  opacity: 1;
  border-radius: 4px;


  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 22px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  vertical-align: center;

}

.footer {
  width: 100%;
  height: 60px;
  background: rgba(255, 214, 147, 1);
  opacity: 1;
  text-align: center;
  line-height: 60px;
}

.footer_text {

  height: 22px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 22px;
  color: rgba(34, 34, 34, 1);
  opacity: 1;
}

.notice-add {
  width: 95%;
  background: rgba(77, 114, 233, 1);
  opacity: 1;
  border-radius: 4px;
  color: white
}

.notice-button {
  width: 95%;
  border-radius: 4px;

}

.notice-foot {
  height: 20px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 20px;
  color: rgba(230, 80, 65, 1);
  opacity: 1;
}

.popup_img {
  width: 70px;
  height: 70px;
  background: rgba(95, 203, 167, 1);
  border-radius: 50%;
  opacity: 1;
  /*margin-top: 20px;*/
}

.popup_t1 {
  width: 90px;
  height: 25px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 25px;
  color: rgba(34, 34, 34, 1);
  opacity: 1;
  margin-top: 15px;
}

.popup_t2 {
  width: 170px;
  height: 44px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 22px;
  color: rgba(255, 102, 45, 1);
  opacity: 1;
  margin-top: 10px;
  text-align: center;
}

.circle {
  width: 14px;
  height: 14px;
  background: rgba(230, 80, 65, 1);
  border-radius: 50%;

  text-align: center;
  font-size: 10px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 14px;
  color: rgba(255, 255, 255, 1);

  display: inline-block;
  margin-left: 6px;
  margin-top: -3px;
}

.col_value {
  text-align: right;
}

.footer_phone {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 21px;
  color: rgba(159, 159, 167, 1);
  opacity: 1;
  position: fixed;
  bottom: 100px;
  width: 250px;
  left: 50%;
  transform: translateX(-125px);

}

.stepImg {
  display: block;
  width: 100%;  
}

.success_card {
  /*height:155px;*/
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04);
  opacity: 1;
  border-radius: 4px;
}

.success_card_title {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 22px;
  color: rgba(34, 34, 34, 1);
  opacity: 1;
  border-bottom: 2px solid rgba(241, 241, 241, 1);
}

.successRadio_right {
  height: 16px;
  font-size: 11px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 16px;
  color: rgba(255, 255, 255, 1);
  opacity: 1;
  padding: 2px 6px;
  margin-left: 5px;
  background: #BBBFC4;
  border: 1px;
}

.notice_title {

  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 30px;
  color: rgba(123, 123, 123, 1);
  opacity: 1;
}

.notice_line {
  width: 100%;
  height: 0px;
  border: 1px solid rgba(159, 159, 167, 1);
  opacity: 1;
}

.content_title {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 26px;
  color: rgba(123, 123, 123, 1);
  opacity: 1;
  text-align: left;
  margin-top: 5px;
}

.content_value {
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 19px;
  color: rgba(159, 159, 167, 1);
  opacity: 1;
  text-align: left;
}

.c-c {
  display: flex;
  justify-content: center;
  align-items: center;
}

.find-result-list {
  width: 90%;
}


</style>
