import request from '@/store/taxiRequest';

const taxiApi = {
  /**
   * 加载出租车活动数据
   */
  loadActivities() {
    return request({
      url: `api/taxi/public/activities`,
      method: 'get'
    })
  },
  // 获取运营城市
  getOpenCityList() {
    return request({
        url: '/common/queryCityWithCreateOrderV1',
        method: 'get'
    })
  },
};

export default taxiApi;
