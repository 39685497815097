<template>
  <div style="background: rgba(238, 238, 238, 1);height: 100vh;width: 100%">
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh" style="height: 100vh;overflow-y: auto">
      <p style="padding: 10px 0 ; text-align: center; background-color:#fff;">
        <van-icon color="green" name="arrow-down"/>
        <span>下拉刷新</span>
      </p>
      <!-- <img v-for="(activity, index) in activityList" :key="index" :src="activity.bannerUrl"
           style="width: 100%; height: auto; margin: 0" @click="onClickBanner(activity)"/> -->
      <Banner source="21"></Banner>     
      <table class="table" cellspacing="0">
        <tr class="siteTr">
          <th v-for="(item,index) of siteColumn" :key="item+index">{{item}}</th>
        </tr>
        <tr v-for="(items, r) in siteData" :key="r" class="siteTr">
          <td v-for="(item, c) in items" :key="c">{{ item }}</td>
        </tr>
      </table>
    </van-pull-refresh>
  </div>
</template>

<script>
  import axios from "axios";
  import Vue from 'vue';
  import {Cell, CellGroup, Col, Icon, PullRefresh, Row, Tabbar, TabbarItem, Toast} from 'vant';
  import {mapActions, mapState} from "vuex";
  import Banner from '@/components/Banner/index.vue';

  Vue.use(Toast);
  Vue.use(PullRefresh);
  Vue.use(Icon);
  Vue.use(Cell).use(CellGroup);
  Vue.use(Tabbar).use(TabbarItem);
  Vue.use(Row).use(Col);

  export default {
    name: "site",
    components: {Banner},
    data() {
      return {
        siteData: [],
        //站点数据表头
        siteColumn: ["区域名称", "车辆总数", "空车", "载客", "停车数"],
        isLoading: false,
      }
    },
    created() {
      this.loadData();
    },
    computed: {
      ...mapState({
        activityList: state => state.taxiActivities
      })
    },
    methods: {
      ...mapActions({
        loadTaxiActivities: 'loadTaxiActivities'
      }),
      loadData() {
        // this.loadActivities();
        this.loadSiteData();
      },
      async loadActivities() {
        try {
          await this.loadTaxiActivities()
        } catch (e) {
          Toast.fail('getActivities failed : ' + e)
          console.log('getActivities failed : ', e)
        }
      },
      loadSiteData(ifrefresh) {
        let loading = Toast.loading({
          overlay: true,
          message: '数据加载中...',
          duration: 0,
        });
        axios({
          method: "get",
          url: "/api/legacy/station/taxi/stat",
          baseURL: this.$store.state.cheApiUrl,
        }).then((res) => {
            if (res.data && res.data.result === 'ok') {
              this.siteData = res.data.data.autoinfo.area || [];
              this.siteData = this.siteData.filter(item => item['name'] !== '交通局').map(item => {
                return [item['name'], item['ac'], item['ec'], item['fc'], item['sc']];
              });
              this.siteData.sort((a, b) => {
                return b[0].length - a[0].length
              }) // 排序
              if (ifrefresh) {
                this.isLoading = false;
              }
              loading.clear()
            } else {
              loading.clear()
              Toast.fail('站点数据获取失败')
              this.siteData = [];
            }
          })
          .catch(error => {
            loading.clear()
            Toast.fail('站点数据获取异常：', error)
            console.log(error);
          })
      },

      //下拉刷新
      onRefresh() {
        this.loadSiteData(true);
      },

      onClickBanner(activity) {
        if (activity.id) {
          if(activity.contentType === 'outurl') {
            //外部活动链跳转
            window.location.href = activity.content;
          }else{
            this.$router.push('/activity/' + activity.id)
          }
        }
      }
    },
  }
</script>
<style scoped lang="scss">
  * {
    margin: 0;
    padding: 0;
    border: 0;
  }

  .table {
    width: 90%;
    margin: 10px auto;
    padding-bottom: 30px;
  }

  /*奇数行*/
  .siteTr:nth-of-type(odd) {
    background: #ffffff;
    font-size: 28px;
    color: rgba(60, 59, 74, 1);
  }

  /*偶数行*/
  .siteTr:nth-of-type(even) {
    background: #ffffff;
  }

  .siteTr:nth-of-type(1) {
    background: #d6d6d7;
    color: #5E627B;
  }

  .siteTr:nth-of-type(1) td {
    background: #F6F7F9;
    color: #5E627B;
  }

  .siteTr:nth-of-type(1) tr {
    background: #5E627B;
    text-align: left;
  }

  /*th表头*/
  .siteTr th {
    color: #393939;
    font-size: 14px;
    background: #F6F7F9;
    font-weight: bold;
    line-height: 40px;
    text-align: center;
  }

  .siteTr td {
    color: #393939;
    font-size: 14px;
    line-height: 40px;
    text-align: center;
  }

  .siteTr th:first-child {
    text-align: left;
    padding-left: 16px;
  }

  .siteTr td:first-child {
    text-align: left;
    padding-left: 16px;
  }
</style>
