<template>
  <div id="map-root" class="root-container" @resize="onMapWrapperResize">
        <div id="map-wrapper" ref="mapWrapper" class="contents" style="">
          <div id="mymap" class="map" ref="mymap" v-show="ifMap" :style="{height: scrollerHeight}">
              <div v-if="driverInfo !== null" id="info-header-wrapper" class="header-info-wrapper">
                  <div style="display: flex; align-items: center; z-index: 99999;box-shadow: rgb(0 0 0 / 14%) 0px 0px 10px;bottom: 20px;left: 22px;
                   border-radius: 4px;position: absolute; background-color: #FFFFFF; text-shadow: 0 0 black; padding: 0px 0px 21px 0;">
                      <img style="padding: 0 5px 0px 0px;border-radius: 50px; position: absolute; bottom: 67px;left: 23px"
                           mode="widthFix" src="@/assets/img/driver.jpg" width="50" height="50"/>
                      <p style="font-weight: bold;font-size: 15px;font-family: fangsong;padding:10px 5px 10px 24px;">{{driverInfo.name}}</p>
                      <p style="font-size: 15px;font-family: ；;padding: 0px 5px 0 5px; position: absolute; bottom: 43px;left: 115px;">{{driverInfo.companyName}}</p>
                      <div style="font-size: 15px;font-family: fangsong; padding: 4px 2px;">
                          <label  style="padding: 2px 6px;position: absolute; bottom: 35px;left: 115px;
                                background-color: #4c70e4;color: #FFFFFF; border-radius: 4px;">{{driverInfo.plateNum}}</label>
                      </div>
                      <div style="padding: 0px 10px 0px 10px;">
                          <a :href="'tel:' + 110" style="padding: 4px 10px;color: #FFFFFF ;font-family: fangsong;margin-left: 124px;
                          background-color: #ff0000;color: #ffffff;font-size: 15px;display: flex;align-items: center; border-radius: 20px;">
                              <img style="padding: 0 5px 0px 0px;" mode="widthFix" src="@/assets/img/639@2x.png" />
                              110报警
                          </a>
                      </div>
                      <hr/>
                      <p v-if="currOrder.mainStatus === '3'" style="position: absolute; bottom: -2px;font-size: 10px; padding: 0px 0 0 20px;font-family: fangsong">行程已结束</p>
                      <p v-if="currOrder.mainStatus === '2'" style="position: absolute; bottom: -2px;font-size: 10px; padding: 0px 0 0 5px;font-family: fangsong">
                          行程车辆距离目的地还有<label style="color: #ed6a0c">{{drivingDistance}}km</label>,大约还有<label style="color: #ed6a0c">{{drivingTime}}</label>到达
                      </p>
                      <p v-if="currOrder.mainStatus === '4'" style="position: absolute; bottom: -2px;font-size: 10px; padding: 0px 0 0 20px;font-family: fangsong">行程已取消</p>

                  </div>
              </div>
          </div>
        </div>
  </div>
</template>

<script>
import * as _ from 'lodash';
import moment from "moment";
import driverApi from '@/store/driverApi';

// const jsonp = require('jsonp');

export default {
  name: "shareorder",
  props: {
    popupShow: Boolean,
    mapSearch: Object,
    cityCenter: Object,
    mapPlaceholder: String,
      direction: String,
      shareid: String,
    },
    data() {
      return {

        cpoint: [],//中心点

        map: null,
        ifMap: true,
        center: {lng: 0, lat: 0},
        zoom: 3,

        loadingLocation: false,
        showNoticeBar: false,
        noticeBarContent: '',

        sites: [],
        interval: null,
        currPosInterval: null,
        currPositionInterval: null,
        lastLoadedTime: '',
        countDownSeconds: 0,
        currVeh: null,

        currDrivers: [],
        loadingDrivers: false, // 正在加载司机资料
        clickedPlateNumber: null,

        orders: [], // 照片无效的车辆
        allInvalidStickerVehicles: [], // 所有车辆：待拍照的车辆
        sideBarVehicles: [], // 侧边栏的车辆列表
        carMarkers: new Map(),
        carMarkerSize: 0,

        currentLocationMarker: null,
        currentLocation: null,


        loading: false,

        showCarStopTimeLabel: false,
        zoomClickMarker: false,

        stopCarMinMinutes: 3,
        stopCarCount: 0,

        searchValueInput: '',

        geolocation: null,

        // 导航

        currPosition: null, // 高德的定位结果  https://lbs.amap.com/api/javascript-api/reference/location#m_GeolocationResult
        currLocation: null, // AMap.LngLat
        currLocationAddress: '',

        targetVehicleLL: null,
        targetVehiclePlateNumber: null,
        targetVehiclePlateOid: null,

        checkedDriving: false,
        checkedRiding: true,

        driving: null, // 驾车
        drivingTime: null, // 驾车时间
        riding: null, // 骑行
        // 当前显示项目
        showInstall: {
          id: 7, name: '腾飞-东风汽车'
        },

        // 当前显示的下画状态
        showPictureStatus: {

          id: 0, name: '默认'
        },

        // 轨迹数据
        trajectory: [],
        PathSimplifier: null,
        pathSimplifierIns: null,

        // 当前轨迹正在显示的订单
        currOrder: {},
        // 订单的几个位置
        orderMarkers: {
          depart: null,
          destination: null,
          match: null,
          arrive: null,
          currenPosition: null,
        },
        currMarkers: {},
        orderCoordName: 'gps', // 订单的坐标系
        trajectoryCoordName: 'gps', // 订单的坐标系

        driverInfo: {}, // 司机信息
        curHeight: 0,
        curTrajectory: [],// 当前轨迹数据
        drivingDistance: null,
      }
    },
    mounted() {
      this.initMap();
      this.loadOrderTrajectory();
      this.loadOrders();
      this.getDriverInfo();
      // this.showTrajectory();
      // 在地图上加载矩形
      // this.reloadRectangle();

      // checkedDriving
      // this.loadData();

       this.currPositionInterval = setInterval(() => {
         this.countDownSeconds = 20;
         this.loadOrderTrajectory();
          // this.getLocationData();
       }, 15 * 1000);


      // this.interval = setInterval(() => {
      //     this.countDownSeconds = 15;
      //     this.loadData();
      //     // this.getLocationData();
      // }, 15 * 1000);

      // this.currPosInterval = setInterval(() => {
      //     this.countDownSeconds = 15;
      //     this.getLocationFromAMap2();
      //     // this.getLocationData();
      // }, 15 * 1000);

      // 文字复制
      // let clipboard = new ClipboardJS('#btnCopy1');
      //
      // clipboard.on('success', function (e) {
      //     console.info('Action:', e.action);
      //     console.info('Text:', e.text);
      //     console.info('Trigger:', e.trigger);
      //
      //     this.$message.success({message: `已复制${e.text}`});
      //
      //     e.clearSelection();
      // });
      //
      // var clipboard2 = new ClipboardJS('#btnCopy2');
      //
      // clipboard2.on('success', function (e) {
      //     console.info('Action:', e.action);
      //     console.info('Text:', e.text);
      //     console.info('Trigger:', e.trigger);
      //
      //     this.$message.success({message: `已复制${e.text}`});
      //
      //     e.clearSelection();
      // });
    },
    beforeDestroy() {
      if (this.interval) {
        clearInterval(this.interval)
      }
      if (this.currPosInterval) {
        clearInterval(this.currPosInterval);
      }
      if (this.currPositionInterval) {
        clearInterval(this.currPositionInterval);
      }
    },
    destroyed() {

    },
    computed: {
      searchValue: {
        get() {
          return this.searchValueInput;
        },
        set(val) {
          this.searchValueInput = val.toUpperCase();
        }
      },
      searchValueValid() {
        return this.adutils.plateNumberLiteValid(this.searchValue);
      },

      contentContainerSize() {
        return this.$store.state.contentContainerSize;
      },

      markerCount() {
        return Object.getOwnPropertyNames(this.carMarkers).length;
      },

       scrollerHeight() {

         let h = document.documentElement.clientHeight || document.body.clientHeight;
         return h + 'px'; //减去页面上固定高度height
      }

    },
    watch: {
      currPosition: {
        handler(newVal, val) {
          console.log('watch currLocation ', this.currLocation);
          this.refreshTargetRoute();
        }
      },
      searchValue: {
        handler(newVal, val) {

          if (this.adutils.plateNumberLiteValid(newVal)) {
            this.onSearch();
            this.loadVehicleDrivers('桂A' + newVal);
            this.targetVehiclePlateNumber = '桂A' + newVal;
          } else {
            console.log('invalid searchValue value : ' + newVal);
          }
        }
      },
      invalidStickerVehicles: {
        handler(newVal) {
          if (newVal) {
            let vehicles = (newVal || []).map(item => {
              return {
                ...item,
                plateNumber: item.plateNumber,
                stopTimeStr: item.stopTimeStr,
              }
            });

            this.sideBarVehicles = this.lo.sortBy(vehicles, 'plateNumber');
          }
        }
      },


      'carMarkers': {
        handler(val) {
          // console.log('carMarkers ', val);

          function getMapSize(x) {
            var len = 0;
            for (var key in x) {
              len++;
            }

            return len;
          }

          this.carMarkerSize = getMapSize(val);
        },
        immediate: true,
      },


      // 'cpoint': {
      //     handler(newVal, val) {
      //         let _this = this;
      //         var placeSearch = new AMap.PlaceSearch({
      //             // city 指定搜索所在城市，支持传入格式有：城市名、citycode和adcode
      //             city: '南宁'
      //         })
      //         this.geocoder(newVal);
      //
      //     },
      //     deep: true,
      // },
      contentContainerSize: {
        handler(val) {
          // console.log(val, 'contentContainerSize');
          if (_.isArray(val) && val.length === 2) {

            let newHeight = val[1];
            if (this.$store.state.showMenu) {
              newHeight = newHeight - 50; // 减去底部Tab的高度
            }

            newHeight = newHeight - 44; // 减去顶部tab的高度

            // console.log("Map Container change: " + newHeight);
            // console.log("this.$refs.mymap", this.$refs.mymap);

            this.$refs.mymap.style.height = newHeight + 'px';
            // console.log("this.$refs.mymap.style.height", this.$refs.mymap.style.height);
          }
        }
      },

      currOrder: {
        handler(val) {
          try {
            this.updateOrderMarker();
          } catch (e) {
            console.error('updateOrderMarker ', e)
          }
        }
      }
    },
    methods: {
        onCallPhone(){
            console.log("我要报警辣");
        },

      parseQueryParam() {
        // let lng = this.$route.query.lng;
        // let lat = this.$route.query.lat;

        // let swx = this.$route.query.swx; // south west, x
        // let swy = this.$route.query.swy; // south west, y
        // let nex = this.$route.query.nex; // north east x
        // let ney = this.$route.query.ney; // north east y
        // let zoom = this.$route.query.zoom;
        let zoom = this.$route.query.zoom;
        console.log("zoom:"+ zoom)
        let siteCode = this.$route.query.siteCode;

        let haveFullParams = true;
        // if (!lng || !lat) {
        //     lng = 108.367225;
        //     lat = 22.818672;
        //     haveFullParams = false;
        // }

        if (!zoom) {
          zoom = zoom ? Number(zoom) : 15;
          haveFullParams = false;
        }

        // if (!swx || !swy || !nex || !ney) {
        //     // default value
        //     swx = 108.4024100;
        //     swy = 22.8415300;
        //     nex = 108.4126700;
        //     ney = 22.8483900;
        //     haveFullParams = false;
        // }

        // if (!siteCode) {
        //     siteCode = 'dz';
        //     haveFullParams = false;
        // }

        return {
          // swx,
          // swy,
          // nex,
          // ney,
          zoom,
          siteCode,
          haveFullParams
        };
      },

      reloadRectangle() {

        let q = this.parseQueryParam();
        if (!q.haveFullParams) {
          // this.$router.push(`${this.$route.path}?swx=${q.swx}&swy=${q.swy}&nex=${q.nex}&ney=${q.ney}&siteCode=${q.siteCode}`);
          let defPath = `${this.$route.path}?siteCode=${q.siteCode}&zoom=${q.zoom}`;
          this.$router.push(defPath);
          console.log('路由参数不全，使用默认参数：' + defPath);
        }

        // this.loadSiteByCode(q.siteCode, q.zoom);
      },

      initMap() {
        let _this = this;

        let q = this.parseQueryParam();

        // console.log(this.$route.query);
        //
        // let lng = this.$route.query.lng;
        // let lat = this.$route.query.lat;
        // let queryZoom = this.$route.query.zoom;
        //
        // if (!lng || !lat) {
        //     lng = 108.367225;
        //     lat = 22.818672;
        //     queryZoom = queryZoom ? Number(queryZoom) : 17;
        //     this.$router.push(`${this.$route.path}?lng=${lng}&lat=${lat}&zoom=${queryZoom}`);
        // }

        // let elementResizeDetectorMaker = require("element-resize-detector");
        // const vm = this;
        // let changesize = function (element) {
        //     var width = element.offsetWidth;
        //     var height = element.offsetHeight;
        //
        //     if (vm.$refs.mymap) {
        //         // console.log('vm.$refs.', vm.$refs);
        //
        //         vm.$refs.mymap.style.height = height;
        //         vm.$refs.mapWrapper.style.height = height;
        //     }
        //     console.log("Size change: " + width + "x" + height);
        // };
        // elementResizeDetectorMaker().listenTo(document.getElementById( 'map-root'), _.debounce(changesize, 500));


        let options = {
          resizeEnable: true,
          center: [108.317125, 22.818672],
          zoom: q.zoom,
          mapStyle: 'amap://styles/16fe97ded05d5c2e459a7b73cf7fad73'
        };

        // console.log('map options = ', options);
        this.map = new AMap.Map("mymap", options);

        // this.map.setFeatures("road");  // 单一种类要素显示
        this.map.setFeatures(['road', 'point', 'bg', 'building']); // 多个种类要素显示



          if (!_.isEmpty(this.cityCenter)) {
          this.cpoint = [this.cityCenter.lng, this.cityCenter.lat]; //中心点坐标
          console.log('设置地图中心');
          this.map.setCenter(this.cpoint); //设置地图中心点
        }


        //启动时一次加载所有依赖的组件
        //  https://lbs.amap.com/api/amap-ui/intro
        //加载PathSimplifier，loadUI的路径参数为模块名中 'ui/' 之后的部分
        AMapUI.load(['ui/misc/PathSimplifier'], (PathSimplifier) => {

          if (!PathSimplifier.supportCanvas) {
            alert('当前环境不支持 Canvas！');
            return;
          }

          //启动页面
          this.initPathSimplifier(PathSimplifier);
        });

        // //地图类型切换插件。用户通过该插件进行地图切换。
        // this.map.plugin(["AMap.MapType"], function () {
        //     //地图类型切换
        //     var type = new AMap.MapType({
        //         defaultType: 0,
        //     });
        //     _this.map.addControl(type);
        // });

        // 插件使用说明：
        // https://developer.amap.com/api/javascript-api/guide/abc/plugins#plugin
        // https://developer.amap.com/api/javascript-api/reference/map-control#AMap.ToolBar
        this.map.plugin(["AMap.ToolBar"], function () {
          //加载工具条
          var tool = new AMap.ToolBar({locate: true, position: 'LB', liteStyle: true});
          _this.map.addControl(tool);
        });

        // this.getLocationData();
        // this.getLocationFromAMap();
        // this.getLocationFromAMap2();
        this.currLocation = new AMap.LngLat(108.266228, 22.862768); // 初始为国电智能大厦
        // this.bmap = new BMap.Map("bdMap");
      },

      initPathSimplifier(PathSimplifier) {
        this.PathSimplifier = PathSimplifier;
        if (!_.isEmpty(this.trajectory)) {
          this.showTrajectory();
        }
      },

      onMapWrapperResize() {

      },

      async loadVehicleDrivers(brandnumber) {
        try {
          this.loadingDrivers = true;
          let {status, data} = await this.api.taximis.getRealDrivers({brandnumber});
          if (status === '0') {
            this.currDrivers = data || [];
          }
        } catch (e) {
          this.currDrivers = [];
        } finally {
          this.loadingDrivers = false;
        }
      },

      convertGpsCoordToAmapCoord(lng, lat) {
        var gps = [lng, lat];

        let _this = this;
        AMap.convertFrom(gps, 'gps', function (status, result) {
          console.log('坐标转换为高德地图结果：status=', status, 'result=', result)
          if (result.info === 'ok') {

            var resLnglat = result.locations[0];

            var icon = new AMap.Icon({
              size: new AMap.Size(15, 31), // 图标尺寸
              image: require('@/assets/img/map_car.png'), // Icon的图像
              imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
              imageSize: new AMap.Size(15, 31) // 根据所设置的大小拉伸或压缩图片
            });

            var marker = new AMap.Marker({
              position: resLnglat,
              offset: new AMap.Pixel(0, 0),
              icon: icon, // '//vdata.amap.com/icons/b18/1/2.png', // 添加 Icon 图标 URL
              title: res// resp.data.groupName + "," + resp.data.gpsDatetime
            });


            _this.map.add(marker);
            _this.map.setCenter(resLnglat); //设置地图中心点

          } else {
            _this.$message.info('GPS坐标转换为高德坐标失败：' + result.info);
          }
        });
      },

      onLoad() {

      },


      showInfoWindow(resLnglat) {
        AMap.plugin('AMap.Geocoder', function () {
          let geocoder;

          function regeoCode() {
            if (!geocoder) {
              geocoder = new AMap.Geocoder({
                // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
                city: '南宁'
              })
            }

            geocoder.getAddress(resLnglat,
              function (status, result) {
                if (status === 'complete' && result.regeocode) {

                  var address = result.regeocode.formattedAddress;
                  // showInfoWindow( address);
                  document.getElementById('address').value = address;
                } else {
                  alert(JSON.stringify(result))
                }
              });
          }

          regeoCode();
        })
      },

      showRectOnMap(southWest, northEast) {
        // Rectangle 类：https://lbs.amap.com/api/javascript-api/reference/overlay#rectangle
        var bounds = new AMap.Bounds(southWest, northEast);
        var rectangle = new AMap.Rectangle({
          bounds: bounds,
          strokeColor: 'blue',
          strokeWeight: 1,
          strokeOpacity: 0.7,
          strokeDasharray: [30, 10],
          strokeStyle: 'solid', // dashed, solid
          fillColor: 'blue',
          fillOpacity: 0.2,
          cursor: 'pointer',
          zIndex: 50,
        });

        rectangle.setMap(this.map);
        // 缩放地图到合适的视野级别
        this.map.setFitView([rectangle])
      },

      showCurrentPostionMarker(amapLL) {
        if (this.currentLocationMarker) {
          // 更新 marker 位置
          this.currentLocationMarker.setPosition(this.currentLocation);
        } else {

          let iconImg = 'assets/img/marker_man.png';
          let icon = new AMap.Icon({
            size: new AMap.Size(100, 100),    // 图标尺寸
            image: iconImg,  // Icon 的图像
            imageOffset: new AMap.Pixel(0, 0),  // 图像相对展示区域的偏移量，适于雪碧图等
            imageSize: new AMap.Size(100, 100)   // 根据所设置的大小拉伸或压缩图片
          });

          // https://lbs.amap.com/api/javascript-api/reference/overlay#marker
          let marker = new AMap.Marker({
            position: amapLL,
            offset: new AMap.Pixel(0, 0),
            icon: icon,// '//vdata.amap.com/icons/b18/1/2.png', // 添加 Icon 图标 URL
            anchor: 'center',
            clickable: true,
            topWhenClick: true
          });

          this.currentLocationMarker = marker;
          this.map.add(this.currentLocationMarker);
        }
      },

      showVehicleInfoWindow(vehInfo) {
        // 显示信息窗口
        // 信息窗体的内容

        let _this = this;

        function line(title, value, valueStyle) {
          return [
            '<tr>',
            '<th width="80px " style="color: darkgray; text-align: right; vertical-align: top; font-size: 14px; font-style: normal">',
            title + ':',
            '</th>',
            '<th style="color: black; font-size: 14px; font-style: normal;' + (valueStyle === undefined ? '' : valueStyle) + '">',
            value,
            '</th>',

            '</tr>'
          ].join('');
          // return '<div><span style="color: darkgray;">' + title + '</span>' + '<span >' + value + '</span>' + '</div>'
        }

        var content = [
          '<div style="padding:0px"> <b>车辆实时信息</b></div>',
          '<table border="0" width="300px">',
          // line("编号", vehInfo.vehicleOid),

          line("车牌", vehInfo.plateNumber),
          line("公司", vehInfo.groupName),

          // line('地址', address),

          // line("方向角", vehInfo.direction),
          // line("车速", vehInfo.speed),

          line("载客状态", vehInfo.load ? '载客' : '空车', vehInfo.load ? "color: green;" : ""),

          // line("定位方式", "GPS"),
          // line("在线", vehInfo.online ? '是' : '离线', vehInfo.online ? "color: green;" : "" ),
          // line("GPS正常", vehInfo.gpsNormal ? '是' : '异常', vehInfo.gpsNormal ? "color: green;" : "" ),
          // line("在线状态", ((resp.data.netStatus === 1) ? "在线" : "离线"), ((resp.data.netStatus === 1) ? "color: green;" : "")),
          line("GPS回传", moment(vehInfo.gpsDatetime).format('YYYY/MM/DD HH:mm:ss')),
          '</table>',
          '<div style="padding:0px"> <b>广告贴画信息</b></div>',

          '<table border="0" width="300px">',
          line("项目", _.get(vehInfo, 'installmentName', '')),
          line("画面", _.get(vehInfo, 'image1PictureTypeName', '')),
          line("备注", _.get(vehInfo, 'picture.remarks', '')),
          `<img src="${_.get(vehInfo, 'picture.image1HttpPath')}" width="100px" height="100px" />`,
          `<img src="${_.get(vehInfo, 'picture.image2HttpPath')}" width="100px" height="100px" />`,
          '</table>'
        ];

        // 创建 infoWindow 实例
        let infoWindow = new AMap.InfoWindow({
          content: content.join(
            ""
          ) //传入 dom 对象，或者 html 字符串
        });

        // 打开信息窗体
        infoWindow.open(_this.map, this.getVehicleLL(vehInfo));
      },

      showTrajectory() {
        if (!this.trajectory) {
          console.log('trajectory is empty')
          return;
        }

        console.log('显示轨迹', this.trajectory);

        //创建组件实例
        if (!this.pathSimplifierIns) {
          this.pathSimplifierIns = new this.PathSimplifier({
            zIndex: 100,
            map: this.map, //所属的地图实例
            autoSetFitView: false,
            getPath: (pathData, pathIndex) => {
              //返回轨迹数据中的节点坐标信息，[AMap.LngLat, AMap.LngLat...] 或者 [[lng|number,lat|number],...]
              return pathData.path;
            },
            getHoverTitle: (pathData, pathIndex, pointIndex) => {
              //返回鼠标悬停时显示的信息
              if (pointIndex >= 0) {
                //鼠标悬停在某个轨迹节点上
                return `${pointIndex}/${pathData.path.length} : ${pathData.data[pathIndex].gpsTimeText}`;
              }
              //鼠标悬停在节点之间的连线上
              return pathData.name + '，点数量' + pathData.path.length;
            },
            renderOptions: {
              //轨迹线的样式
              pathLineStyle: {
                strokeStyle: 'yellow',
                lineWidth: 6,
                dirArrowStyle: true
              },
              getPathStyle: (pathItem, zoom) => {
                //https://lbs.amap.com/api/amap-ui/reference-amap-ui/mass-data/pathsimplifier#render
                //  @param  {pathIndex:number, pathData:* }  pathInfo 轨迹信息，包括轨迹索引和轨迹数据
                //  @param  {number} zoom 地图当前的zoom级别
                //  @return {Object} 样式配置
                //假设有一个全局的colors数组

                // let color = colors[pathItem.pathIndex % colors.length]; //依次取颜色
                // console.log('pathItem', pathItem.pathIndex, pathItem.pathData, zoom)
                let color = this.consts.bizStatusTextColor(pathItem.pathData.biz);
                let lineWidth = Math.round(4 * Math.pow(1.1, zoom - 3)); //线宽随zoom增大
                return {
                  pathLineStyle: {
                    strokeStyle: color,
                    // lineWidth: lineWidth
                  }
                };
              }
            }
          });
        }

        //{
        //   name: '大地线',
        //     //创建一条包括500个插值点的大地线
        //     path: this.PathSimplifier.getGeodesicPath([116.405289, 39.904987], [87.61792, 43.793308], 500)
        // }

        // 营运状态。1. 载客 2.接单 3.空驶 4.停运'
        let groupedValues = _.groupBy(this.trajectory, 'bizStatus')
        let pathList = _.transform(groupedValues, (result, value, key) => {
          console.log('key ', this.consts.bizStatusText(key))
          result.push({
            biz: key,
            name: this.consts.bizStatusText(key) || '',
            data: value,
            path: value.map(i => {
              return new AMap.LngLat(i.amapLng, i.amapLat)
            })
          })
        }, [])


        //console.log('pathList', pathList);
        //   [{
        //   name: '轨迹0',
        //   path: this.trajectory
        // }]
        //这里构建两条简单的轨迹，仅作示例
        this.pathSimplifierIns.setData(pathList);
            console.log(this.currOrder.mainStatus);
          if (this.map && this.currOrder.mainStatus !== '3') {
              let driving = new AMap.Driving({
                  // map: this.map,
                  outlineColor: 'mediumpurple',
                  autoFitView: false,
              });
              let currLocation = new AMap.LngLat(this.curTrajectory[this.curTrajectory.length-1].lng, this.curTrajectory[this.curTrajectory.length-1].lat);
              let targetVehicleLL = new AMap.LngLat(this.currOrder.destLongitude, this.currOrder.destLatitude);
              // 根据起终点经纬度规划驾车导航路线
              driving.search(currLocation, targetVehicleLL, (status, result) => {
                  // result 即是对应的驾车导航信息，相关数据结构文档请参考  https://lbs.amap.com/api/javascript-api/reference/route-search#m_DrivingResult
                  try {
                      if (status === 'complete') {
                          this.drivingDistance = result.routes[0].distance/1000;
                          this.drivingTime = new moment.duration(result.routes[0].time, 'second').humanize().replace(" minutes","分钟");
                          console.log('绘制驾车路线完成,还有'+this.drivingDistance+'km,剩余驾驶时间:'+this.drivingTime);
                      } else {
                          this.drivingTime = null;
                          console.error('获取驾车数据失败：' + result)
                      }

                  } catch (e) {
                      console.log('handle search result ', e);
                  }
              });
          }
      },

      onClickMarker(marker) {
        let clickVehicle = marker.getExtData();

        console.log('clickVehicle', clickVehicle);

        this.currVeh = clickVehicle;
        this.targetVehicleLL = new AMap.LngLat(this.currVeh.amapLng, this.currVeh.amapLat);
        this.targetVehiclePlateNumber = this.currVeh.plateNumber;
        this.targetVehiclePlateOid = this.currVeh.vehicleOid;

        this.refreshTargetRoute();

        // 清除其他 Label

        // for (let [key,marker] of Object.entries(this.carMarkers)) {
        //
        // }
        // console.log('carMarkers', Object.keys(this.carMarkers));
        _.forIn(this.carMarkers, (value, key) => {


          if (key.startsWith('_')) {
            return;
          }

          if (key.startsWith('桂A')) {

            let {m1} = value;
            // if (markerItem.getLabel()) {
            //     console.log(key + ' label 清除');
            //     markerItem.setLabel(null)
            // }
            let vehInfo = m1.getExtData();
            if (vehInfo && vehInfo.highlight) {

              console.warn("恢复图标尺寸", vehInfo);

              // 不是当前点击的图标都恢复尺寸
              if (this.zoomClickMarker) {
                if (key !== clickVehicle.plateNumber) {

                  let icon = marker.getIcon();
                  if (icon) {
                    let w = icon.getImageSize().getWidth();
                    let h = icon.getImageSize().getHeight();
                    const clickedIconSizeRatio = 1.5;
                    let newW = w / clickedIconSizeRatio;
                    let newH = h / clickedIconSizeRatio;
                    icon.setImageSize(new AMap.Size(newW, newH));

                    delete vehInfo.highlight;
                  }
                }
              }
            }
          }
        });


        // Marker 变大 clickedIconSizeRatio 倍
        if (!clickVehicle.highlight) {
          if (this.zoomClickMarker) {
            //https://lbs.amap.com/api/javascript-api/reference/overlay#icon
            let icon = marker.getIcon();
            if (icon) {
              let w = icon.getImageSize().getWidth();
              let h = icon.getImageSize().getHeight();
              const clickedIconSizeRatio = 1.5;
              let newW = w * clickedIconSizeRatio;
              let newH = h * clickedIconSizeRatio;
              icon.setImageSize(new AMap.Size(newW, newH));


              console.log('标记marker为高亮', clickVehicle);
              clickVehicle['highlight'] = true;
              marker.setIcon(icon);
            }
          }
        }

        marker.setLabel({
          content: [clickVehicle.plateNumber, clickVehicle.stopTimeStr ? clickVehicle.stopTimeStr : clickVehicle.speed + 'km/h'].join(', '),
          offset: new AMap.Pixel(0, 0),
          direction: 'right'
        });
        // this.showVehicleInfoWindow(clickVehicle);

        // 搜索的车牌号发送变化后
        this.searchValue = clickVehicle.plateNumber.replace('桂A', '');
        // this.loadVehicleDrivers(clickVehicle.plateNumber);
      },

      getMarkerIconImage(vehLL) {
        let iconImg = 'assets/img/map_car.png';
        let iconImgGreen = 'assets/img/map_car_green.png';
        let iconImgRed = 'assets/img/map_car_red.png';

        let iconImgGray = 'assets/img/map_car_gray.png';
        let iconImgZero = 'assets/img/map_car_zero_speed.png';
        let iconImgLoad = 'assets/img/map_car_blue.png'; // 重车
        let iconImgLoadZero = 'assets/img/map_car_blue_zero_speed.png'; // 重车

        let iconImgSvg = 'assets/img/marker_car.svg';

        if (_.get(vehLL, 'picture.installmentId') === this.showInstall.id) {
          // 益海嘉里的车
          if (vehLL.gpsOffline) {
            return iconImgRed;
          } else {
            if (vehLL.stopDuration > this.stopCarMinMinutes * 60 * 1000) {
              // 停车
              return iconImgGray;
            } else {
              if (vehLL.load) {
                return (vehLL.speed === 0 ? iconImgLoadZero : iconImgLoad);
              } else {
                return (vehLL.speed === 0 ? iconImgZero : iconImg);
              }

              // if (vehLL.picture.installmentId > 1) {
              //     if (vehLL.load) {
              //         return (vehLL.speed === 0 ? iconImgSvg : iconImgSvg);
              //     } else {
              //         return (vehLL.speed === 0 ? iconImgSvg : iconImgSvg);
              //     }
              // } else {
              //
              // }
            }
          }
        } else {
          return iconImgGreen;
        }
      },

      getMarkerIcon(vehicleData) {
        return new AMap.Icon({
          size: new AMap.Size(15, 31),    // 图标尺寸
          image: this.getMarkerIconImage(vehicleData),  // Icon 的图像
          imageOffset: new AMap.Pixel(0, 0),  // 图像相对展示区域的偏移量，适于雪碧图等
          imageSize: new AMap.Size(15, 31)   // 根据所设置的大小拉伸或压缩图片
        });
      },

      getVehicleLL(veh) {
        return new AMap.LngLat(veh.amapLng, veh.amapLat);
      },

      // isType true 创建小车图标底部的圆圈
      // isType false 创建小车图标
      createCarMarker(vehLoc, isType) {

        let ll = this.getVehicleLL(vehLoc);
        // https://lbs.amap.com/api/javascript-api/reference/overlay#marker
        let marker = new AMap.Marker({
          position: ll,
          offset: new AMap.Pixel(0, 0),
          icon: isType ? this.getShadowIcon(vehLoc) : this.getMarkerIcon(vehLoc),// '//vdata.amap.com/icons/b18/1/2.png', // 添加 Icon 图标 URL
          zIndex: isType ? 99 : 100,
          anchor: 'center',
          angle: vehLoc.direction,
          clickable: true,
          topWhenClick: true
        });
        marker.setExtData(vehLoc);

        if (!isType) {
          if (vehLoc.stopDuration > 3 * 60 * 1000) {
            // 停车
            if (this.showCarStopTimeLabel) {
              if (vehLoc.stopTimeStr) {
                marker.setLabel({
                  content: `<div class='marker-label-info'>${vehLoc.stopTimeStr}</div>`,
                  offset: new AMap.Pixel(0, 0),
                  direction: 'right'
                });
              }
            }
          } else {

            if (_.get(vehLoc, 'picture.remarks')) {
              let remarks = _.truncate(vehLoc.picture.remarks, {length: 8})
              marker.setLabel({
                content: `<div class='marker-label-info'>${remarks}</div>`,
                offset: new AMap.Pixel(0, 0),
                direction: 'right'
              });
            }
          }

          let clickhandler = (e) => {
            console.log('click marker ', vehLoc);
            this.onClickMarker(marker);
            // showInfoWindow(vehLoc);
          };
          marker.on('click', clickhandler);
        }


        return marker;
      },

      showCarMarkersOnMap(vehList) {

        vehList.forEach(this.fixDirection())

        // 车牌号
        let updateItems = [];
        let addItems = [];
        vehList.forEach(item => {

          // 路径规划目标车辆的位置发生了变化
          if (this.targetVehiclePlateNumber === item.plateNumber) {
            this.targetVehicleLL = new AMap.LngLat(item.amapLng, item.amapLat);
            this.refreshTargetRoute();
          }

          if (this.carMarkers[item.plateNumber]) {
            updateItems.push(item);
          } else {
            addItems.push(item);
          }
        });

        let removeItems = [];
        _.forIn(this.carMarkers, (value, key) => {

          if (!_.find(vehList, item => {
            return item.plateNumber === key;
          })) {
            if (key.startsWith('桂A')) {
              removeItems.push(key);
            }
          }
        });

        // console.log('新增：', addItems);
        // console.log('更新：', updateItems);
        // console.log('移除：', removeItems);

        if (!_.isEmpty(removeItems)) {
          removeItems.forEach(carNo => {
            this.map.remove(this.carMarkers[carNo].m1);
            delete this.carMarkers[carNo]
          });
          // this.carMarkers = [];
        }

        let _this = this;

        addItems.forEach(vehLL => {
          let veh = this.createCarMarker(vehLL);
          let vehType = this.createCarMarker(vehLL, true);
          this.map.add(veh);
          this.map.add(vehType);
          this.carMarkers[vehLL.plateNumber] = {m1: veh, m2:vehType};
        });

        updateItems.forEach(updateItem => {
          let targetMarker = this.carMarkers[updateItem.plateNumber];
          if (targetMarker) {
            var ll = new AMap.LngLat(updateItem.amapLng, updateItem.amapLat);
            targetMarker.setPosition(ll);

            let {load, speed, picture, type} = targetMarker.getExtData();

            targetMarker.m1.setIcon(this.getMarkerIcon(updateItem));
            if(updateItem.type !== type ){
              targetMarker.m2.setIcon(this.getShadowIcon(updateItem));
            }
            // if (updateItem.load !== load || updateItem.speed !== speed) {
            //
            // }

            // 更新项目的图标

            // 更新备注
            if (updateItem.picture.remarks !== _.get(picture, 'remarks')) {
              let remarks = '';
              let text = '';
              if (updateItem.picture.remarks) {
                remarks = _.truncate(updateItem.picture.remarks, {length: 8})
                text = `<div class='marker-label-info'>${remarks}</div>`;
              } else {
                text = updateItem.plateNumber
              }

              targetMarker.setLabel({
                content: text,
                offset: new AMap.Pixel(10, 5),
                direction: 'right'
              });
            }


            targetMarker.setExtData(updateItem);

            // console.log('更新：', updateItem);
          }
        });
      },

      showCarsOnMap() {
        // console.log('showMonitorCarsOnMap' );

        let inputCoords = this.orders.map(item => {
          return new AMap.LngLat(item.lng, item.lat)
        });

        // console.log('要转换的坐标', inputCoords);
        let successCallback = (resultArray) => {
          //console.log('坐标转换结果数组：', resultArray);

          this.lo.zipWith(this.orders, resultArray, (veh, ll) => {
            veh['amapLng'] = ll.getLng();
            veh['amapLat'] = ll.getLat();
            return veh;
          });

          this.showCarMarkersOnMap(this.orders);
        };
        let errCb = (reason) => {
          console.log('坐标转换失败：' + reason);
        };


        this.convertGpsToAMapCoord(inputCoords, 'gps', successCallback, errCb);
      },

      drawRectangle(southWest, northEast) {
        console.log('drawRectangle', southWest, northEast);
        // latitude 纬度 22
        // longitude 经度 108
        // var southWest = new AMap.LngLat(108.40241, 22.84153);
        // var northEast = new AMap.LngLat(108.41267, 22.84839);

        // let swPoint = {lng: 108.40241, lat: 22.84153};
        // let nePoint = {lng: 108.41267, lat: 22.84839};


        // let inputPoints = [];
        // inputPoints.push(swPoint);
        // inputPoints.push(nePoint);

        // let inputArray = inputPoints.map(item => [item.lng, item.lat]);

        let inputArray = [southWest, northEast];
        let successCallback = (resultArray) => {
          //console.log('坐标转换结果数组：', resultArray);

          this.showCarMarkersOnMap(resultArray);
        };
        let errCb = (reason) => {
          console.log('坐标转换失败：' + reason);
        };

        this.convertGpsToAMapCoord(inputArray, 'gps', successCallback, errCb);
      },

      drawPolyine(lineArr) {

        let _this = this;
        var polyline = new AMap.Polyline({
          map: _this.amap,
          path: lineArr,
          showDir: true,
          strokeColor: "#28F", //线颜色
          // strokeOpacity: 1,     //线透明度
          strokeWeight: 6, //线宽
          // strokeStyle: "solid"  //线样式
        });

        var passedPolyline = new AMap.Polyline({
          map: _this.amap,
          // path: lineArr,
          strokeColor: "#AF5", //线颜色
          // strokeOpacity: 1,     //线透明度
          strokeWeight: 6, //线宽
          // strokeStyle: "solid"  //线样式
        });

        var marker = new AMap.Marker({
          map: this.map,
          position: lineArr[0],
          icon: "http://webapi.amap.com/images/car.png",
          offset: new AMap.Pixel(-26, -13),
          autoRotation: true,
          angle: -90,
        });

        // var marker = new AMap.Marker({
        //     position: resLnglat,
        //     offset: new AMap.Pixel(0, 0),
        //     icon: icon,// '//vdata.amap.com/icons/b18/1/2.png', // 添加 Icon 图标 URL
        //     title: resp.data.groupName + "," + resp.data.gpsDatetime
        // });

        marker.on('moving', function (e) {
          passedPolyline.setPath(e.passedPath);
        });

        _this.trajectoryMarker = marker;

        _this.map.setFitView();
      },

      /**
       * inCoordName
       * 地球上同一个地理位置的经纬度，在不同的坐标系中，会有少于偏移，国内目前常见的坐标系主要分为三种：

       地球坐标系——WGS84：常见于 GPS 设备，Google 地图等国际标准的坐标体系。
       火星坐标系——GCJ-02：中国国内使用的被强制加密后的坐标体系，高德坐标就属于该种坐标体系。
       百度坐标系——BD-09：百度地图所使用的坐标体系，是在火星坐标系的基础上又进行了一次加密处理。
       */
      convertGpsToAMapCoord(inCoords, inCoordName, successCallback, errCallback) {
        let resultArray = [];
        this.recursiveConvertToAMapCoord(resultArray, inCoords, 0, inCoords.length > 40 ? 40 : inCoords.length, inCoordName, successCallback, errCallback);
        return resultArray;
      },

      convertFrom(inCoords, inCoordName) {
        return new Promise(((resolve, reject) => {
          if (!_.isArray(inCoords)) {
            reject('输入坐标不是数组')
            return;
          }
          if (_.isEmpty(inCoords)) {
            reject('输入坐标为空')
            return;
          }
          // TODO: type 检查
          let resultArray = [];
          try {
            this.recursiveConvertToAMapCoord(resultArray,
              inCoords, 0,
              inCoords.length > 40 ? 40 : inCoords.length, inCoordName,
              (resultArray) => {
                //console.log('坐标转换结果数组：', resultArray);
                resolve(resultArray);
              }, (reason) => {
                reject(reason);
              });

          } catch (e) {
            reject(e)
          }
        }));
      },

      /**
       * 转换指定坐标为高德坐标
       * @param resultArray
       * @param inputArray LngLat 数组或者 LngLat 实例
       * @param currIndex
       * @param endIndex
       * @param coordName 坐标系名称，'gps'，'bd-09'
       * @param successCallback
       * @param errCallback
       */
      recursiveConvertToAMapCoord(resultArray, inputArray, currIndex, endIndex, coordName, successCallback, errCallback) {
        // console.log('------');
        // console.log('- amap 转换坐标', '坐标总数', inputArray.length, '个，从第', currIndex,'个转换到第', endIndex, '个(不包含)');

        // if (lineArr.length - currIndex > 40) {
        // endIndex = currIndex + 40; // not include endIndex element
        var tempInputArray = inputArray.slice(currIndex, endIndex);


        // https://lbs.amap.com/api/jsapi-v2/guide/transform/convertfrom
        AMap.convertFrom(tempInputArray, coordName, (status, result) => {

          if (result.info === 'ok') {
            result.locations.forEach(function (loc) {
              resultArray.push(loc);
            });

            // console.log(`√amap坐标系转换成功, ${currIndex} ${endIndex}`, '转换了', result.locations.length, '(result.locations.length)个坐标')

            if (endIndex >= inputArray.length) {
              // console.log('结束转换：', currIndex, endIndex);

              if (typeof successCallback === 'function') {
                successCallback(resultArray);
              }
            } else {
              // 81
              // 0 - 40
              // 40 - 80
              let newEndIndex = (endIndex + 40) < inputArray.length ? endIndex + 40 : inputArray.length;
              this.recursiveConvertToAMapCoord(resultArray, inputArray, endIndex, newEndIndex, coordName, successCallback, errCallback);
            }
          } else {
            if (typeof errCallback == 'function') {
              console.error('convertFrom with error ', result);
              errCallback(result.info)
            }
            console.warn('坐标转换失败, status= ', status, 'result=', result)
          }
        })
      },

      convertCoordArray(coordArray) {
        var lineArr = coordArray.map(function (item) {
          return [item.lng, item.lat];
        });


      },

      /**
       * 高度坐标转百度
       * @param gg_lng
       * @param gg_lat
       * @returns {{bd_lng: number, bd_lat: number}}
       */
      bd_encrypt(gg_lng, gg_lat) {
        var X_PI = Math.PI * 3000.0 / 180.0;
        var x = gg_lng, y = gg_lat;
        var z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * X_PI);
        var theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * X_PI);
        var bd_lng = z * Math.cos(theta) + 0.0065;
        var bd_lat = z * Math.sin(theta) + 0.006;
        return {
          bd_lat: bd_lat,
          bd_lng: bd_lng
        }
      },
      showNotification(msg) {
        this.noticeBarContent = msg;
        this.showNoticeBar = true;
      },

      getLocationData() {
        const _this = this;

        // //定位
        // Toast.loading({
        //     mask: true,
        //     message: '加载当前位置信息...',
        //     duration: 0,
        // });

        function showError(error) {
          _this.loadingLocation = false;
          let msg = '';
          switch (error.code) {
            case error.PERMISSION_DENIED:
              msg = "未授权浏览器获取地理位置，可以手动设置上车地点。";
              break;
            case error.POSITION_UNAVAILABLE:
              msg = "地理位置信息不可用。";
              break;
            case error.TIMEOUT:
              msg = "请求地理位置超时。";
              break;
            case error.UNKNOWN_ERROR:
              msg = "无法获取地理位置.";
              break;
          }

          _this.showNotification(msg);
        }

        function showPosition(position) {
          _this.loadingLocation = false;
          let msg = "当前设备纬度: " + position.coords.latitude + " 经度: " + position.coords.longitude;

          // Toast.success(msg);

          // https://lbs.amap.com/api/javascript-api/reference/core/#LngLat

          // let ll = [position.coords.longitude, position.coords.latitude];
          let ll = new AMap.LngLat(position.coords.longitude, position.coords.latitude);
          AMap.convertFrom(ll, 'gps', function (status, result) {
            if (result.info === 'ok') {
              let amapLL = result.locations[0];

              _this.showCurrentPostionMarker(amapLL);

              console.log('获取位置' + amapLL);

              // _this.reverseCurrentPoint(amapLL);

            } else {
              console.warn('坐标转换失败, result = ', result);
            }
          });
        }

        if (navigator.geolocation) {
          let option = {
            enableHighAccuracy: true,
            timeout: 15000,
            maximumAge: 0
          };

          _this.loadingLocation = true;
          navigator.geolocation.getCurrentPosition(showPosition, showError, option);
        } else {
          _this.showNotification('该浏览器不支持获取地理位置');
        }
      },
      reverseCurrentPoint(amapLL) {
        let _this = this;
        AMap.plugin('AMap.Geocoder', function () {
          var geocoder;

          function regeoCode() {

            if (!geocoder) {
              geocoder = new AMap.Geocoder({
                // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
                city: '南宁',
                radius: 1000 //范围，默认：500
              })
            }

            geocoder.getAddress(amapLL,
              function (status, result) {
                if (status === 'complete' && result.regeocode) {
                  var address = result.regeocode.formattedAddress;

                  let formattedAddress = address.replace('广西壮族自治区南宁市', '');
                  formattedAddress = formattedAddress.replace('广西壮族自治区', '');
                  _this.searchWord = formattedAddress;
                  _this.choseMapData = {
                    address: formattedAddress,
                    lng: amapLL.getLng(),
                    lat: amapLL.getLat(),
                  };

                  _this.cpoint = [amapLL.getLng(), amapLL.getLat()]; //中心点坐标
                  _this.map.setCenter(_this.cpoint); //设置地图中心点

                  // _this.startData = {
                  //     address: formattedAddress,
                  //     lng: amapLL.getLng(),
                  //     lat: amapLL.getLat(),
                  // };
                  // _this.cpoint = [_this.startData.lng, _this.startData.lat]; //中心点坐标
                  // _this.map.setCenter(_this.cpoint); //设置地图中心点
                } else {
                  console.warn('逆地理解析当前位置失败');
                }
              });
          }

          regeoCode();
        });
      },

      getLocationFromAMap2() {
        if (!this.map) {
          return;
        }

        // GeolocationResult
        let onComplete = (result) => {
          // https://lbs.amap.com/api/javascript-api/reference/location#m_GeolocationResult

          this.currPosition = result;
          this.currLocation = new AMap.LngLat(result.position.lng, result.position.lat);
          this.currLocationAddress = this.currPosition.formattedAddress
          console.log('定位成功，当前位置', this.currLocation, result);
        };

        // GeolocationError
        let onError = (error) => {
          console.log('定位错误', error);
        };

        this.map.plugin('AMap.Geolocation', () => {
          if (this.geolocation == null) {
            let geolocation = new AMap.Geolocation({
              enableHighAccuracy: true,//是否使用高精度定位，默认:true
              timeout: 10000,          //超过10秒后停止定位，默认：无穷大
              maximumAge: 0,           //定位结果缓存0毫秒，默认：0
              convert: true,           //自动偏移坐标，偏移后的坐标为高德坐标，默认：true
              showButton: false,        //显示定位按钮，默认：true
              buttonPosition: 'LB',    //定位按钮停靠位置，默认：'LB'，左下角
              buttonOffset: new AMap.Pixel(10, 140),//定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
              showMarker: true,        //定位成功后在定位到的位置显示点标记，默认：true
              showCircle: true,        //定位成功后用圆圈表示定位精度范围，默认：true
              panToLocation: false,     //定位成功后将定位到的位置作为地图中心点，默认：true
              zoomToAccuracy: false      //定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
            });

            this.map.addControl(geolocation);
            AMap.event.addListener(geolocation, 'complete', onComplete);//返回定位信息
            AMap.event.addListener(geolocation, 'error', onError);      //返回定位出错信息

            geolocation.getCurrentPosition();
            this.geolocation = geolocation;
          } else {
            this.geolocation.getCurrentPosition();
          }
        });
      },

      // https://lbs.amap.com/api/javascript-api/guide/services/geolocation
      getLocationFromAMap() {
        console.log('从高德获取位置');

        const _this = this;
        // Geolocation的定位流程以及定位失败的原因？
        // https://lbs.amap.com/faq/web/javascript-api/80
        var geolocation = new AMap.Geolocation({
          // 是否使用高精度定位，默认：true
          enableHighAccuracy: true,
          maximumAge: 0,           //定位结果缓存0毫秒，默认：0
          // 设置定位超时时间，默认：无穷大
          timeout: 10000,
          // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
          // buttonOffset: new AMap.Pixel(10, 20),
          //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
          zoomToAccuracy: true,
          //  定位按钮的排放位置,  RB表示右下
          // buttonPosition: 'RB',
          //是否禁止使用IP定位，默认值为0，可选值0-3
          // 0: 可以使用IP定位
          // 1: 手机设备禁止使用IP定位
          // 2: PC上禁止使用IP定位
          // 3: 所有终端禁止使用IP定位
          noIpLocate: 0,
          GeoLocationFirst: true,

          showButton: true,        //显示定位按钮，默认：true
          buttonPosition: 'LB',    //定位按钮停靠位置，默认：'LB'，左下角
          buttonOffset: new AMap.Pixel(10, 20),//定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)

          convert: true,           //自动偏移坐标，偏移后的坐标为高德坐标，默认：true

          showMarker: true,        //定位成功后在定位到的位置显示点标记，默认：true
          showCircle: true,        //定位成功后用圆圈表示定位精度范围，默认：true
          panToLocation: false,     //定位成功后将定位到的位置作为地图中心点，默认：true
        });

        if (!geolocation.isSupported) {
          this.showNoticeBar = true;
          this.noticeBarContent = '浏览器不支持获取GPS定位数据';
        }

        this.loadingLocation = true;
        geolocation.getCurrentPosition((status, result) => {
          console.log('定位信息', status, result);

          _this.loadingLocation = false;

          // if (status == 'error') {
          //     // Toast.fail('获取当前位置信息失败');
          //     if (result.info == 'NOT_SUPPORTED') {
          //         _this.showNotification('当前浏览器不支持定位功能');
          //     } else {
          //         // showNotification(result.message);
          //         _this.showNotification('获取定位失败，请检查是否已打开手机GPS定位功能');
          //     }
          // } else {
          //
          //     // Toast.success('获取当前位置信息成功');
          //     // Toast.clear();
          //
          //     let formattedAddress = result.formattedAddress.replace('广西壮族自治区南宁市', '');
          //     _this.startData = {
          //         address: formattedAddress,
          //         lng: result.position.getLng(),
          //         lat: result.position.getLat(),
          //     };
          //
          //     _this.cpoint = [_this.startData.lng, _this.startData.lat]; //中心点坐标
          //     _this.map.setCenter(_this.cpoint); //设置地图中心点
          // }

        })
      },


      /*
      加载贴画无效的车车辆
       */
     /* async loadData(showLoading) {

        if (showLoading) {
          this.$message.info('加载数据...');
        }

        try {
          this.loadOrders();
        } catch (e) {
          console.log(e);
        } finally {
          if (showLoading) {

          }
        }
      },*/

      vehicleInfoFilterFun() {
        return (item) => {

          return item;
        }
      },

      async convertTrajectoryCoord(positionList, trajectoryCoordName) {
        let llArray = positionList.map(item => {
          return [item.lng, item.lat]
        })

        try {
          let resultArray = await this.convertFrom(llArray, trajectoryCoordName)
          return this.lo.zipWith(positionList, resultArray, (veh, ll) => {
            veh['amapLng'] = ll.getLng();
            veh['amapLat'] = ll.getLat();
            return veh;
          });

        } catch (e) {
          return Promise.reject('坐标转换失败：' + e);
        }
      },

      /*onClickReload() {
        try {
          this.loadOrders();
        } catch (e) {
          this.$message.error(`加载订单列表异常：` + e)
        }
      },*/

      /*onClickOrder(order) {
        console.log('click order ', order);
        this.currOrder = order;
        // this.loadOrderTrajectory(order.orderId);
      },*/
      async getDriverInfo(){
          let form = {
              shareId: this.shareid,
          }
          let {status, data} = await driverApi.getDriverInfo(form);
          if(status === '0'){
              data.name = data.name.substr(0, 1) + "师傅";
              this.driverInfo = data;
              this.driverInfo.userPic = this.driverInfo.userPic == null ? "@/assets/img/driver1.jpg" :  this.driverInfo.userPic;
          }
      },

      async loadOrderTrajectory() {
        let form = {
          shareId: this.shareid,
        }
        console.log(JSON.stringify(form))
        let {status, data, message} = await driverApi.getOrderPosition(form);
        if (status === '0') {
          data = data || []
          data.forEach(item => {
            if (item.gpsTime) {
              item.gpsTimeText = moment(item.gpsTime).format('MM-DD HH:mm:ss');
            } else {
              item.gpsTimeText = ''
            }
            // item.bizStatusText = this.consts.bizStatusText(item.bizStatus);
            // item.bizStatusTextColor = this.consts.bizStatusTextColor(item.bizStatus);
          })
          this.curTrajectory = data;
          this.trajectory = await this.convertTrajectoryCoord(data || [], this.trajectoryCoordName);
          this.showTrajectory();
        } else {
          this.trajectory = []
          this.$message.info(`订单${orderId}无轨迹`);
        }
      },

      async loadOrders() {
        let form = {
          shareId: this.shareid,
        }
        let {status, data} = await driverApi.getOrderInfo(form);;
        if (status === '0') {
            this.currOrder = data[0];
        } else {
          this.orders = []
        }
      },

      createOrderMarker(lngLat, imgPath, extData, label) {
        let icon = new AMap.Icon({
          size: new AMap.Size(30, 30),    // 图标尺寸
          image: imgPath,  // Icon 的图像
          imageOffset: new AMap.Pixel(0, 0),  // 图像相对展示区域的偏移量，适于雪碧图等
          imageSize: new AMap.Size(30, 30)   // 根据所设置的大小拉伸或压缩图片
        });

        // https://lbs.amap.com/api/javascript-api/reference/overlay#marker
        let marker = new AMap.Marker({
          position: lngLat,
          offset: new AMap.Pixel(0, 0),
          icon: icon, // 添加 Icon 图标 URL
          anchor: 'center',
          clickable: true,
          topWhenClick: true
        });
        marker.setExtData(extData);

        if (label) {
          marker.setLabel({
            content: `<div class='marker-label-info'>${label}</div>`,
            offset: new AMap.Pixel(0, 0),
            direction: 'right'
          });
        }

        let clickhandler = (e) => {
          console.log('click marker ', extData);
          // showInfoWindow(vehLoc);
        };
        marker.on('click', clickhandler);

        return marker;
      },

      async updateOrderMarker() {
        console.log("-----------------------------------------------------------------");
        if (!this.currOrder) {
          return;
        }
        if (!this.currOrder.departLL) {
          let departLL = new AMap.LngLat(this.currOrder.departLongitude, this.currOrder.departLatitude);
          let destLL = new AMap.LngLat(this.currOrder.destLongitude, this.currOrder.destLatitude)
          // let matchLL = new AMap.LngLat(this.currOrder.matchLongitude, this.currOrder.matchLatitude)
          // let arriveLL = new AMap.LngLat(this.currOrder.arriveLongitude, this.currOrder.arriveLatitude)
          try {
            let inCoord = [departLL, destLL]
            let llArray = await this.convertFrom(inCoord, this.orderCoordName)
            console.error('转换坐标llArray ', _.join(inCoord.map(item => {
              return item.getLng() + "," + item.getLat()
            }), '  '))

            this.currOrder.departLL = llArray[0]
            this.currOrder.destLL = llArray[1]
            // this.currOrder.matchLL = llArray[2]
            // this.currOrder.arriveLL = llArray[3]

            if (!this.currOrder.departLL) {
              console.error('转换坐标失败')
              return;
            }
            console.log('订单各坐标转换结果', llArray);
          } catch (e) {
            console.log('转换坐标异常：' + e)
            return;
          }
        }

        if (!_.isEmpty(this.orderMarkers)) {
          let {depart, destination, match, arrive} = this.orderMarkers;

          // 起点（实际上车点）
          if (depart) {
            let extData = depart.getExtData();
            console.log(`currOrder `, this.currOrder, ' depart extData', extData);
            depart.setPosition(this.currOrder.departLL);

          } else {
            this.orderMarkers.depart = this.createOrderMarker(this.currOrder.departLL, require('@/assets/img/order_depart_icon.png'), this.currOrder, this.currOrder.departure);
            this.map.add(this.orderMarkers.depart);
          }

          // 终点
          if (destination) {
            destination.setPosition(this.currOrder.destLL);
          } else {
            this.orderMarkers.destination = this.createOrderMarker(this.currOrder.destLL, require('@/assets/img/order_dest_icon.png'), this.currOrder, this.currOrder.destination);
            this.map.add(this.orderMarkers.destination);
          }

          // 给两点画上一条线
            let line = new AMap.Polyline({
                strokeColor:'#ff0000',
                isOutline:true,
                outlineColor:'white'
            });
            line.setMap(this.map);

            let text = new AMap.Text({
                text:'',
                style:{'background-color':'#ff0000',
                    'border-color':'#ff0000',
                    'font-size':'12px'}
            });
            text.setMap(this.map)

            let p1 = this.currOrder.departLL;
            let p2 = this.currOrder.destLL;
            let textPos = p1.divideBy(2).add(p2.divideBy(2));
            let distance = Math.round(p1.distance(p2));
            let path = [p1,p2];
            line.setPath(path);
            text.setText('相距'+distance+'米')
            text.setPosition(textPos)

            // 根据地图其实和终点设置自适应地图大小
            this.map.setFitView(null, false, [50,130,80,80], this.parseQueryParam().zoom + 1);


            /*// 抢单
            if (match) {
              match.setPosition(this.currOrder.matchLL);
            } else {
              this.orderMarkers.match = this.createOrderMarker(this.currOrder.matchLL, require('@/assets/img/order_match_icon.png'), this.currOrder);
              this.map.add(this.orderMarkers.match);
            }

            // 实际下车点
            if (arrive) {
              arrive.setPosition(this.currOrder.arriveLL);
            } else {
              this.orderMarkers.arrive = this.createOrderMarker(this.currOrder.arriveLL, require('@/assets/img/order_arrive_icon.png'), this.currOrder);
              this.map.add(this.orderMarkers.arrive);
            }*/
        }
      },
        //添加marker标记
      addMarker(position) {
          map.clearMap();
          var marker = new AMap.Marker({
              map: this.map,
              position: position
          });
          //鼠标点击marker弹出自定义的信息窗体
          AMap.event.addListener(marker, 'click', function () {
              infoWindow.open(this.map, marker.getPosition());
          });
       }
    },
  }
</script>

<style scoped>
  .marker-label-info {
    color: lightgoldenrodyellow;
  }

  .map {
    position: absolute;
    margin-top: 0;
    margin-bottom: 0;
    /*height: 900px;*/
  }


  .root-container {
    width: 100%;
    background: #f5f5f5;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
  }

  .header-project-wrapper {

    text-align: left;
    font-size: 11px;
    border-radius: 6px;
    padding: 4px 10px;

    /*top: 10px;*/

    /*margin: 10px;*/
    box-shadow: 0 1px 4px #c6c6c6;
    background-color: white;
  }

  .header-info-wrapper {
    width: auto;
    text-align: left;

    border-radius: 6px;
    padding: 4px 10px;
    left: 8px;
    right: 8px;
    /*top: 10px;*/
    margin-top: 4px;
    /*margin: 10px;*/
    box-shadow: 0 1px 4px #c6c6c6;
    background-color: white;
  }


  .list-item {
    margin-right: 2px;
    background-color: #f0f0f0;
    display: inline-block;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
  }

  .inline-item {
    margin-right: 2px;
    margin-bottom: 3px;
    background-color: #f0f0f0;
    display: inline;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
  }

  .list-item.active {
    background-color: #2797FF;
    color: white;
  }

  .list-item.action {
    background-color: mediumpurple;
    color: white;
  }

  .list-item.action2 {
    background-color: indianred;
    color: white;
  }

  td:first-child {
    width: 36px;
    min-width: 36px;
    font-weight: bold;
    font-size: 12px;
    color: darkslategray;
  }

</style>

<style scoped>

  .start {
    display: flex;
    /*align-items: center;*/
    padding: 20px 0;
    border-bottom: 1px solid #cccccc;
    width: 90%;
  }

  .end {
    display: flex;
    padding: 20px 0;
    width: 90%;

  }

  .phone {
    margin-top: 20px !important;
  }

  .input {
    border-color: lightgray;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    border: none;
    width: 95%;
    border-width: 1px;

    margin-top: 50px;

    height: 50px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 5px 10px rgba(188, 187, 180, 0.16);
    opacity: 1;
    border-radius: 6px;
  }

  .inputPhone {
    border: none;
    margin: 15px 0;
    text-align: center;

    width: 95%;
    height: 50px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 5px 10px rgba(188, 187, 180, 0.16);
    opacity: 1;
    border-radius: 6px;

    font-size: 16px;
    font-family: PingFang SC;

    line-height: 22px;
    /*color:rgba(204,204,204,1);*/
    opacity: 1;
  }

  .calltaxi.active {
    background: rgba(34, 34, 34, 1);
    color: rgba(254, 216, 64, 1);
  }

  .calltaxi.inactive {
    background: rgb(123, 123, 123);
    color: rgb(193, 155, 0);
  }

  .calltaxi {
    /*width:990px;*/
    height: 50px;
    /*background: rgba(34, 34, 34, 1);*/
    box-shadow: 0px 15px 30px rgba(188, 187, 180, 0.2);
    opacity: 1;
    border-radius: 6px;

    width: 95%;

    /*height:40px;*/

    /*border-radius: 8px;*/
    color: white;

    /*line-height: 40px;*/
    /*text-align: center;*/
    /*color:#ffffff;*/
    /*font-size: 16px;*/
    margin-top: 50px;
    outline: none;
    border: none;
    /*background: #777777;*/

    font-size: 18px;
    font-family: PingFang SC;
    text-align: center;
    font-weight: bold;
    line-height: 25px;

    opacity: 1;
  }

  .contents {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .search {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 58px;
  }

  .searchWord {
    width: 100%;
    /*height: 35px;*/
    border: none;
    margin-right: auto;
    margin-left: auto;

    /*padding-left: 15px;*/
    /*height: 40px;*/
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 15px 30px rgba(188, 187, 180, 0.16);
    -moz-box-shadow: 0px 15px 30px rgba(188, 187, 180, 0.16);;
    -webkit-box-shadow: 0px 15px 30px rgba(188, 187, 180, 0.16);;
    opacity: 1;
    border-radius: 6px;
  }

  .line {
    width: 1px;
    height: 20px;
    background: #000000;
    margin: 0 8px;
  }

  .searchCancel {
    font-size: 13px;
    color: #cccccc;
  }

  #mymap {
    width: 100%;
    /*height: 400px;*/
    position: relative;
  }

  #myresult {
    width: 100%;
    height: auto;
    overflow: auto;
    flex: 1;
  }

  .resultItem {
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid #f5f5f5;

  }

  .resultP {
    font-size: 14px;
    color: #444444;
    margin: 0 10px;
    font-weight: bold;
  }

  .resultP2 {
    font-size: 12px;
    color: #999999;
    margin: 1px 10px 0 10px;

  }

  .header-info-wrapper {
      width: auto;
      text-align: left;
      /*position: absolute;*/
      border-radius: 6px;
      padding: 4px 10px;
      left: 8px;
      right: 8px;
      /*top: 10px;*/
      margin-top: 8px;
      /*margin: 10px;*/
      box-shadow: 0 1px 4px #c6c6c6;
      background-color: white;
  }


  .list-item {
      margin-right: 2px;
      background-color: #f0f0f0;
      display: inline-block;
      padding: 4px 8px;
      border-radius: 4px;
      font-size: 12px;
  }

  .inline-item {
      margin-right: 2px;
      margin-bottom: 3px;
      background-color: #f0f0f0;
      display: inline;
      padding: 4px 8px;
      border-radius: 4px;
      font-size: 12px;
  }

  .list-item.active {
      background-color: #2797FF;
      color: white;
  }

  td:first-child {
      width: 40px;
      font-weight: bold;
  }

</style>
