import request from '@/store/driverRequest';
import axios from "axios";

const driverApi = {
    register(data) {
        return request({
            url: `/wx/driver/register`,
            method: 'post',
            data: data
        })
    },

    loadUserInfo(params) {
        return request({
            url: `wx/userInfo`,
            method: 'get',
            params: params
        })
    },

    loadUserInfoByOpenId(params) {
        return request({
            url: `/wx/userInfoByOpenId`,
            method: 'get',
            params: params
        })
    },

    submitPay(params) {
        return request({
            url: `/wx/lost/submitPay`,
            method: 'post',
            params: params
        })
    },
    receiveEntpay(params) {
        return request({
            url: `/wx/driver/reward/receive/entpay`,
            method: 'post',
            params: params
        })
    },

    loadServicePrice() {

        return request({
            url: `/wx/lost/servicePrice`,
            method: 'get'
        })
    },

    login(params) {
        return request({
            url: `/wx/driver/login`,
            method: 'post',
            params: params
        })
    },

    loadBankCards() {
        return request({
            url: `/wx/driver/bankcards`,
            method: 'get',
            params: null
        })
    },

    addBankcard(data) {
        return request({
            url: '/wx/driver/bankcards',
            method: 'post',
            data: data
        })
    },

    updateBankcard(cardId, data) {
        return request({
            url: '/wx/driver/bankcards/' + cardId,
            method: 'post',
            data: data
        })
    },

    loadDriverInfo() {
        return request({
            url: `/wx/driver/info`,
            method: 'get',
            params: null
        })
    },
    loadLostOrders(params) {
        return request({
            url: `wx/lost/orders`,
            method: 'get',
            params: params
        })
    },
    loadLostRewards() {
        return request({
            url: `/wx/driver/rewards`,
            method: 'get',
            params: null
        })
    },

    /**
     * 选择领取失物查找奖励的银行卡号
     * @param params, 两个参数：bankId, lostId
     */
    selectRewardBank(params) {
        return request({
            url: `/wx/driver/reward/receive/bank`,
            method: 'post',
            params: params
        })
    },

    /**
     * 根据shareid查找订单轨迹图
     * @param params, 一个参数：shareid
     */
    getOrderPosition(params) {
        return request({
            url: `/api/common/getOrderPosition`,
            method: 'get',
            params: params
        })
    },
    /**
     * 根据shareid查找司机信息
     * @param params, 一个参数：shareid
     */
    getDriverInfo(params) {
        return request({
            url: `/api/common/getDriverInfo`,
            method: 'get',
            params: params
        })
    },
    /**
     * 根据shareid获取订单信息
     * @param params, 一个参数：shareid
     */
    getOrderInfo(params) {
        return request({
            url: `/api/common/getOrderInfo`,
            method: 'get',
            params: params
        })
    },
};

export default driverApi;
