<template>
    <div>
        <h1>{{message}}</h1>
    </div>
</template>

<script>

    import {mapActions, mapState, mapGetters} from 'vuex';

    export default {
        name: "Error",
        data() {
            return {}
        },
        computed: {
            ...mapState({
                message: 'errorMessage'
            })
        }
    }
</script>

<style scoped>

</style>