<template>
    <div class="root">

        <div class="section-title" >
            <div style="display: flex;align-items: center">
                <img src="../../../img/docoment@2x.png" style="width:17px" alt="">
                <span class="section-title-value">我的订单</span>
            </div>
            <div class="section-title-see" @click="onRouteLostSearch">
                <span>查看详情</span>
                <van-icon name="arrow" color="#4C70E4" />
            </div>
        </div>

        <div style="background: #ffffff;padding-top: 5px;padding-bottom: 10px;border-radius: 8px;margin-top: 10px">
            <!--<van-row class="cell" >-->
                <!--<van-col span="8" ><span class="title">订单号</span></van-col>-->
                <!--<van-col span="16" class="col_value"><span class="value">{{order.id}}</span></van-col>-->
            <!--</van-row>-->

            <van-row class="cell" >
                <van-col span="8" ><span class="title">姓名</span></van-col>
                <van-col span="16"  class="col_value"><span class="value">{{order.owner}}</span></van-col>
            </van-row>

            <van-row class="cell" >
                <van-col span="8" ><span class="title">联系电话</span></van-col>
                <van-col span="16"  class="col_value"><span class="value">{{order.phone}}</span></van-col>
            </van-row>

            <van-row class="cell" >
                <van-col span="8" ><span class="title">物品描述</span></van-col>
                <van-col span="16"  class="col_value"><span class="value">{{order.propertyName}}</span></van-col>
            </van-row>
            <van-row class="cell" v-if="!order.invoiceImgPath" >
                <van-col span="8" ><span class="title">乘车时间</span></van-col>
                <van-col span="16"  class="col_value"><span class="value">{{order.getOnDateStr}}</span></van-col>
            </van-row>


            <!--<van-row class="cell" >-->
                <!--<van-col span="8" ><span class="title">上车地点</span></van-col>-->
                <!--<van-col span="16"  class="col_value"><span class="value">{{order.getOnLocation}}</span></van-col>-->
            <!--</van-row>-->

            <!--<van-row class="cell" >-->
                <!--<van-col span="8" ><span class="title">下车地点</span></van-col>-->
                <!--<van-col span="16"  class="col_value"><span class="value">{{order.getOffLocation}}</span></van-col>-->
            <!--</van-row>-->
        </div>
        <!--<div>-->
            <!--<van-cell title-class="cell-title" value-class="cell-value-id" center title="订单号" :value="order.id"/>-->
            <!--<van-cell title-class="cell-title" value-class="cell-value" title="姓名" :value="order.owner"/>-->
            <!--<van-cell title-class="cell-title" value-class="cell-value" title="物品描述" :value="order.propertyName"/>-->
            <!--<van-cell title-class="cell-title" value-class="cell-value" title="上车地点" :value="order.getOnLocation"/>-->
            <!--<van-cell title-class="cell-title" value-class="cell-value" title="下车地点" :value="order.getOffLocation"/>-->
        <!--</div>-->

    </div>

</template>

<script>

    import Vue from 'vue'
    import {Cell, CellGroup} from 'vant';
    import { Row, Col } from 'vant';

    Vue.use(Row).use(Col);

    Vue.use(Cell).use(CellGroup);

    export default {
        name: "lost-simple-info",
        props: {
            order: Object
        },
        data() {
            return {
                title: ''
            }
        },
        mounted() {
            console.log('----订单信息---',this.order);
        },
        watch: {},
        computed: {},
        methods: {
            onRouteLostSearch(){
                this.$router.push('/lostDetail/' + this.order.id)
            },
        }
    }
</script>


<style scoped>
    .root {
        text-align: left;
    }

    .divider {
        padding: 8px 16px;
        background-color: #f5f5f5;
    }

    .cell-title-id {
        height: 20px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        width: 100px;
        line-height: 20px;
        color: rgba(153, 153, 153, 1);
        opacity: 1;
    }

    .cell-title {
        height: 20px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        width: 100px;
        line-height: 20px;
        color: rgba(153, 153, 153, 1);
        opacity: 1;
    }

    .cell-value {
        text-align: right;
        height: 20px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 20px;
        color: rgba(34, 34, 34, 1);
        opacity: 1;
    }

    .cell{
        padding: 2px 0
    }

    .title{
        font-size:14px;
        font-family:PingFang SC;
        font-weight:400;
        line-height:20px;
        color:#909194;
        opacity:1;
        margin-left: 20px;
    }
    .value{
        width:100%;
        font-size:14px;
        font-family:PingFang SC;
        font-weight:400;
        line-height:20px;
        color:rgba(34,34,34,1);
        opacity:1;

        /*margin-right: 20px;*/
    }
    .col_value{
        text-align: right;
        word-wrap:break-word;
        padding-right:20px
    }
    .section-title{
        display: flex;
        justify-content: space-between;

    }
    .section-title-value{

        font-size:16px;
        font-family:PingFang SC;
        /* font-weight:500; */
        line-height:22px;
        color:rgba(34,34,34,1);
        opacity:1;
        margin-left: 10px;
    }
    .section-title-see{
        display: flex;
        align-items: center;
        font-size:14px;
        font-family:PingFang SC;
        font-weight:400;
        line-height:20px;
        color:rgba(76,112,228,1);
        opacity:1;
    }
</style>
