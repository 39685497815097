import axios from 'axios'
import store from '@/store/store'
import auth from '@/store/auth';
import _ from 'lodash'
// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_CALL_API_URL, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 8000 // request timeout
});

// request interceptor
service.interceptors.request.use(
    config => {
        if (store.state.openId) {
            config.headers['openid'] = auth.getOpenId()
        }

        // console.log('请求', config.url, '参数', config.params, 'headers', config.headers);
        return config;
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
);

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const res = response.data;



        // if the custom code is not 20000, it is judged as an error.
        if (_.has(res,'success')) {
            // TODO check code
            console.log('success response ', res)
            return res;
        } else if(_.has(res,'status') && res.status !== '0') {

            console.log('status response ', res)

            // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
            // /** TOKEN失效 **/
            // 	public final static int ERR_2000_TOKEN_ERR = 2000;
            // 	/** 无效用户 **/
            // 	public final static int ERR_2001_INVALID_USER = 2001;
            // 	/** 密码错误 **/
            // 	public final static int ERR_2002_WRONG_PASSWORD = 2002;
            // 	/** 验签失败 **/
            // 	public final static int ERR_2003_FAIL_TOKEN = 2003;
            if (res.errCode === 401) {
                return Promise.reject(new Error(res.message || '没有TOKEN'));
            }

            if (res.errCode === 2000 || res.code === 2001 || res.code === 2003) {
                // to re-login
                // reset token
                console.error('API请求错误', res.errCode);

                // store.commit('RESET_DRIVER_TOKEN');
                // localStorage.setItem('driverToken', null);
                // location.reload();
            }
            return Promise.reject(new Error(res.message || 'Error'));
        } else {
            console.log('other response ', res)
            return res;
        }
    },
    error => {
        console.log('err' + error); // for debug
        return Promise.reject(error)
    }
);

export default service
