const consts = {
    /*
     失物处理状态
     */
    LOST_HANDLE_STATUS_UNHANDLED: 0,
    LOST_HANDLE_STATUS_HANDLING: 1,
    LOST_HANDLE_STATUS_HANDLED: 2,

    /*
      司机资料审核状态
     */
    INFO_STATUS_UNREVIEWED: 0,
    INFO_STATUS_REVIEWING: 1,
    INFO_STATUS_SUCCESS: 2,
    INFO_STATUS_FAILED: 3,

    /*
     司机资料审核状态
    */
    REWARD_STATUS_UNPAID: 0,
    REWARD_STATUS_PAID: 1,
    REWARD_STATUS_PROCESSING: 2,
    REWARD_STATUS_FAILED: 3,

    /*
     失物查找匹配结果
     */
    // 未匹配车辆
    LOST_MATCH_STATUS_UNMATCHED: 0,
    // 匹配车辆
    LOST_MATCH_STATUS_MATCHED: 1,

    bizStatusText(bizStatus) {
        const s = {
            1: '载客',
            2: '接单',
            3: '空驶',
            4: '停运'
        }
        return s[bizStatus] || ''
    },
    bizStatusTextColor(bizStatus) {
        const s = {
            1: 'blue',
            2: 'orange',
            3: 'red',
            4: 'gray',
        }
        return s[bizStatus] || '';
    }
};

export default consts;
