import Cookies from 'js-cookie'

// 不管是安卓还是 iOS，退出登录微信之后重登陆，都会清除缓存、cookie、和 localStorage
const cache = {

    /**
     * 先存储在 localStorage 再存储在 Cookies
     * @param key
     * @param value
     * @param expires days
     */
    setKey(key, value, expires) {
        // 有些浏览器 localStorage.setItem()异常时，可以使用Cookies来存储token
        try {
            window.localStorage.setItem(key, value);
        } catch (e) {
            console.error(e);
        }

        Cookies.set(key, value, {expires});
    },
    /**
     * 先从 localStorage 获取，获取不到再从 Cookies 获取
     * @param key
     * @returns {string|*}
     */
    getKey(key) {
        try {
            let v = window.localStorage.getItem(key);
            if (!v) {
                console.log(`getKey localStorage找不到${key}，从cookie找`);
                return Cookies.get(key);
            } else {
                return v;
            }
        } catch (e) {
            console.log(`getKey localStorage获取${key}异常，从cookie找`);
            return Cookies.get(key);
        }
    },
    setJson(key, value, expires) {
        // 有些浏览器 localStorage.setItem()异常时，可以使用Cookies来存储token
        try {
            window.localStorage.setItem(key, JSON.stringify(value));
        } catch (e) {
            console.error(e);
        }

        Cookies.set(key, JSON.stringify(value), {expires});
    },
    getJson(key) {
        try {
            let val = window.localStorage.getItem(key);
            if (!val) {
                console.log(`getJson localStorage找不到${key}，从cookie找`);
                let cval = Cookies.get(key);
                if (cval) {
                    return JSON.parse(cval);
                } else {
                    return {};
                }
            } else {
                return JSON.parse(val);
            }
        } catch (e) {
            console.log(`getJson localStorage获取${key}异常，从cookie找`);
            return JSON.parse(Cookies.get(key));
        }
    }
};

export default cache;
