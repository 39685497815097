import request from '@/store/callRequest';
import http from '@/store/http'

export default {

    /**
     * 获取热线呼叫用户的呼叫信息
     * @param accessToken 短信链接里面的token
     * @returns {*}
     */
    fetchCallInfo(accessToken) {
        return http.get(request, '/api/calls/callinfo', {accessToken})
    },
};
