const appConfig = {
    taxiApiUrl: process.env.VUE_APP_TAXI_API_URL,
    cheApiUrl: process.env.VUE_APP_CHE_API_URL,
    appName: process.env.VUE_APP_NAME,
    appVersion: process.env.VUE_APP_VERSION,
    nodeEnv: process.env.NODE_ENV,

    isProd: process.env.NODE_ENV === 'production',
    isDev: process.env.NODE_ENV === 'development'
};

export default appConfig;
