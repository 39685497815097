import { render, staticRenderFns } from "./LostInfo.vue?vue&type=template&id=31077848&scoped=true&"
import script from "./LostInfo.vue?vue&type=script&lang=js&"
export * from "./LostInfo.vue?vue&type=script&lang=js&"
import style0 from "./LostInfo.vue?vue&type=style&index=0&id=31077848&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31077848",
  null
  
)

export default component.exports