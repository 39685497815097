import request from '@/store/taxiRequest';

const lostApi = {

    addOrder(params) {
        return request({
            url: `/wx/lost/orders`,
            method: 'post',
            data: params
        })
    },

    getFileName(params) {
        return request({
            url: `/wx/lost/getFileName`,
            method: 'get',
            params
        })
    },

    getAliyunOSSAccessToken(data) {
        return request({
            url: `/wx/lost/aliyunOSSAccessToken`,
            method: 'get',
            data: data
        })
    },

    // 更新失物查找失主服务类型
    ownerServiceType(orderId, data) {
        return request({
            url: `/wx/lost/orders/${orderId}/ownerServiceType`,
            method: 'patch',
            params: data
        })
    },
    // 获取可用城市列表
    getSwAdministrativeDivision() {
        return request({
            url: `/wx/lost/getSwAdministrativeDivision`,
            method: 'get',
        })
    },


};

export default lostApi;
