<template>
    <div class="container">
        <van-nav-bar :title="$route.meta.navTitle" fixed placeholder left-arrow @click-left="$router.back()"></van-nav-bar>
        <h1>甲方：（有失物查找需求的付费用户）</h1>
        <h1>乙方：广西蛋卷科技有限公司</h1>
        <h1>鉴于：</h1>
        <h1>
            乙方具备出租汽车监控平台的服务能力，并有能力降低甲方在使用巡游出租汽车服务后因甲方自身原因遗落物品（简称“失物”）带来的损失，有偿为甲方提供信息咨询服务。并且甲方有失物查找的需求及付费升级VIP服务的意愿。双方本着平等、自愿的原则，达成如下协议：
        </h1>

        <h2>
            一、服务范围
        </h2>

        <p>
            1、乙方根据甲方提交的失物查找申请表单中所选择的城市提供VIP增值服务。
        </p>

        <p>
            2、乙方要求司机提供失物免费送还服务（仅限所选城市的市区范围内，超出范围由甲方自行与司机另行协商，乙方不参与也不承担由此引起的任何纠纷责任）。
        </p>
        <p>
            3、乙方可提供失物邮寄服务（邮费由甲方承担）。
        </p>

        <h2>
            二、甲方委托乙方查找失物流程
        </h2>

        <p>
            1、甲方通过包括但不限于手机、电脑等电子设备付费购买乙方提供的一次性失物查找VIP增值服务。按乙方要求填写失物查找申请表单，如：基本信息（包括但不限于所在城市、甲方姓名、联系电话等）、物品信息（包括但不限于失物的名称及其他需要说明的特殊信息等）以及遗失过程的基本信息（包括但不限于乘坐巡游出租汽车的时间、地点、费用及其他需要详细描述的信息等），填写完成后向乙方提交失物查找申请表单。
        </p>
        <p>
            2、乙方根据甲方提交的失物查找申请表单查找甲方所乘坐过的符合甲方描述的车辆，并跟进寻找失物。乙方在收到甲方提交的失物查找申请表单之时起48小时内向甲方反馈查找结果。如查找到失物，乙方将及时通知甲方，甲方在收到通知之日起三个工作日内到达指定地点领取或向乙方提供失物送达地点（所选城市的市区范围内免费送达，如超出市内或需邮寄，则另外收取投递费用）。无论是否查找到甲方失物，乙方向甲方通知当次失物查找结果之时，即是甲方委托乙方本次失物查找工作的结束，视为本次VIP增值服务终止。如甲方还需乙方再次查找其他失物，应重新下单向乙方付费购买服务。
        </p>

        <h2>
            三、服务性质：付费服务。
        </h2>
        
        <h2>
            四、费用支付方式及费用性质
        </h2>

        <p>
            1、仅支持微信支付方式。
        </p>
        <p>
            2、费用性质：按订单结算，每一份失物查找申请表单视为一份订单。
        </p>

        <h2>
            五、收费标准及费用描述
        </h2>

        <p>
            1、甲方购买乙方一次性失物查找VIP增值服务支付费用为人民币198元/次。
        </p>

        <p>
            2、若乙方查找到符合甲方提交的失物查找申请表单上填写的失物并通知甲方领取，无论甲方是否领取，乙方均不退还198元的VIP增值服务费用。
        </p>

        <p>
            3、若乙方查找不到符合甲方提交的失物查找申请表单上填写条件的车辆或失物，并且已将查找结果通知甲方，乙方仅收信息服务费人民币28元，剩余的170元在乙方向甲方反馈失物查找结果通知后三个工作日内原路退还甲方。
        </p>

        <p>
            4、如甲方购买了乙方一次性失物查找VIP增值服务后，不向乙方提交失物查找申请表单，或者提交失物查找申请表单填写的行程信息不准确，乙方有权拒绝开展查找工作，甲方购买乙方的一次性失物查找VIP增值服务后七个工作日内仍不提交失物查找申请表单的，乙方收取甲方信息服务费28元后，向甲方原路退还人民币170元，双方一次性失物查找VIP服务协议即刻失效，乙方不向甲方负任何赔偿责任。
        </p>

        <h2>
            六、乙方的权利及义务
        </h2>
        <p>
            1、乙方优先紧急处理甲方提交的失物查找申请表单。
        </p>
        <p>
            2、乙方就当次失物查找工作为甲方提供网络专属客服全程一对一服务。
        </p>
        <p>
            3、乙方找到符合甲方提交的失物查找申请表单上的失物后应及时通知并归还甲方，甲方在收到查找结果之日起三个工作日内到达指定地点领取或向乙方提供失物送达地点及联系人信息（所选城市的市区范围内免费送达，如超出市区范围或需邮寄，则另外收取费用）。
        </p>
        <p>
            4、乙方按甲方提交的失物查找申请表单上填写的信息查找失物，乙方不保证归还失物的完整性，失物符合甲方提交的失物查找申请表单上的失物信息即视为乙方已查找到甲方的失物。
        </p>
        <h2>
            七、甲方的权利及义务
        </h2>
        <p>
            1、甲方应按表单内容要求认真及详细地填写失物查找申请表单。
        </p>
        <p>
            2、甲方确定使用本服务应及时付费。
        </p>
        <p>
            3、甲方有权利了解失物查找的进程和结果。
        </p>
        <p>
            4、甲方有权利选择失物领取的方式。
        </p>
        <h2>
            八、特别声明
        </h2>
        <p>
            1、甲方购买乙方提供的一次性失物查找VIP增值服务并不表示乙方一定能帮甲方找到失物。
        </p>
        <p>
            2、乙方依据甲方提交的失物查找申请表单上的信息，通过技术手段查询甲方所乘坐过的车辆，查询结果受甲方填写行程信息的准确性、巡游出租汽车数据回传等因素影响。
        </p>
        <p>
            3、乙方为独立第三方数据平台，司机的失信行为及其它不当行为与乙方无关，但乙方会及时向所属出租车公司反馈。
        </p>
        <p>
            4、本协议自甲方向乙方支付一次性失物查找VIP增值服务费用人民币198元/次时起生效，至甲方收到乙方失物查找结果通知之时终止。
        </p>
        <p>
            5、乙方提供的一次性失物查找VIP增值服务在不违反国家及行业相关法律法规的前提下，最终解释权归《广西蛋卷科技有限公司》所有。
        </p>
        <p>
            6、甲方已完全知悉本合同条款，及充分理解本协议内容之表述，同意接受本合同条款约束。
        </p>
        <h2>
            九、重要提示
        </h2>
        <p>
            甲方选择付费购买一次性失物查找VIP增值服务，支付成功后即代表甲方同意此协议内容，协议即刻生效，在乙方无违约的情况下甲方不可撤销，乙方保留后续追诉的权利。        
        </p>
    </div>

</template>

<script>
    export default {
        name: "LostAgreement"
    }
</script>

<style scoped>

    .container {
        text-align: left;
        padding: 15px 20px;
    }

    h1 {
        text-align: left;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 18px;
        color: rgba(34, 34, 34, 1);
        opacity: 1;
    }

    h2 {
        text-align: left;
        height: 20px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 18px;
        color: rgba(34, 34, 34, 1);
        opacity: 1;
    }

    p {
        text-align: left;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 18px;
        color: rgba(102, 102, 102, 1);
        opacity: 1;
    }

</style>