<template>
    <div class="root">

        <div class="dividers">
            <div class="order-id">
                <img v-if="!order.id" class="order-id-img" src="@/assets/img/lost/info-icon.png" alt=""> 
                订单详情
                <span v-if="order.id" style="font-weight: 400;font-size: 12px" >（订单编号：<span style="color: #4C70E4;">{{order.id}}</span>）</span>
                <span v-else class="order-id-tips" >请确认无误后，再提交订单</span>
            </div>
        </div>

        <div style="background: #ffffff;padding-top: 5px;padding-bottom: 10px">
            <van-row class="cell" >
                <van-col span="8" ><span class="title">所在城市</span></van-col>
                <van-col span="16" class="value">{{order.city}}</van-col>
            </van-row>
            <van-row class="cell" >
                <van-col span="8" ><span class="title">姓名</span></van-col>
                <van-col span="16" class="value">{{order.owner}}</van-col>
            </van-row>

            <van-row class="cell" v-if="!order.invoice">
                <van-col span="8" ><span class="title">性别</span></van-col>
                <van-col span="16" class="value">{{order.sex}}</van-col>
            </van-row>

            <van-row class="cell" >
                <van-col span="8" ><span class="title">联系电话</span></van-col>
                <van-col span="16" class="value">{{order.phone}}</van-col>
            </van-row>

            <van-row class="line" ></van-row>

            <van-row class="cell" >
                <van-col span="8" ><span class="title">物品描述</span></van-col>
                <van-col span="16" class="value">{{order.propertyName}}</van-col>
            </van-row>

            <van-row class="cell" v-if="!order.invoice">
                <van-col span="8" ><span class="title">物品数量</span></van-col>
                <van-col span="16" class="value">{{order.amount}}</van-col>
            </van-row>

            <van-row class="cell" >
                <van-col span="8" ><span class="title">物品所在位置</span></van-col>
                <van-col span="16" class="value">{{order.propertyPosition}}</van-col>
            </van-row>

            <van-row class="line"></van-row>

            <van-row class="cell"  v-if="!order.invoice">
                <van-col span="8" ><span class="title">上车时间</span></van-col>
                <van-col span="16" class="value">{{order.getOnDateStr}}</van-col>
            </van-row>

            <van-row class="cell"  v-if="!order.invoice">
                <van-col span="8" ><span class="title">下车时间</span></van-col>
                <van-col span="16" class="value">{{order.getOffDateStr}}</van-col>
            </van-row>

            <van-row class="cell" >
                <van-col span="8" ><span class="title">上车地点</span></van-col>
                <van-col span="16" class="value">{{order.getOnLocation}}</van-col>
            </van-row>

            <van-row class="cell" >
                <van-col span="8" ><span class="title">下车地点</span></van-col>
                <van-col span="16" class="value">{{order.getOffLocation}}</van-col>
            </van-row>

            <van-row class="cell" v-if="!order.invoice">
                <van-col span="8" ><span class="title">乘车线路</span></van-col>
                <van-col span="16" class="value">{{order.line}}</van-col>
            </van-row>

            <van-row class="cell" v-if="!order.invoice">
                <van-col span="8" ><span class="title">车费</span></van-col>
                <van-col span="16" class="value">{{order.cost == undefined ? '' : order.cost + '元'}}</van-col>
            </van-row>

            <van-row class="line"></van-row>

            <van-row class="cell" v-if="!order.invoice">
                <van-col span="8" ><span class="title">乘客描述</span></van-col>
                <van-col span="16" class="value">{{order.passengerDesc}}</van-col>
            </van-row>

            <van-row class="cell" v-if="!order.invoice">
                <van-col span="8" ><span class="title">乘客位置</span></van-col>
                <van-col span="16" class="value">{{order.seat}}</van-col>
            </van-row>

            <!-- <van-row class="cell" v-if="!order.invoice">
                <van-col span="8" ><span class="title">司机姓名</span></van-col>
                <van-col span="16" class="value">{{order.driverName}}</van-col>
            </van-row> -->
            <van-row class="cell" v-if="!order.invoice">
                <van-col span="8" ><span class="title">司机性别</span></van-col>
                <van-col span="16" class="value">{{order.driverSex}}</van-col>
            </van-row>
            <!-- <van-row class="cell" v-if="!order.invoice">
                <van-col span="8" ><span class="title">车牌号码</span></van-col>
                <van-col span="16" class="value">{{order.plateNumber}}</van-col>
            </van-row> -->

            <van-row class="cell" v-if="!order.invoice">
                <van-col span="8" ><span class="title">车身颜色</span></van-col>
                <van-col span="16" class="value">{{order.carColor}}</van-col>
            </van-row>
            <van-row class="cell" v-if="order.invoice">
                <van-col span="8" ><span class="title">乘车发票</span></van-col>
                <van-col span="16" class="value">
                    <img :src="order.invoiceImgHttpPath" style="width: 40px;height: 40px;object-fit: contain" @click="preImg(order.invoiceImgHttpPath)">
                </van-col>
            </van-row>

        </div>

    </div>

</template>

<script>

    import Vue from 'vue'
    import {Cell, CellGroup} from 'vant';
    import {Field} from 'vant';
    import {Picker} from 'vant';
    import {ActionSheet} from 'vant';
    import {Popup} from 'vant';
    import {Toast} from 'vant';
    import {DatetimePicker} from 'vant';
    import {NoticeBar} from 'vant';
    import {Icon} from 'vant';
    import {Tab, Tabs} from 'vant';
    import {List} from 'vant';
    import {Tag} from 'vant';
    import {Dialog} from 'vant';
    import { ImagePreview } from 'vant';
    Vue.use(ImagePreview);
    Vue.use(Dialog);

    Vue.use(Tag);

    Vue.use(Icon);
    Vue.use(Toast);
    Vue.use(NoticeBar);
    Vue.use(List);
    Vue.use(Tab).use(Tabs);
    Vue.use(DatetimePicker);
    Vue.use(Popup);
    Vue.use(Toast);
    Vue.use(ActionSheet);
    Vue.use(Picker);
    Vue.use(Field);
    Vue.use(Cell).use(CellGroup);

    export default {
        name: "lost-order-info",
        props: {
            order: Object
        },
        data() {
            return {
                title: ''
            }
        },
        mounted() {
        },
        watch: {},
        computed: {},
        methods: {
            // 图片预览
            preImg(item){
                ImagePreview({
                    images: [item],
                    closeable: true,
                });
            },
        }
    }
</script>


<style lang="scss" scoped>
    .root {
        text-align: left;
    }

    .order-id {
        display: flex;
        align-items: center;
        color: rgba(34,34,34,1);
        font-weight: bold;
        .order-id-img{
            width: 18px;
            height: 18px;
            margin-right: 5px;
        }
        .order-id-tips{
            margin-left: 10px;
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #E65041;
        }
    }
    .line{
        padding: 5px 0px;
        margin-left:20px;
        margin-bottom:5px;
        border-bottom: 1px solid rgba(241,241,241,1);
    }
    .cell{
        padding: 5px 0
    }
    .dividers {
        padding: 17px 20px 8px;
        background-color: #fff;
        margin-top: 10px;
        font-size:16px;
        font-family:PingFang SC;
        font-weight:400;
        line-height:22px;
        color:rgba(153,153,153,1);
        opacity:1;
    }

    .cell-title {
        height: 20px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 20px;
        color: rgba(153, 153, 153, 1);
        opacity: 1;
    }

    .cell-value {
        height: 20px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 20px;
        color: rgba(34, 34, 34, 1);
        opacity: 1;
    }

    .title{
        font-size:14px;
        font-family:PingFang SC;
        font-weight:400;
        line-height:20px;
        color:rgba(153,153,153,1);
        opacity:1;
        margin-left: 20px;
    }
    .value{
        font-size:14px;
        font-family:PingFang SC;
        font-weight:400;
        line-height:20px;
        color:rgba(34,34,34,1);
        opacity:1;
    }
    .invoice{
        width: 100%;
        height: 50px;
    }
</style>
