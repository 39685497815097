<template>
  <div id="app">
    <layout />
  </div>
</template>

<script>
  import Layout from './layout/Main';
export default {
    name: "app",
    components: {
        Layout
    },


}

</script>
<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
  /*margin-top: 60px;*/
}
  .br{
    border: 1px solid red;
  }
  .flex{
    display: flex;
  }
.main {
  display: flex;
  flex-direction: column;
}

div, p, h1, h2, h3, h4, h5, li, input {
  box-sizing: border-box;
}
</style>
