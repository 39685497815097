<template>
    <div style="width:100%;height:100%; alignment: center; text-align: center">
        <van-notice-bar v-if="showNoticeBar" scrollable> {{ noticeBarContent }}</van-notice-bar>
        <div class="selectCity" v-show="activePage === 'form'">
            当前城市：
            <van-dropdown-menu>
                <van-dropdown-item v-model="city" :options="option"/>
            </van-dropdown-menu>
            <span class="noOpenCity" v-if="!showErrorMsg">
        （当前城市暂未开通服务）
      </span>
        </div>
        <div class="content" v-show="activePage === 'form'">
            <div class="card">
                <div class="card_bottom">
                    <!--上车点-->
                    <div class="start" @click="openMap('start')">
                        <img src="../img/icon_start.png" alt="" style="width:6px;height:6px;margin-top: 6px">
                        <span style="font-weight: bold; text-align: left; font-size: 14px;line-height: 18px">{{
                                startData.address
                            }}</span>
                        <van-loading v-if="loadingLocation" size="15px"/>
                    </div>
                    <!--下车点-->
                    <div class="end" @click="openMap('end')">
                        <img src="../img/icon_end.png" alt="" style="width:6px;height:6px;margin-top: 6px">
                        <span style="font-weight: bold;font-size: 14px;line-height: 18px;text-align: left;">{{
                                endData.address
                            }}</span>
                    </div>
                </div>
            </div>
            <van-field type="text" maxlength="11" class="inputPhone" disabled input-align="center" v-model="phone"
                       placeholder="请输入手机号（必填）" clearable/>
            <button type="primary" size="large" class="" :class=" [ 'calltaxi', canCallTaxi ? 'active': 'inactive', ] "
                    @click="onClickCallTaxi">呼叫出租车
            </button>
            <div style="font-size: 9px; color: darkgray; margin-top: 18px;text-align: center">
                本次叫车服务由广西蛋卷科技有限公司提供
            </div>
        </div>
        <div class="contents" :style="showNoticeBar?'':'height: 100vh'" v-show="activePage === 'map'">
            <div class="search" style="margin-right: 16px">
                <van-icon name="arrow-left" size="24px" style="padding: 8px" @click="searchCancel"/>
                <input type="text" class="searchWord" v-model="searchWord" :placeholder="placeholderWorld"
                       @focus="focus">
                <a v-if="!ifMap" @click="ifMap = true;"
                   style=" min-width: 40px; margin-left: 8px; margin-right: 0px; color: green">地图</a>
            </div>
            <div id="mymap" v-show="ifMap">
                <img src="../img/down@2x.png" alt=""
                     style="width:40px;height:40px;position: absolute;z-index: 999;left: 50%;top:50%;transform: translate(-20px,-40px)"
                     id="controlImg">
            </div>
            <div id="myresult">
                <div class="resultItem" v-for="item in searchData" :key="item.title+item.lng" @click="choseItem(item)">
                    <span class="resultP">{{ item.title }}</span>
                    <span class="resultP2">{{ item.address }}</span>
                </div>
            </div>
        </div>
        <div class="result" v-show="activePage === 'result'">
            <img src="../assets/img/call_taxi_success.png" style="width: 130px; height: 109px;"/>
            <div class="call-success-text"> 叫车成功！</div>
            <div class="call-success-text2"> 请保持电话畅通</div>
            <p class="call-success-text3">
                您的叫车请求已发送给周围的出租车司机
                司机将通过您预留的手机号联系您
            </p>
        </div>
        <div v-if="showBottomAd&&activePage === 'form'" class="bannerbox">
            <img src="../assets/img/ad_credit_card.jpg" class="banner"/>
        </div>
        <!--提示当前城市不支持下单-->
        <van-dialog v-model="show" confirm-button-text="知道了" confirm-button-color="#4D72E9" :lazy-render="false"
                    @cancel="sure">
            <div class="dialog">
                <img src="../img/noOpenCity.png" style="width: 95%;height: auto;margin-bottom: 10px">
                <div class="text">当前城市暂未开通服务，敬请期待</div>
            </div>
        </van-dialog>
    </div>
</template>

<style scoped>
.call-success-text {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 25px;
    color: rgba(95, 203, 167, 1);
    opacity: 1;
}

.call-success-text2 {
    font-size: 18px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 25px;
    color: rgba(34, 34, 34, 1);
    opacity: 1;
}

.call-success-text3 {
    font-size: 14px;
    font-family: PingFang SC;
    line-height: 25px;
    color: rgba(153, 153, 153, 1);
    opacity: 1;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    width: 252px;
}
</style>

<script>
import taxiApi from "../store/taxiApi";
import Vue from 'vue'
import axios from 'axios'
import {Button, Col, Dialog, DropdownItem, DropdownMenu, Icon, Loading, NoticeBar, Row, Toast} from 'vant';
import {mapActions, mapState} from 'vuex';

import gcoord from "gcoord";


Vue.use(DropdownMenu).use(DropdownItem)
Vue.use(Row).use(Col);
Vue.use(Loading);
Vue.use(NoticeBar);
Vue.use(Button);
Vue.use(Dialog);
Vue.use(Toast);
Vue.use(Icon);
export default {
    name: "taxi",
    data() {
        return {
            show: false,
            city: '请选择',
            option: [],
            showErrorMsg: true, // 是否显示错误信息（当前城市暂未开通服务）
            positionData: {
                address: "",
                lng: '',
                lat: '',
                point: [],
            },
            showBottomAd: false,
            activePage: 'form', /* form, map, result*/
            startData: {
                address: "选择上车点",
                lng: '',
                lat: '',
            },
            endData: {
                address: "选择下车点",
                lng: '',
                lat: '',
            },
            cpoint: [],//中心点
            phone: undefined,
            map: null,
            ifMap: true,
            center: {lng: 0, lat: 0},
            zoom: 3,
            searchWord: undefined,
            placeholderWorld: '',
            direction: undefined,
            //周围检索数据
            poiData: [],
            //搜索数据
            searchData: [],
            loadingLocation: false,
            showNoticeBar: false,
            noticeBarContent: '',
        }
    },
    async mounted() {
        try {
            await this.getOpenCityList()
        } catch (e) {
            console.log('getCityList error', e)
        }

        this.initMap()

        try {
            await this.getLocationData();
        } catch (e) {
            console.log('getLocationData error', e)
        }
    },
    computed: {
        ...mapState({
            showTabMenu: 'showTabMenu',
            smsAccessToken: 'smsAccessToken',
            callInfo: state => state.callInfo
        }),
        isStartInputEmpty() {
            return this.startData.address === '选择上车点';
        },
        isEndInputEmpty() {
            return this.endData.address === '选择下车点';
        },
        isPhoneInputValid() {
            return (/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.phone));
        },
        canCallTaxi() {
            return !this.isStartInputEmpty && !this.isEndInputEmpty && this.isPhoneInputValid;
        }
    },
    watch: {
        'option': {
            handler(val, oldVal) {
                console.log('option', val)
            },
        },
        'callInfo': {
            handler(newVal) {
                console.log('callInfo changed', newVal)
                if (!this.phone) {
                    this.phone = this.callInfo.phone
                } else {
                    // TODO 弹框询问是否使用新的手机号
                }
            },
            immediate: true
        },
        'searchWord': {
            handler(newVal, val) {
                let _this = this;
                if (newVal == '') {//&&newVal!=undefined
                    // this.suggestion(newVal);
                } else {
                    if (this.ifMap == false) {
                        //加载输入提示插件
                        var autoComplete
                        AMap.plugin('AMap.AutoComplete', () => {
                            let autoOptions = {
                                city: this.city
                            };
                            autoComplete = new AMap.AutoComplete(autoOptions);
                            autoComplete.search(newVal, (status, result) => {
                                console.log('result=>', result)
                                // 搜索成功时，result即是对应的匹配数据
                                this.searchData = [];
                                (result.tips || []).forEach(item => {
                                    let obj = {
                                        title: item.name,
                                        address: item.address.length == 0 ? item.district : item.address,
                                        lng: item.location.lng,
                                        lat: item.location.lat,
                                    }
                                    this.searchData.push(obj)
                                    console.warn('this.searchData：', this.searchData)
                                })
                            })
                        });
                    }
                }
            },
        },
        'cpoint': {
            handler(newVal, val) {
                var placeSearch = new AMap.PlaceSearch({
                    city: this.city
                })

                this.geocoder(newVal);
            },
            deep: true,
        },
        'showTabMenu': {
            handler(newVal) {
                console.log('参数', newVal)
                this.phone = newVal[0]
            }
        },
        city: {
            handler(val, old) {
                this.direction = ''
                this.showErrorMsg = this.option.filter(res => res.value == val)[0].check
                if (!this.showErrorMsg) {
                    this.show = true;
                }
                this.map.setZoomAndCenter(13, this.positionData.lng ? this.positionData.point : this.option.filter(res => res.value == val)[0].point)
                if (!this.positionData.lng) {
                    this.startData = {
                        address: "选择上车点",
                        lng: '',
                        lat: '',
                    }
                    this.endData = {
                        address: "选择下车点",
                        lng: '',
                        lat: '',
                    }
                } else {
                    this.positionData = {
                        address: "",
                        lng: '',
                        lat: '',
                        point: []
                    }
                }

            }
        },
    },
    methods: {
        ...mapActions({
            loadSmsCallInfo: 'loadSmsCallInfo'
        }),
        // 获取运营城市列表
        async getOpenCityList() {
            try {
                const {data: cityList, status, message} = await taxiApi.getOpenCityList();
                if (status == '0') {
                    this.option = []
                    console.log('cityList', cityList)
                    let defaultCity = [{
                        text: '请选择',
                        value: '请选择',
                        check: true,
                        point: [108.363949, 22.822754]
                    }
                    ];
                    let newCityList = []
                    for (let i = 0; i < cityList.length; i++) {
                        /**
                         * {
                         *     "id": 1,
                         *     "areaNumber": 450100,
                         *     "areaCode": "0771",
                         *     "placeName": "南宁市",
                         *     "isDelete": 0,
                         *     "centerLat": 22.8064929356,
                         *     "centerLng": 108.297233556,
                         *     "isShow": 0,
                         *     "swShow": 1,
                         *     "adShow": null
                         * },
                         */

                        let city = cityList[i]

                        // 2023年4月10日 高德的API无法调用，应是新版的接口需要使用key和密钥（需要Nginx配置）
                        // https://lbs.amap.com/api/javascript-api-v2/guide/abc/jscode
                        // let point = await this.switchCity(city.placeName) // 南宁市 -> [lng,lat]

                        let point = [city.centerLng, city.centerLat]

                        if (point && point.length > 0) {
                            let cityMsg = {text: city.placeName, value: city.placeName, check: true, point}
                            newCityList.push(cityMsg)
                        } else {
                            console.error('城市' + city.placeName + '获取中心点失败')
                        }
                    }

                    this.option = defaultCity.concat(newCityList)
                    console.log('get option', this.option)
                } else {
                    Toast.fail(`获取城市失败：${message}`)
                }
            } catch (e) {
                Toast.fail(`城市获取异常：${e}`)
            }
        },
        // 根据城市名获取经纬度
        async switchCity(cityName) {
            //加载行政区划插件
            // https://lbs.amap.com/api/javascript-api/guide/services/district-search
            let district = new AMap.DistrictSearch({
                subdistrict: 0,   //获取边界不需要返回下级行政区
                extensions: 'all',  //返回行政区边界坐标组等具体信息
                level: 'city'  //查询行政级别为 市
            });

            return new Promise((resolve, reject) => {
                district.search(cityName, (status, result) => {
                    if (status == 'complete') {
                        var center = [result.districtList[0].center.lng, result.districtList[0].center.lat]
                        resolve(center)
                    } else if (status == 'error') {
                        reject(result)
                    } else if (status == 'no_data') {
                        resolve([])
                    }
                })
            })
        },

        // 初始化地图
        initMap() {
            this.map = new AMap.Map("mymap", {
                resizeEnable: true,
                zoom: 13,
            });

            this.map.on('moveend', () => {
                let location = this.map.getCenter();
                console.warn('zoom：', this.map.getZoom())
                this.cpoint = [location.getLng(), location.getLat()];
            });
        },
        /**
         * 高度坐标转百度
         * @param gg_lng
         * @param gg_lat
         * @returns {{bd_lng: number, bd_lat: number}}
         */
        bd_encrypt(gg_lng, gg_lat) {
            var X_PI = Math.PI * 3000.0 / 180.0;
            var x = gg_lng, y = gg_lat;
            var z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * X_PI);
            var theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * X_PI);
            var bd_lng = z * Math.cos(theta) + 0.0065;
            var bd_lat = z * Math.sin(theta) + 0.006;
            return {
                bd_lat: bd_lat,
                bd_lng: bd_lng
            }
        },
        //地理解析
        geocoder(lnglat) {
            let Geocoder = new AMap.Geocoder({extensions: 'all'});
            Geocoder.getAddress(lnglat, (status, result) => {
                console.log('逆地理解析', result);
                if (status === 'complete') {
                    let formattedAddress = result.regeocode.formattedAddress.replace(`广西壮族自治区${this.city}`, '');
                    if (this.direction === 'start') {
                        this.startData = {
                            address: formattedAddress,
                            lng: this.cpoint[0],
                            lat: this.cpoint[1],
                        }
                    } else if (this.direction === 'end') {
                        this.endData = {
                            address: formattedAddress,
                            lng: this.cpoint[0],
                            lat: this.cpoint[1],
                        }
                    }
                    this.searchData = [];
                    result.regeocode.pois.forEach(item => {
                        let obj = {
                            title: item.name,
                            address: item.address,
                            lng: item.location.lng,
                            lat: item.location.lat,
                        }
                        this.searchData.push(obj);
                    })

                }
            })
        },
        showNotification(msg) {
            this.noticeBarContent = msg;
            this.showNoticeBar = true;
        },
        async convertToAMapCoords(inCoords, fromCoordName) {
            return new Promise((resolve, reject) => {
                // https://lbs.amap.com/api/javascript-api/reference/lnglat-to-address#m_AMap.convertFrom
                AMap.convertFrom(inCoords, fromCoordName, (status, result) => {
                    console.log('convertToAMapCoords ', status, result)
                    if (status === 'complete') {
                        if (result.info === 'ok') {
                            resolve(result.locations[0])
                        } else {
                            reject(result.info)
                        }
                    } else {
                        reject(result || '错误')
                    }
                })
            })
        },

        async getAddress(amapLL, city = '全国') {
            let _this = this
            return new Promise((resolve, reject) => {
                AMap.plugin('AMap.Geocoder', () => {
                    let geocoder;

                    function regeoCode() {
                        if (!geocoder) {
                            geocoder = new AMap.Geocoder({
                                city: city,
                                radius: 1000
                            })
                        }
                        geocoder.getAddress(amapLL, (status, result) => {
                            if (status === 'complete' && result.regeocode) {
                                resolve(result);
                            } else {
                                reject('逆地理解析失败:' + result.info)
                            }
                        });
                    }

                    regeoCode();
                });
            })
        },
        async getLocationData() {
            const _this = this;

            function showError(error) {
                _this.loadingLocation = false;
                let msg = '';
                switch (error.code) {
                    case error.PERMISSION_DENIED:
                        msg = "未授权浏览器获取地理位置，可以手动设置上车地点。";
                        break;
                    case error.POSITION_UNAVAILABLE:
                        msg = "地理位置信息不可用。";
                        break;
                    case error.TIMEOUT:
                        msg = "请求地理位置超时。";
                        break;
                    case error.UNKNOWN_ERROR:
                        msg = "无法获取地理位置.";
                        break;
                }
                _this.showNotification(msg);
            }

            async function showPosition(position) {
                _this.loadingLocation = false;
                let ll = [position.coords.longitude, position.coords.latitude];

                console.log('坐标转换', ll)

                // let amapLL = await _this.convertToAMapCoords(ll, 'gps')
                // GPS -> 高德
                let gaode = gcoord.transform(ll, gcoord.WGS84, gcoord.GCJ02);
                let amapLL = new AMap.LngLat(gaode[0], gaode[1])
                console.log('坐标转换', ll, amapLL)

                let result = await _this.getAddress(amapLL, _this.city || '全国')
                _this.city = result.regeocode.addressComponent.city;
                console.log('当前城市：' + _this.city)

                const address = result.regeocode.formattedAddress;
                let fmtAddr = address.replace(`广西壮族自治区${_this.city}`, '');
                console.log('当前地址：' + fmtAddr)

                _this.startData = {
                    address: fmtAddr,
                    lng: amapLL.getLng(),
                    lat: amapLL.getLat(),
                };
                _this.positionData = {
                    address: fmtAddr,
                    point: [amapLL.getLng(), amapLL.getLat()],
                    lng: amapLL.getLng(),
                    lat: amapLL.getLat(),
                };

                console.log('startData：', _this.startData)
                console.log('positionData：', _this.positionData)
                // _this.cpoint = [_this.startData.lng, _this.startData.lat]; //中心点坐标
                // _this.map.setCenter(_this.cpoint); //设置地图中心点
                console.log('定位city：', _this.city)
            }

            let position = null;
            try {
                let option = {
                    enableHighAccuracy: true,
                    timeout: 15000,
                    maximumAge: 0
                };
                _this.loadingLocation = true;
                position = await this.getDevicePosition(option);
                _this.loadingLocation = false;
            } catch (reason) {
                _this.showNotification('该浏览器不支持获取位置');
                // _this.showNotification(reason);
            }

            if (position) {
                await showPosition(position)
            }
        },

        /**
         * TODO 获取当前设备的定位
         * @return {Promise<void>}
         */
        async getDevicePosition(option) {
            let _this = this
            return new Promise((resolve, reject) => {
                if (navigator.geolocation) {

                    let showPosition = (position) => {
                        resolve(position)
                    }
                    let showError = (error) => {
                        reject(error)
                    }
                    navigator.geolocation.getCurrentPosition(showPosition, showError, option);
                } else {
                    reject(new Error('该浏览器不支持获取地理位置'))
                }
            })

        },
        getLocationFromAMap() {
            console.log('从高德获取位置');
            var geolocation = new AMap.Geolocation({
                enableHighAccuracy: true,
                timeout: 10000,
                buttonOffset: new AMap.Pixel(10, 20),
                zoomToAccuracy: true,
                buttonPosition: 'RB',
                noIpLocate: 0,
                GeoLocationFirst: true,
            });

            if (!geolocation.isSupported) {
                this.showNoticeBar = true;
                this.noticeBarContent = '浏览器不支持获取GPS定位数据';
            }
            this.loadingLocation = true;
            geolocation.getCurrentPosition((status, result) => {
                console.log('定位信息', status, result);

                this.loadingLocation = false;

                if (status == 'error') {
                    // Toast.fail('获取当前位置信息失败');
                    if (result.info == 'NOT_SUPPORTED') {
                        this.showNotification('当前浏览器不支持定位功能');
                    } else {
                        this.showNotification('获取定位失败，请检查是否已打开手机GPS定位功能');
                    }
                } else {
                    let formattedAddress = result.formattedAddress.replace(`广西壮族自治区${this.city}`, '');
                    this.startData = {
                        address: formattedAddress,
                        lng: result.position.getLng(),
                        lat: result.position.getLat(),
                    };

                    this.cpoint = [this.startData.lng, this.startData.lat]; //中心点坐标
                    this.map.setCenter(this.cpoint); //设置地图中心点
                }

            })
        },
        openMap(type) {
            if (this.city == '请选择') {
                Toast.fail('未选择服务城市');
                return
            }
            this.activePage = 'map';
            this.searchWord = '';
            this.direction = type;
            if (type === 'start') {
                this.placeholderWorld = '搜索上车地点';
                if (this.startData.address != '选择上车点') {
                    this.cpoint = [this.startData.lng, this.startData.lat]; //中心点坐标
                    this.map.setCenter(this.cpoint); //设置地图中心点
                }

            } else if (type === 'end') {

                this.placeholderWorld = '搜索下车地点'
                if (this.endData.address != '选择下车点') {
                    this.cpoint = [this.endData.lng, this.endData.lat]; //中心点坐标
                    this.map.setCenter(this.cpoint); //设置地图中心点
                }
            }

        },
        onClickCallTaxi() {
            if (!this.canCallTaxi) {
                return;
            }
            if (!this.showErrorMsg) {
                this.show = true
                return;
            }

            function checkPhone(phone) {

                if (!(/^1[345789]\d{9}$/.test(phone))) {
                    Dialog.alert({
                        title: '提示',
                        message: '手机号码有误，请重填'
                    }).then(() => {
                        // on close
                    });
                    return false;
                } else {
                    return true;
                }
            }

            const externalOrder = {
                mobile: this.phone,
                origin: this.startData.address,
                originLng: this.startData.lng,
                originLat: this.startData.lat,
                dest: this.endData.address,
                destLng: this.endData.lng,
                destLat: this.endData.lat,
                source: 6,  // 6. 手机H5网页
                channel: 5,  // 0. 蛋卷 1.北方 2.出行南宁APP 3.市民卡APP 4.智桂通APP 5.95128自助服务
                type: 0, // 0 实时。1预约
                name: `${this.phone}`
            }

            //高德坐标转百度坐标
            let originLocation = this.bd_encrypt(externalOrder.originLng, externalOrder.originLat);
            let destLocation = this.bd_encrypt(externalOrder.destLng, externalOrder.destLat);
            externalOrder.originLng = originLocation.bd_lng;
            externalOrder.originLat = originLocation.bd_lat;
            externalOrder.destLng = destLocation.bd_lng;
            externalOrder.destLat = destLocation.bd_lat;


            Toast.loading({
                mask: true,
                message: '正在下发叫车信息...',
                duration: 0,
            });

            axios({
                method: "post",
                url: "/api/external/createOrderFromH5",
                baseURL: this.$store.state.taxiApiUrl,
                headers: {"content-type": "application/json"},
                data: externalOrder,
            }).then(res => {
                if (res.data.errCode == 0) {
                    this.activePage = 'result';
                    this.showBottomAd = false;
                } else {
                    let msg = '叫车请求失败，请稍后重试！';
                    msg = msg + res.data.message;
                    Dialog.alert({
                        title: '提示',
                        message: msg,
                    }).then(() => {
                        // on close
                    });
                }
            }).catch(err => {
                Dialog.alert({
                    title: '异常提示',
                    message: err,
                }).then(() => {
                    // on close
                })
            }).finally(err => {
                Toast.clear();
            })

        },
        //获取附近车辆
        nearbyTaxi() {
            axios({
                url: 'api/driver/getMap',
                baseURL: this.$store.state.taxiApiUrl,
                method: 'post',
                params: {
                    lng: '116.278362',
                    lat: '40.002759'
                },
            }).then(res => {
                console.log(res);
            })

        },
        searchCancel() {
            this.activePage = 'form';
        },
        choseItem(item) {
            if (this.direction == 'start') {
                this.startData = {
                    address: item.title,
                    lng: item.lng,
                    lat: item.lat,
                }
            } else if (this.direction == 'end') {
                this.endData = {
                    address: item.title,
                    lng: item.lng,
                    lat: item.lat,
                }
            }

            this.activePage = 'form';
        },

        //地点输入提示
        suggestion(keyword) {
        },
        focus() {
            this.ifMap = false;
        },
        blur() {
            this.ifMap = true;
        },
        // 不支持城市弹窗
        sure() {
            this.show = true
            console.log('不支持')
        },
    },
}
</script>


<style scoped lang="scss">
/*结果*/
.result {
    margin-top: 100px;
}

/*不支持当前城市弹窗*/
.dialog {
    padding: 50px 30px 30px;

    .text {
        font-family: PingFang SC;
        font-weight: 400;
        color: #20252C;
    }
}

/*选择城市*/
.selectCity {
    width: 90%;
    margin: 0 auto;
    height: 50px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    font-family: PingFang SC;

    ::v-deep.van-dropdown-menu__bar {
        background-color: #EEEEEE;
        box-shadow: none;
    }

    ::v-deep.van-dropdown-menu__title {
        font-weight: bold;
        color: #222222;
    }

    ::v-deep.van-dropdown-menu__title::after {
        border-color: transparent transparent #222222 #222222;
    }

    .noOpenCity {
        font-weight: 400;
        color: #CB1C1C;
    }
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  width: 238px;
  height: 49px;
  margin-top: 20px;
}

.bannerbox {
  /*position: fixed;*/
  margin-top: 50px;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 50px;
  width: 100%;
  overflow: hidden;
  alignment: bottom;
  /*height:120px;*/
}

.banner {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  position: relative;
  width: 100%;
  height: auto;
  display: block;
  background-image: url('../assets/img/ad_credit_card.jpg');
}

.bottom-ad-img {
  position: fixed;

  margin-bottom: 50px;
  height: 150px;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  width: 540px;
}

.card {
  width: 95%;
  /*box-shadow: 0 0 2px #ccc;*/
  border: none;

  margin-top: 20px;
}

.card_top {
  padding: 10px 0;
  color: #ccc;
  font-size: 14px;
}

.card_top {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #cccccc;
}

.card_bottom {
  display: flex;
  border-width: 0;
  border-style: none;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 15px 30px rgba(188, 187, 180, 0.16);
  opacity: 1;
  border-radius: 6px;
  flex-direction: column;
  align-items: center;

}


.start {
  display: flex;
  /*align-items: center;*/
  padding: 20px 0;
  border-bottom: 1px solid #cccccc;
  width: 90%;
}

.end {
  display: flex;
  padding: 20px 0;
  width: 90%;

}

.phone {
  margin-top: 20px !important;
}


img {
  width: 8px;
  height: 8px;
}

span {
  margin-left: 15px;
  font-size: 15px;
  color: #222222;
}

h3 {
  /*color:#222222;*/
  margin-top: 10px;
}

.input {
  border-color: lightgray;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  border: none;
  width: 95%;
  border-width: 1px;

  margin-top: 50px;

  height: 50px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 5px 10px rgba(188, 187, 180, 0.16);
  opacity: 1;
  border-radius: 6px;
}

.inputPhone {
  border: none;
  margin: 15px 0;
  width: 95%;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 5px 10px rgba(188, 187, 180, 0.16);
  border-radius: 6px;
  font-size: 16px;
  font-family: PingFang SC;
  opacity: 1;
}

.calltaxi.active {
  background: rgba(34, 34, 34, 1);
  color: rgba(254, 216, 64, 1);
}

.calltaxi.inactive {
  background: rgb(123, 123, 123);
  color: rgb(193, 155, 0);
}

.calltaxi {
  /*width:990px;*/
  height: 50px;
  /*background: rgba(34, 34, 34, 1);*/
  box-shadow: 0px 15px 30px rgba(188, 187, 180, 0.2);
  opacity: 1;
  border-radius: 6px;

  width: 95%;

  /*height:40px;*/

  /*border-radius: 8px;*/
  color: white;

  /*line-height: 40px;*/
  /*text-align: center;*/
  /*color:#ffffff;*/
  /*font-size: 16px;*/
  margin-top: 50px;
  outline: none;
  border: none;
  /*background: #777777;*/

  font-size: 18px;
  font-family: PingFang SC;
  text-align: center;
  font-weight: bold;
  line-height: 25px;

  opacity: 1;
}

.contents {
  width: 100%;
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
}

.search {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 58px;
  flex-shrink: 0;
}

.searchWord {
  width: 100%;
  /*height: 35px;*/
  border: none;
  margin-right: auto;
  margin-left: auto;

  /*width:823px;*/
  padding-left: 15px;
  height: 40px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 15px 30px rgba(188, 187, 180, 0.16);
  -moz-box-shadow: 0px 15px 30px rgba(188, 187, 180, 0.16);;
  -webkit-box-shadow: 0px 15px 30px rgba(188, 187, 180, 0.16);;
  opacity: 1;
  border-radius: 6px;
}

.line {
  width: 1px;
  height: 20px;
  background: #000000;
  margin: 0 8px;
}

.searchCancel {
  font-size: 13px;
  color: #cccccc;
}

#mymap {
  width: 100%;
  height: 400px;
  flex-shrink: 0;
  position: relative;
}

#myresult {
  width: 98%;
  /*flex-grow: 1;*/
  overflow-y: auto;
}

.resultItem {
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px solid #f5f5f5;

}

.resultP {
  font-size: 14px;
  color: #444444;
  margin: 0 10px;
  font-weight: bold;
}

.resultP2 {
  font-size: 12px;
  color: #999999;
  margin: 1px 10px 0 10px;

}

</style>
