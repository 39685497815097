<template>
  <div class="guide-page">
    <div class="main">
      <div class="guide-title">蛋卷科技为您提供出租车失物查找服务：</div>
      <div class="steps">
        <div class="steps-item">
          <div class="item-number">1</div>
          <div class="item-title">打开微信，点击搜索</div>
          <img class="item-guide-wx-img" src="../../assets/img/lost/guide-wx.png" alt="">
        </div>
        <div class="steps-item">
          <div class="item-number">2</div>
          <div class="item-title">搜索并关注 <span class="title-highlight">“蛋卷出行”</span> 公众号</div>
          <van-button id="clipDjcx" class="item-btn" data-clipboard-text="蛋卷出行" @click="copyText">点我复制“蛋卷出行”</van-button>
        </div>
        <div class="steps-item">
          <div class="item-number">3</div>
          <div class="item-title">关注后点击公众号菜单 <span class="title-highlight">“失物查找”</span>，根据表单填写失物查找信息并提交。</div>
        </div>
      </div>
    </div>
    <div class="footer">
      <img class="footer-img" src="../../assets/img/lost/qr-djcx.png" alt="">
      <span class="footer-text">微信扫码识别二维码关注公众号</span>
    </div>  
  </div>
</template>

<script>
  import Clipboard from "clipboard";
  import Vue from 'vue'
  import {Toast, Step, Steps} from 'vant';
  Vue.use(Toast).use(Step).use(Steps);
  export default {
      name: "guidePage",
      data() {
          return {}
      },
      methods:{
        // 复制文本
        copyText(){
          var clipboard = new Clipboard('#clipDjcx')
          clipboard.on('success', e => {
            Toast('已成功复制到粘贴板')
            console.log('已成功复制到粘贴板');
            //  释放内存
            clipboard.destroy()
          })

          clipboard.on('error', e => {
            // 不支持复制
            Toast('该浏览器不支持复制')
            console.log('该浏览器不支持复制');
            // 释放内存
            clipboard.destroy()
          })
        }
      }

  }
</script>

<style lang="scss" scoped>
.guide-page{
  width: 100%;
  min-height: 100vh;
  background-color: #F2F3FA;
  .main{
    flex: 1;
    width: 100%;
    padding: 26px 22px;
    box-sizing: border-box;
    // background-color: red;
    .guide-title{
      font-size: 17px;
      font-weight: 800;
      color: #3C3B4A;
      font-family: PingFang SC-Heavy, PingFang SC;
    }
    .steps{
      width: 100%;
      margin-top: 15px;
      .steps-item{
        position: relative;
        width: 100%;
        padding: 0 0 27px 17px;
        box-sizing: border-box;
        border-left: 1px dashed #707070;
        &:last-child{
          border-left: 1px dashed transparent;
        }
        .item-number{
          position: absolute;
          left: -10px;
          top: 0px;
          z-index: 2;
          width: 20px;
          height: 20px;
          text-align: center;
          line-height: 20px;
          border-radius: 50%;
          font-size: 15px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #FFFFFF;
          background-color: #344399;
        }
        .item-title{
          margin-bottom: 12px;
          font-size: 15px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #3C3B4A;
          .title-highlight{
            color: #344399;
            font-size: 17px;
            font-weight: bold;
          }
        }
        .item-guide-wx-img{
          width: 139px;
          height: 129px;
        }
        .item-btn{
          display: flex;
          align-items: center;
          height: 34px;
          border-radius: 6px;
          font-size: 14px;
          font-family: PingFang SC-Heavy, PingFang SC;
          font-weight: 800;
          color: #3C3B4A;
          background: #DFE1F0;
        }
      }
    }
  }
  .footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 22px 0 47px;
    background: #344399;
    border-radius: 26px 26px 0px 0px;
    .footer-img{
      display: block;
      width: 156px;
      height: 156px;
    }
    .footer-text{
      margin-top: 9px;
      font-size: 17px;
      font-weight: bold;
      color: #FFFFFF;
      font-family: PingFang SC-Bold, PingFang SC;
    }
  }
}

</style>