<template>
  <div class="banner-box" v-if="bannerList.length">
    <van-swipe class="banner" 
      :autoplay="6000" 
      width="100%"
      height="150"
      :show-indicators="false" 
      indicator-color="white">
      <van-swipe-item class="banner-item" v-for="(item, index) in bannerList" :key="index" @click="onClickBanner(item)">
        <img class="item-img" v-lazy="item.bannerUrl" />
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
  import Vue from "vue";
  import { Toast, Swipe, SwipeItem, Lazyload } from "vant";
  import {mapState, mapActions} from 'vuex'
  import taxiApi from "@/store/taxiApi";
  Vue.use(Toast).use(Swipe).use(SwipeItem).use(Lazyload);
  export default {
    props:{
      //页面来源
      source:{
        type: [Number,String],
        default: null
      },
    },
    data() {
      return {
        bannerList: []
      }
    },
    computed: {
      ...mapState({
        activityList: state => state.activityList
      })
    },
    watch:{
      activityList:{
        handler(val, oldVal){ 
          this.bannerList = val
        },
        
      }
    },
    mounted() {
       
      if(!this.activityList.length){
        this.getBannerList()
      }else{
        this.bannerList = this.activityList
      }
    },
    methods: {
      ...mapActions({
        getBannerList: 'getBannerList',
      }),
      //获取banner数据
      // async getBannerList(){
      //   try {
      //     const { status, data } = await taxiApi.loadActivities() 
      //     if(status == '0'){
      //       this.bannerList = data
      //       console.log('轮播图数据', data);
      //     }
          
      //   } catch (error) {
      //     Toast.fail('获取轮播数据异常：', error)
      //     console.error('获取轮播数据异常：',error);
      //   }
      // },
      //点击轮播图
      onClickBanner(item){
        console.log('点击录播图',item);
        if(!item.url){
          // this.$router.push(`/activityDatail?id=${item.id}`)
          console.log('来源',this.source);
          let routerPath = ''
          if(this.source){
            routerPath = `/activityDatail?id=${item.id}&type=${this.source}`
          }else{
            routerPath = `/activityDatail?id=${item.id}`
          }
          this.$router.push(routerPath)
        }else{
          // 注意：在苹果端微信小程序，使用a元素做跳转，跳转当前项目下的页面链接会没有任何反应的
          // const a = document.createElement('a');
          // a.href = item.url;
          // a.click()
          window.location.href = item.url
        }

        // switch (item) {
        //   case 1:
        //     // this.$router.push('/onlineAgreement')
        //     break;
        
        //   case 2:
        //     // window.location.href = 'https://t.honghusaas.com/2xmWadB';
        //     // 创建一个a标签用于跳转
        //     const a = document.createElement('a');
        //     a.href = 'https://t.honghusaas.com/2xmWadB';
        //     a.click()
        //     break;
        
        //   default:
        //     break;
        // }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .banner-box{
    margin: 16px;
    box-sizing: border-box;
    .banner{
      border-radius: 8px;
      .banner-item{
        border-radius: 8px;
        .item-img{
          border-radius: 8px;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
</style>