import VueRouter from 'vue-router'
import Vue from 'vue'
// 解决多次点击重复路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
import Site from '../views/Site'
import Taxi from '../views/Taxi'
import LostIndex from '../views/lost/LostIndex'
import LostAboutVip from '../views/lost/LostAboutVip'
import LostDetail from '../views/lost/LostDetail'
import CreateOrder from '../views/lost/CreateOrder'
import LostUpgrade from '../views/lost/LostUpgrade'
import Nearby from '../views/Nearby'
import Error from '../views/error/Error'

import appConfig from '../config/appConfig'
import store from '../store/store'
import urlUtil from "../utils/urlUtil";
import auth from '@/store/auth';

import * as _ from 'lodash'
import LostAgreement from "../views/lost/LostAgreement";
import ServeNotice from "../views/ServeNotice";
import ShareOrder from "../views/ShareOrder";
import LostListPage from "@/views/lost/LostListPage";
import GuidePage from "@/views/lost/GuidePage";

Vue.use(VueRouter);
const router = new VueRouter({
    // mode: 'history',
    routes: [
        // 动态路径参数 以冒号开头
        {
            path: '/',
            redirect: '/lost',
            meta: {
                showMenu: true,
            },
            isTabMenu: false,
        },
        {
            path: '/taxi',
            component: Taxi,
            meta: {
                isTabMenu: true, /*是否显示为tab菜单*/
                showMenu: true,
                keepAlive: true,
                saveRoute: true,
                title: `在线叫车 - ${appConfig.appName}`,
                activeImage: require('@/assets/img/taxi.png'),
                inactiveImage: require('@/assets/img/taxid.png')
            },
            name: '在线叫车',
        },
        {
            path: '/taxibd',
            component: () => import('@/views/taxi/index'),
            meta: {
                isTabMenu: true, /*是否显示为tab菜单*/
                showMenu: true,
                keepAlive: true,
                saveRoute: true,
                title: `在线叫车 - ${appConfig.appName}`,
                activeImage: require('@/assets/img/taxi.png'),
                inactiveImage: require('@/assets/img/taxid.png')
            },
            name: 'onlineCallCar',
        },
        {
            path: '/success',
            component: () => import('@/views/taxi/success'),
            meta: {
                isTabMenu: true, /*是否显示为tab菜单*/
                showMenu: true,
                keepAlive: true,
                saveRoute: true,
                title: `在线叫车 - ${appConfig.appName}`,
                activeImage: require('@/assets/img/taxi.png'),
                inactiveImage: require('@/assets/img/taxid.png')
            },
            name: 'success',
        },
        {
            path: '/nearby',
            component: Nearby,
            meta: {
                showMenu: true,
                isTabMenu: false,
                keepAlive: true,
                saveRoute: true,
                title: `附近空车 - ${appConfig.appName}`,
                activeImage: require('@/assets/img/location.png'),
                inactiveImage: require('@/assets/img/location_disable.png')
            },
            name: '附近空车',

        },
        {
            path: '/site',
            component: Site,
            meta: {
                showMenu: true,
                isTabMenu: true,
                keepAlive: true,
                saveRoute: true,
                noAuth: true,
                title: `站点数据 - ${appConfig.appName}`,
                activeImage: require('@/assets/img/station@2x.png'),
                inactiveImage: require('@/assets/img/station_disable@2x.png')
            },
            name: '站点数据',
        },
        {
            path: '/activity/:id',
            props: true,
            component: () => import('@/views/activity/index.vue'),
            meta: {
                showMenu: false,
                isTabMenu: false,
                keepAlive: true,
                saveRoute: true,
                noAuth: true,
                title: `2021南宁出租车千万补贴`,
            },
            name: '出租车活动页面',
        },
        {
            path: '/activityDatail',
            name: 'activityDatail',
            component: ()=>import('@/views/activity/activityDatail'),
            meta: {
                isTabMenu: false,
                needDriverLogin: false,
                showMenu: false,
                saveRoute: false,
                keepAlive: false,
                title: '蛋卷出行'
            }
        },
        {
            path: '/lost',
            component: LostIndex,
            meta: {
                showMenu: true,
                isTabMenu: true,
                keepAlive: true,
                needWechatAgent: true,
                saveRoute: true,
                title: `失物查找 - 蛋卷科技`,
                activeImage: require('@/assets/img/lost.png'),
                inactiveImage: require('@/assets/img/lost_disable.png')
            },
            name: '失物查找',

        }, {
            path: '/aboutVip',
            component: LostAboutVip,
            meta: {
                title: `失物查找 - 蛋卷科技`,
                navTitle: 'VIP服务介绍',
                showMenu: false,
                isTabMenu: false,
                keepAlive: true,
                saveRoute: true,
            },
            name: 'VIP服务',
        }, {
            path: '/serveNotice',
            component: ServeNotice,
            meta: {
                title: `失物查找 - 蛋卷科技`,
                navTitle: '服务类型介绍',
                showMenu: false,
                isTabMenu: false,
                needWechatAgent: true,
                keepAlive: true,
                saveRoute: true,

            },
            name: 'serveNotice',
        },
        {
            path: '/lostList/:lostId',
            component: LostListPage,
            props: true,
            meta: {
                isTabMenu: false,
                showMenu: false,
                keepAlive: false,
                needWechatAgent: true,
                saveRoute: true,
                title: `全部订单`,
                activeImage: '',
                inactiveImage: ''
            },
            name: 'LostListPage',
        },
        {
            path: '/lostDetail/:lostid',
            component: LostDetail,
            props: true,
            meta: {
                title: `失物查找 - 蛋卷科技`,
                navTitle: '订单详情',
                isTabMenu: false,
                showMenu: false,
                needWechatAgent: true,
                keepAlive: false,
                saveRoute: true,
                activeImage: '',
                inactiveImage: ''
            },
            name: '订单详情',

        },
        {
            path: '/createOrder/:page/:invoice/:orderId/:isVipNotPay?',
            component: CreateOrder,
            props: true,
            meta: {
                title: `失物查找 - 蛋卷科技`,
                navTitle: '填写失物订单',
                isTabMenu: false,
                showMenu: false,
                keepAlive: false,
                needWechatAgent: true,
                saveRoute: true,
                activeImage: '',
                inactiveImage: ''
            },
            name: 'createOrder',

        },
        {
            path: '/lostUpgrade/:lostid',
            component: LostUpgrade,
            props: true,
            meta: {
                title: `失物查找 - 蛋卷科技`,
                navTitle: '订单服务升级',
                isTabMenu: false,
                showMenu: false,
                needWechatAgent: true,
                keepAlive: false,
                saveRoute: true,
                activeImage: '',
                inactiveImage: ''
            },
            name: '失物查找服务升级',

        },

        {
            path: '/lostAgreement',
            component: LostAgreement,
            props: true,
            meta: {

                title: `失物查找 - 蛋卷科技`,
                navTitle: '付费失物查找协议',
                showMenu: false,
                isTabMenu: false,
                needWechatAgent: true,
                keepAlive: true,
                saveRoute: true,
                activeImage: '',
                inactiveImage: ''
            },
            name: '《付费失物查找协议》',
        },
        {
            name: '错误',
            path: '/error',
            component: Error,
            meta: {
                needDriverLogin: false,
                isTabMenu: false,
                showMenu: false,
                keepAlive: true,
                saveRoute: true,
                title: `错误`,
            },
        },
        {
            name: '404',
            path: '/404',
            component: () => import('@/views/NotFound.vue'),
            meta: {
                isTabMenu: false,
                showMenu: false,
                needDriverLogin: false,
                keepAlive: true,
                saveRoute: true,
                title: `页面不存在`,
            },
        }, {
            path: '/shareOrder/:shareid',
            component: ShareOrder,
            props: true,
            meta: {
                isTabMenu: false,
                showMenu: false,
                keepAlive: false,
                saveRoute: true,
                title: `行程分享`,
                activeImage: '',
                inactiveImage: ''
            },
            name: '行程分享',

        },
        {
            path: '/guidePage',
            component: GuidePage,
            name: 'guidePage',
            meta: {
                isTabMenu: false,
                showMenu: false,
                needDriverLogin: false,
                needWechatAgent: false,
                keepAlive: true,
                saveRoute: true,
                title: `蛋卷出行`,
            },
        },
        {
            path: '/passengerAgreement/:city?',
            name: 'passengerAgreement',
            component: ()=>import('@/views/agreement/passengerAgreement'),
            meta: {
                isTabMenu: false,
                needDriverLogin: false,
                showMenu: false,
                saveRoute: false,
                keepAlive: true,
                title: '用户服务协议'
            }
        },
        {
            path: '/privacyAgreementB/:city?',
            name: 'privacyAgreementB',
            component: ()=>import('@/views/agreement/privacyAgreementB'),
            meta: {
                isTabMenu: false,
                needDriverLogin: false,
                showMenu: false,
                saveRoute: false,
                keepAlive: true,
                title: '隐私协议'
            }
        },
        {
            path: '/privacyAgreementC/:city?',
            name: 'privacyAgreementC',
            component: ()=>import('@/views/agreement/privacyAgreementC'),
            meta: {
                isTabMenu: false,
                needDriverLogin: false,
                showMenu: false,
                saveRoute: false,
                keepAlive: true,
                title: '隐私协议'
            }
        },
    ]
});
const updateMenuByRoute = (route) => {
    store.commit('SHOW_MENU', route.meta.showMenu || false);
}

const updateDocTitle = (route) => {
    if (route.meta.title) {
        document.title = route.meta.title
    }
}

const filterSaveRoute = (route) => {
    if (route.meta.saveRoute) {
        store.dispatch("pushRouterPath", route.path);
    }
}

// 过滤重定向的路径，
// 短路径（例如，#/l/<accessToken>）跳转到业务页面时，把accessToken保存
/**
 *
 * @param route
 * @param from
 * @param next
 * @returns {boolean} true，表示已经处理
 */
const filterRedirectPath = (route, from, next) => {

    const pathSegments = route.path.split("/");
    if (pathSegments.length === 3) {
        let token = pathSegments[2];
        let nextPath = undefined
        switch (pathSegments[1]) {
            case 'l':
                nextPath = {
                    path: '/lost'
                }
                break;
            case 's':
                nextPath = {
                    path: '/site'
                }
                break;
            case 't':
                nextPath = {
                    path: '/taxi'
                }
                break;
            default:
                return false
        }

        if (nextPath) {
            store.commit('smsAccessToken', token);
            store.dispatch('loadSmsCallInfo')
            next(nextPath)
            return true
        } else {
            return false;
        }
    } else {
        next();
        return true;
    }
}

const handleAuthCallbackRedirect = (to, from, next) => {
    //  路径中带有 /entry/openid/state:/nearby 来自服务端的API的请求的301重定向结果，
    var paths = to.path.split("/");
    let openid = paths[2];
    let state = null;
    if (paths[6] === 'shareOrder') {
        // 二级子路径， /user/login
        state = paths[6] + '/' + paths[7]
    } else {
        // 一级子路径，例如 /login
        state = paths[6];
    }

    store.commit('OPEN_ID', openid);

    //
    // 手机浏览器：使用accessToken去请求失物订单
    // 微信浏览器：公众号授权后，使用openid去请求失物订单
    //
    // loadLostOrders 说明：如果是从 #/l/<accessToken> 跳转到失物查找 #/lost ，继续走微信公众号授权后，这里的加载失物订单则是以openid身份去请求的失物订单。
    //
    store.dispatch('loadLostOrders', {});
    auth.setOpenId(openid);
    console.log('来自后端的公众号授权成功地址跳转 openid=', openid, 'state=', state);
    router.push('/' + state);

    return true; // 返回true表示已经处理
}

const filterWeChatAgent = (to, from, next) => {
    //不是从微信端访问的，一律跳到引导关注蛋卷出行公众号

    if (to.meta && to.meta.needWechatAgent) {
        if (to.name != 'guidePage') {
            next('/guidePage')
            return true;
        } else {
            next();
            return true;
        }
    }

    return false;
}

// 监听浏览器返回事件
window.addEventListener("popstate", () => {
    store.dispatch('popRouterPath')
    console.log('--监听浏览器后退--');
}, false);

/**
 * 检查是否已经走了微信授权
 * @param to
 * @param from
 * @param next
 * @return true, 表示已经处理路由，外部不需要继续处理
 */
const checkWechatAuth = (to, from, next) => {
    if (!store.state.isWechatAgent) {
        // 需要检查微信授权，当前又不是微信浏览器，直接提示
        updateMenuByRoute(to)
        updateDocTitle(to)

        console.log('非微信，跳到引导页')
        next('/guidePage')

        return true;
    } else {

        console.log('当前是微信浏览器')

        let openid = auth.getOpenId();  // 从内存取得openid
        if (openid) {  // 内存里有openid
            console.log('openid===>>', openid)
            console.log('[路由] store.state.openId=', store.state.openId, '新的openid=', openid);
            if (store.state.openId !== openid) {
                if (!_.isEmpty(openid)) {
                    store.commit("OPEN_ID", openid);
                }
            }

            if (store.state.userInfo == null) {
                console.log('内存中有 openid 但是没有用户信息');

                // 触发加载个人数据，不是必须得加载到个人资料（司机中心分支才会要求进入司机主页必须加载到个人资料）
                store.dispatch('loadUserInfoByOpenId', {openId: openid})
                    .then(res => {
                        console.log('userInfo==>>', res)
                        // router.push(to.path);
                    }).catch(reason => {
                    console.log('loadUserInfoByOpenId 异常', reason);
                    // store.commit('setErrorMessage', reason);
                    // router.push("/error")
                });

            } else {
                updateMenuByRoute(to)
                updateDocTitle(to)
                filterSaveRoute(to)

                next();
                return true;
            }

            updateDocTitle(to)
            updateMenuByRoute(to)
            filterSaveRoute(to)

            next();
            return true;

        } else {
            console.log('缓存中没有openid，尝试通过微信授权获取openid');
            // 内存内没有openid
            // 检测是否参数内有code，若有则跳接口获取openid，若没有则跳授权页
            let locationUrl = window.location.href;
            if (locationUrl.indexOf("code") >= 0) {
                console.error('不应该进入这里')
                // 链接里有code
                // 有 code 和 state 表示是从授权URL成功后跳转过来的
                store.dispatch('loadUserInfo', {code: urlUtil.getval(window.location.href, 'code')})
                    .catch(reason => {
                        store.commit('setErrorMessage', reason);
                        router.push("/error")
                    })
                return true;
            } else {
                // 链接里没有code
                let siteUri = locationUrl.replace("/(http[s]*//.*/)(.*)/g", "$2");
                let redirectUri = encodeURIComponent(document.location.protocol + appConfig.taxiApiUrl + "/wx/authCallback?siteUrl=" + siteUri);
                // let redirectUri = encodeURIComponent(window.location.protocol + store.state.taxiApiUrl + "/wx/authCallback");

                let appid = "wx19b938267fa235a3";
                let state = encodeURIComponent(to.path);
                window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_base&state=${state}#wechat_redirect`;  // 跳转微信链接获取code和state（state没啥用目前暂时）

                return true;
            }
        }
    }
}

router.beforeEach((to, from, next) => {

    console.log('╭─==================================');
    console.log('│ = 路由变化从', from.path, ' 👉️ ', to.path);
    console.log('╰─==================================');
    console.log('----浏览器历史 window.history ---', window.history);

    // 微信授权流程：
    // 1. 当前页面请求微信，重定向后端API
    // 2. 后端API处理code得到openid，返回301给前端（/entry/openid/state:/nearby）
    // 3. 前端解析 /entry/... 得到openid
    // 4. 前端调整到state指定的路径（即授权前的路由路径）
    //
    // TODO /entry/openid/state:/nearby 简化为 /entry/<加密的Base64数据>，支持使用state传递对象参数
    //

    // 微信中才有 /entry 调用
    if (store.state.isWechatAgent) {

        // 这个特殊的路径是Vue页面请求微信授权后，如果传递当前页面url（带有#路由路径）给微信的授权url的redirect_uri参数，
        // 微信浏览器跳转到redirect_uri指定的URL会在URL后添加code=xx&state=xx，最终的路径是
        // http://beta.danjuantaxi.com/wx/authCallback?code=xx&state=xx,导致 Vue的路由路径带有Query Parameter而无法工作，无法渲染页面
        //
        //  路径中带有 /entry/openid/state:/nearby 来自服务端的API的请求的301重定向结果，
        if (to.path.indexOf("/entry") > -1) {
            // 解析后端通过url返回的openid，并跳转到 state 指定的路径
            handleAuthCallbackRedirect(to, from, next)
            return;
        }
    }

    const needWechat = _.get(to, 'meta.needWechatAgent') || false
    if (needWechat) {
        if (checkWechatAuth(to, from, next)) {
            return;
        }
    }

    console.log(to.path + '不需要微信授权')

    updateMenuByRoute(to)
    updateDocTitle(to)
    filterSaveRoute(to)

    // 重定向路径处理
    if (filterRedirectPath(to, from, next)) {
        return;
    }

    return next();
});
export default router;
