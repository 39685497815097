<template>

    <div style="width:100%;height:100%; ">

        <van-notice-bar
                v-if="showNoticeBar"
                :scrollable="false">
            {{noticeBarContent}}
        </van-notice-bar>

        <div class="contents">
            <div id="mymap">

            </div>
        </div>

        <van-row style="margin-top: 15px; margin-bottom:15px">
            <van-col span="12">周围空车列表</van-col>
            <van-col span="12">
                <van-button round size="normal" type="primary" @click="onRefreshCarLocation">刷新</van-button>
            </van-col>
        </van-row>

        <div class="info-wrapper">

            <!--            <div class="car-list-container">-->
            <!--                <div class="car-list-item">-->
            <!--                </div>-->
            <!--            </div>-->

            <div class="car-list-header">
                <div class="car-header-text">附近空载出租车</div>
                <van-icon class="icon" name="cross" color="white"></van-icon>
            </div>
            <van-list
                    class="car-list-container"
                    finished-text="没有更多了"
            >
                <van-cell
                        class="car-list-item"
                        v-for="car in cars"
                        :key="car.oid">

                    <!-- 左对齐 -->
                    <van-row type="flex">

                        <van-col span="24">

                            <div style="">

                                <span class="car-icon-wrapper">
                                    <img src="../assets/img/car_list_icon.svg" class="car-list-icon"/>
                                </span>

                                <span class="car-content-wrapper">
                                    <div class="title">
                                        {{car.brandNumber}} <span class="sub-title">| {{car.groupName}}</span>
                                    </div>
                                    <div>
                                        <span class="distance-title">距离您</span>
                                        <span class="distance">{{car.distance}} 米</span>
                                    </div>
                                    <div>
                                        <span class="distance-title">车速</span>
                                        <span class="speed">{{car.speed}} 公里 / 小时</span>
                                    </div>
                                </span>

                                <span class="action-button-wrapper">
                                    <div class="car-action-button" @click="onClickTakeThisCar(car)">我要乘此车</div>
                                </span>
                            </div>

                        </van-col>

                    </van-row>
                </van-cell>
            </van-list>

        </div>

        <van-popup v-model="showDriverInfo"
                   :close-on-click-overlay="true"
                   position="bottom"
                   :overlay="true">

            <div class="show-driver-info">
                <div class="call-driver-header">
                    <div class="title">联系 {{callDriver.brandNumber}} 司机乘车</div>
                </div>

                <div class="call-driver-content">
                    <van-list>
                        <van-cell
                                class="driver-list-item"
                                v-for="driver in callDriver.drivers"
                                :key="driver.phone">

                            <span class="driver-info">
                                <span class="title">{{ driver.name }}</span>
                                <span class="tag">{{ driver.driverTypeName }}</span>
                            </span>

                            <span class="call-button">
                                <a type="primary" size="small" @click="onClickCallDriverButton(driver)"
                                   :href="'tel:' + driver.telephone">呼叫司机</a>
                            </span>

                        </van-cell>
                    </van-list>
                </div>

                <p class="tip">
                    您可以直接拨打司机师傅的电话，说明您的上车地点，让
                    师傅来接您。
                </p>
            </div>

        </van-popup>
    </div>

</template>


<script>
import Vue from 'vue'
import axios from 'axios'

import gcoord from 'gcoord';

import {Button, Col, Dialog, Icon, List, Loading, NoticeBar, Popup, Row, Toast} from 'vant';
import * as _ from 'lodash';
import {mapActions, mapState} from 'vuex';

Vue.use(Popup);
Vue.use(Row).use(Col);

Vue.use(Loading);
Vue.use(List);

Vue.use(NoticeBar);
Vue.use(Button);
// import qs from 'querystring'
    Vue.use(Dialog);
    Vue.use(Toast);
    Vue.use(Icon);

    const jsonp = require('jsonp');

    export default {
        name: "taxi.vue",
        data() {
            return {
                // 地图位置刷新定时器ID
                intervalId: -1,
                refreshInterval: 20000, // 刷新车辆位置的时间间隔，毫秒

                startPoint: [],
                cpoint: [],//中心点
                map: null,
                center: {lng: 0, lat: 0},
                centerMarker: undefined,
                zoom: 11,
                emptyCars: [],
                circleSearchRadius: 1000,
                // debugCenter: [108.2832490660, 22.8607606900],
                // debugCenter: [108.2718940000, 22.8555930000],
                // debugCenter: [108.2718018096, 22.8550703654],
                debugCenter: [108.2678804200, 22.8578879500],
                //周围检索数据
                poiData: [],
                logmsg: '',
                showNoticeBar: false,
                noticeBarContent: '',

                showDriverInfo: false,
                callDriver: {
                    drivers: []
                }
            }
        },
        mounted() {
            // this.ready();
            // this.nearbyTaxi();
            this.initMap();
            // this.initTimer();
        },
        computed: {
            ...mapState({
                    cars: 'cars',
                }
            ),
        },
        watch: {
            'cpoint': {
                handler(newVal, val) {
                    let _this = this;
                    var placeSearch = new AMap.PlaceSearch({
                        // city 指定搜索所在城市，支持传入格式有：城市名、citycode和adcode
                        city: '南宁'
                    })

                    // this.geocoder(newVal);
                    // placeSearch.searchNearBy('大厦', _this.cpoint, 200, function(status, result) {
                    //     if(status == 'complete'){
                    //         _this.poiData = [];
                    //         result.poiList.pois.forEach(item=>{
                    //             let obj = {
                    //                 title:item.name,
                    //                 address:item.address,
                    //                 lng:item.location.getLng(),
                    //                 lat:item.location.getLng(),
                    //             }
                    //             console.log(obj);
                    //             _this.searchData.push(obj);
                    //         })
                    //
                    //
                    //     }
                    // });
                },
                deep: true,
            },
            'cars': {
                handler(newVal, val) {

                    // console.log('this.cars : ', this.cars);

                    this.showCarMarkers(this.startPoint[0], this.startPoint[1], newVal);
                    this.showStartMarkerGps(this.startPoint);
                }
            }
        },
        methods: {

            ...mapActions({
                loadCarsInCircle: 'loadCarsInCircle',
            }),
            initTimer() {
                this.intervalId = setInterval(this.onInterval, this.refreshInterval);
            },
            onClickTakeThisCar(car) {

                let _this = this;

                this.callDriver.brandNumber = car.brandNumber;
                this.callDriver.drivers = [];

                this.showDriverInfo = true;

                axios({
                    url: '/api/realDrivers',
                    baseURL: this.$store.state.cheApiUrl,
                    params: {
                        plateNumber: car.brandNumber
                    }
                }).then(resp => {
                    console.log(resp);
                    if (resp.data.status && resp.data.status === "0") {
                        _this.callDriver.drivers = resp.data.data;
                    } else {
                        _this.callDriver.drivers = [];
                    }
                }).catch(reason => {
                    console.error('加载车辆司机信息失败', reason);
                });
            },
            onClickCallDriverButton(driver) {

            },
            onInterval() {
                console.log('onInterval');

                var d = new Date();
                var t = d.toLocaleTimeString();
                this.reloadCarsInStartPointCircle();
            },
            onRefreshCarLocation() {
                this.reloadCarsInStartPointCircle();
            },
            reloadCarsInStartPointCircle() {
                if (_.isArray(this.startPoint) && this.startPoint.length >= 2) {
                    if (_.isNumber(this.startPoint[0]) &&
                        _.isNumber(this.startPoint[1])) {
                        this.loadCarsInCircle({
                            lng: this.startPoint[0],
                            lat: this.startPoint[1],
                            distance: this.circleSearchRadius
                        }).then(data => {

                        }).finally(() => {

                        });
                    }
                }
            },
            initMap() {
                let _this = this;

                // 存放所有车辆marker数据
                this.allCarMarkers = new Map();

                this.map = new AMap.Map("mymap", {
                    resizeEnable: true,
                    // center: [116.397428, 39.90923],
                    center: [108.2832490660, 22.8607606900],
                    zoom: this.zoom,
                });

                this.map.on('moveend', function () {
                    let location = _this.map.getCenter();
                    // _this.cpoint = [location.getLng(), location.getLat()];
                    console.log('地图移动', location);
                });

                this.getLocationData();

                // //地图类型切换插件。用户通过该插件进行地图切换。
                // this.map.plugin(["AMap.MapType"], function () {
                //     //地图类型切换
                //     var type = new AMap.MapType({
                //         defaultType: 0,
                //     });
                //     _this.map.addControl(type);
                // });

                this.map.plugin(["AMap.ToolBar"], function () {
                    //加载工具条
                    var tool = new AMap.ToolBar({locate: true, position: 'RT'});
                    _this.map.addControl(tool);
                });
            },
            //地理解析
            geocoder(lnglat) {
                let _this = this;
                let Geocoder = new AMap.Geocoder({extensions: 'all'});
                Geocoder.getAddress(lnglat, function (status, result) {

                    if (status === 'complete') {
                        console.log('逆地理解析成功', result);

                        let formattedAddress = result.regeocode.formattedAddress.replace('广西壮族自治区南宁市', '');
                        // _this.searchWord = formattedAddress;

                        if (_this.direction === 'start') {
                            _this.startData = {
                                address: formattedAddress,
                                lng: _this.cpoint[0],
                                lat: _this.cpoint[1],
                            }
                        } else if (_this.direction === 'end') {
                            _this.endData = {
                                address: formattedAddress,
                                lng: _this.cpoint[0],
                                lat: _this.cpoint[1],
                            }
                        }

                        _this.searchData = [];
                        result.regeocode.pois.forEach(item => {
                            let obj = {
                                title: item.name,
                                address: item.address,
                                lng: item.location.lng,
                                lat: item.location.lat,
                            }
                            _this.searchData.push(obj);
                        })

                    } else if (status === 'error') {
                        console.log('逆地理解析失败，' + result);
                    }
                })
            },

            convertGpsToAMapCoordinateLocally(coordinates, onSuccess, onFail) {
                let newCoordinates = coordinates.map(coord => {
                    return gcoord.transform(
                        coord,    // 经纬度坐标
                        gcoord.WGS84,               // 当前坐标系
                        gcoord.GCJ02                 // 目标坐标系(GCJ02是高德的）
                    );
                });
                if (_.isFunction(onSuccess)) {
                    console.log(newCoordinates);
                    onSuccess(newCoordinates);
                }
                if (_.isFunction(onFail)) {
                    console.log(newCoordinates);
                    onFail(newCoordinates);
                }
            },

            /**
             *
             * @param lineArr [[lng,lat],[lng,lat]]
             * @param onSuccess
             */
            convertGpsToAMapCoordinate(lineArr, onSuccess, onFail) {

                function walker(resultArray, inputArray, currIndex, endIndex, gps, onSuccess, onEror) {
                    // console.log('------');
                    // console.log('转换坐标', '坐标数量', inputArray.length, '个，从第', currIndex,
                    //     '个转换到第', endIndex, '个(不包含)');

                    // if (lineArr.length - currIndex > 40) {
                    // endIndex = currIndex + 40; // not include endIndex element
                    var tempInputArray = lineArr.slice(currIndex, endIndex);
                    AMap.convertFrom(tempInputArray, gps, function (status, result) {
                        if (result.info === 'ok') {
                            result.locations.forEach(function (loc) {
                                resultArray.push(loc);
                            });

                            // console.log('√amap坐标系转换成功', 'endIndex', endIndex, '转换了',
                            //     result.locations.length, '个坐标')

                            if (endIndex >= inputArray.length) {
                                // console.log('结束转换：', currIndex, endIndex);

                                if (_.isFunction(onSuccess)) {
                                    onSuccess(resultArray);
                                }
                            } else {

                                walker(resultArray, inputArray, endIndex, endIndex + 40 >= inputArray.length
                                    ? endIndex + 40 : inputArray.length, gps, onSuccess, onEror);
                            }
                        } else {
                            if (_.isFunction(onEror)) {
                                onEror('坐标转换失败', result);
                            }
                        }
                    });
                }

                var resultLL = [];
                walker(resultLL, lineArr, 0, lineArr.length >= 40 ? 40 : lineArr.length, 'gps', function (result) {

                    if (_.isFunction(onSuccess)) {
                        console.log(result);
                        onSuccess(result);
                    }
                }, function (error, result) {
                    if (_.isFunction(onFail)) {
                        console.log(error)
                        onFail(error);
                    }
                });
            },

            onLoadMore() {

            },
            showCarMarkers(lng, lat, cars) {

                console.log('showCarMarkers ', lng, lat, cars);
                const _this = this;

                // _this.map.add(marker);

                function addCarMarker(car) {
                    // console.log('显示车辆', car);
                    var icon = new AMap.Icon({
                        size: new AMap.Size(30, 62),    // 图标尺寸
                        image: 'assets/img/map_car.png',  // Icon的图像
                        imageOffset: new AMap.Pixel(0, 0),  // 图像相对展示区域的偏移量，适于雪碧图等
                        imageSize: new AMap.Size(15, 31)   // 根据所设置的大小拉伸或压缩图片
                    });
                    //console.log(car);
                    // https://lbs.amap.com/api/javascript-api/reference/overlay#marker
                    var marker = new AMap.Marker({
                        // position: new AMap.LngLat(car.x, car.y),
                        position: [car.alng, car.alat],
                        offset: new AMap.Pixel(0, 0),
                        // icon: 'assets/img/map_car.png',    // 图标尺寸
                        icon: icon,    // 图标尺寸
                        zoom: _this.zoom,
                        angle: parseInt(car.direct) % 360,
                    });
                    marker.setIcon(icon);
                    _this.map.add(marker);
                    // marker.setMap(_this.map);

                    _this.allCarMarkers.set(car.oid, marker);
                }

                var coordinates = cars.map(function (car) {
                    return [car.x, car.y];
                });

                // gps 坐标转为 高德地图坐标
                this.convertGpsToAMapCoordinateLocally(coordinates, (result) => {
                    // console.log('convertGpsToAMapCoordinateLocally result', result);

                    result.forEach((item, index, arr) => {
                        // alng 是 amap lag
                        // console.log('修改车辆位置', item);
                        cars[index].alng = item[0];
                        cars[index].alat = item[1];
                    });

                    if (_.isMap(_this.allCarMarkers)) {
                        let allRemoved = [];
                        let allUpdated = new Map();
                        for (let [key, value] of _this.allCarMarkers) {
                            // console.log(key + ' = ' + value);

                            let index = cars.findIndex(i => i.oid === key);
                            if (index === -1) {
                                allRemoved.push(value);
                            } else {
                                allUpdated.set(key, cars[index]);
                            }
                        }

                        let allAdded = [];
                        cars.forEach(currCar => {
                            let foundCar = _this.allCarMarkers.get(currCar.oid);
                            if (foundCar === undefined) {
                                allAdded.push(currCar);
                            }
                        });

                        // console.log('allRemoved', allRemoved);
                        // console.log('allAdded', allAdded);
                        // console.log('allUpdated', allUpdated);
                    }

                    cars.forEach(car => {
                        if (car.alng === undefined || car.alat === undefined) {
                            console.warn('不显示车辆', car.brandNumber);
                            return;
                        }
                        addCarMarker(car);
                    });
                })
            },
            showNotification(msg) {
                this.noticeBarContent = msg;
                this.showNoticeBar = true;
            },

            showCircleMarker(pos) {
                /**
                 * 圆圈
                 */
                var circle = new AMap.Circle({
                    center: pos,
                    radius: this.circleSearchRadius, //半径
                    borderWeight: 1,
                    strokeColor: "#45a0ff",
                    strokeWeight: 2,
                    strokeOpacity: 0.2,
                    fillOpacity: 0.2,
                    strokeStyle: 'solid',
                    strokeDasharray: [10, 10],
                    fillColor: '#FED840',
                    zIndex: 1,
                });
                circle.setMap(this.map);
                // 缩放地图到合适的视野级别
                this.map.setFitView([circle]);
            },

            showStartMarkerGps(pos) {
                console.log('dd', pos);
                const _this = this;
                //
                // 中心点
                //
                var icon = new AMap.Icon({
                  size: new AMap.Size(222, 257),    // 图标尺寸
                  image: require('@/assets/img/start_marker.png'),  // Icon的图像
                  imageOffset: new AMap.Pixel(0, 0),  // 图像相对展示区域的偏移量，适于雪碧图等
                  imageSize: new AMap.Size(222 / 5, 257 / 5)   // 根据所设置的大小拉伸或压缩图片
                });
                var marker = new AMap.Marker({
                    size: new AMap.Size(20, 25),    // 图标尺寸
                    position: pos,

                    offset: new AMap.Pixel(-20, -50),
                    anchor: 'bottom-left', // 设置锚点方位
                    // icon: 'assets/img/start_marker.png', // 添加 Icon 实例
                    icon: icon,
                    title: '上车点',
                    zIndex: 50,
                    // anchor: 'center', // 设置锚点方位
                    zoom: _this.zoom
                });
                _this.map.add(marker);
                // _this.map.setFitView([marker]);
                // marker.setMap(_this.map);


            },
            /*
             * WGS-08
             * @param lng
             * @param lat
             */
            showCarsInCircle(lng, lat) {
                console.log('showCarsInCircle', lng, lat);

                const _this = this;

                // function addMarker(position) {
                //
                //     var icon = new AMap.Icon({
                //         size: new AMap.Size(30, 62),    // 图标尺寸
                //         image: 'assets/img/start_marker.png',  // Icon的图像
                //         imageOffset: new AMap.Pixel(0, 0),  // 图像相对展示区域的偏移量，适于雪碧图等
                //         imageSize: new AMap.Size(15, 31)   // 根据所设置的大小拉伸或压缩图片
                //     });
                //     //console.log(car);
                //     var centerMaker  = new AMap.Marker({
                //         // position: new AMap.LngLat(car.x, car.y),
                //         position: position,
                //         offset: new AMap.Pixel(0, 0),
                //
                //         icon: icon,    // 图标尺寸
                //         zoom: 13
                //     });
                //
                //     if (_this.centerMarker) {
                //         _this.map.remove(_this.centerMarker);
                //     }
                //
                //     _this.centerMarker = centerMaker;
                //     _this.map.add(_this.centerMarker);
                // }
                // var result = gcoord.transform(
                //     [ll.lng, ll.lat],    // 经纬度坐标
                //     gcoord.WGS84,               // 当前坐标系
                //     gcoord.GCJ02                 // 目标坐标系
                // );

                var centerPoint = gcoord.transform(
                    [lng, lat],    // 经纬度坐标
                    gcoord.WGS84,               // 当前坐标系
                    gcoord.GCJ02                 // 目标坐标系
                );
                _this.startPoint = centerPoint;
                _this.showCircleMarker(centerPoint);
                console.log('中心点', _this.startPoint);

                // AMap.convertFrom([lng, lat], 'gps', function (status, result) {
                //     if (result.info === 'ok') {
                //         var centerPos = result.locations[0];
                //
                //         _this.startPoint = centerPos;
                //         _this.showCircleMarker(centerPos);
                //
                //         console.log('中心点', _this.startPoint);
                //         // addMarker(pos);
                //     }
                // });

                this.loadCarsInCircle({
                    lng: lng,
                    lat: lat,
                    distance: this.circleSearchRadius
                }).then(data => {

                }).finally(() => {

                });
            },
            getLocationData() {

                const _this = this;

                // //定位
                // Toast.loading({
                //     mask: true,
                //     message: '加载当前位置信息...',
                //     duration: 0,
                // });

                function onPositionError(error) {
                    let msg = '';
                    switch (error.code) {
                        case error.PERMISSION_DENIED:
                            msg = "您未授权浏览器获取地理位置。";
                            break;
                        case error.POSITION_UNAVAILABLE:
                            msg = "GPS位置信息不可用。";
                            break;
                        case error.TIMEOUT:
                            msg = "请求GPS定位超时。";
                            break;
                        case error.UNKNOWN_ERROR:
                            msg = "未知错误。";
                            break;
                    }
                    _this.showNotification(msg);
                }


                function onPositionReady(position) {
                    let msg = "纬度: " + position.coords.latitude + " 经度: " + position.coords.longitude;
                    // this.showNotification(msg);
                    this.showCarsInCircle(position.coords.longitude, position.coords.latitude);
                }

                // if (navigator.geolocation) {
                //     // https://developer.mozilla.org/zh-CN/docs/Web/API/PositionOptions
                //     let option = {
                //         enableHighAccuracy: false,
                //         timeout: 10000,
                //         maximumAge: 0
                //     };
                //
                //     navigator.geolocation.getCurrentPosition(onPositionReady, onPositionError, option);
                // } else {
                //     this.showNotification('浏览器不支持获取地理位置');
                // }

                this.showCarsInCircle(this.debugCenter[0], this.debugCenter[1]);
            },
        },
    }
</script>


<style scoped>

    .info-wrapper {
        margin-left: 14px;
        margin-right: 14px;
        margin-bottom: 14px;

        right: 0;
        left: 0;
        bottom: 0;

        border-radius: 6px;
        display: block;
        position: absolute;
        width: auto;
        max-height: 50%;
        min-height: 50px;
        overflow: scroll;
        box-shadow: 0 3px 8px rgba(0, 0, 0, 0.16);
    }

    .car-list-header {
        position: sticky;
        top: 0;
        width: 100%;
        height: 52px;
        line-height: 52px;
        background: rgba(51, 51, 51, 1);
        opacity: 1;
        vertical-align: middle;
        border-radius: 6px 6px 0px 0px;
    }

    .car-list-header .icon {
        align-items: center;
        position: absolute;
        right: 16px;
    }

    .car-header-text {
        font-size: 16px;
        margin: 0 auto;
        height: 22px;
        alignment: center;
        vertical-align: middle;
        width: 100%;
        text-align: center;
        align-content: center;
        font-family: PingFang SC;
        font-weight: bold;
        /*line-height: 52px;*/
        /*alignment: center;*/
        color: rgba(255, 255, 255, 1);
        opacity: 1;
    }

    .car-list-container {
        /*margin-top: 52px;*/
        margin-top: 0;
        overflow: scroll;
        background-color: rgba(241, 241, 241, 0.81);
        /*background:rgba(255,255,255,1);*/
        margin-bottom: 40px
    }

    .car-list-item {

    }

    .car-list-item .title {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 20px;
        color: rgba(34, 34, 34, 1);
        opacity: 1;
    }

    .car-content-wrapper {
        display: inline-block;
        margin-left: 0;
        margin-right: 0;
    }


    .action-button-wrapper {
        display: inline-block;
        margin-left: 0;
        margin-right: 0;

        position: absolute;
        right: 0;

        line-height: 44px;

        top: 0;
        bottom: 0;
    }

    .car-action-button {
        display: inline;
        padding: 10px 14px;
        color: white;
        color: rgba(254, 216, 64, 1);
        line-height: 34px;
        height: 34px;
        font-size: 12px;
        background: rgba(51, 51, 51, 1);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
        opacity: 1;
        border-radius: 33px;
    }

    .car-icon-wrapper {
        padding: 0;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 0;
        margin-right: 0;
        line-height: 44px;
        vertical-align: middle;
        display: inline-block;
    }

    .car-list-icon {
        width: 54px;
        height: 21px;
        display: inline;
        line-height: 34px;
        background: rgba(255, 255, 255, 0.3);
        /*box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.16);*/
        opacity: 1;
        border-radius: 18px;
    }

    .car-content-wrapper .distance-title {

        font-size: 12px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #999999;
        opacity: 1;
    }

    .car-content-wrapper .distance {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: bold;
        margin-left: 6px;
        color: rgb(254, 172, 1);
        opacity: 1;
    }

    .car-content-wrapper .sub-title {

        font-family: PingFang SC;
        font-weight: bold;
        color: #999999;
        opacity: 1;
    }

    .car-content-wrapper .speed {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: bold;
        margin-left: 6px;
        color: #999999;
        opacity: 1;
    }

    .call-driver-header {
        height: 52px;
        background: rgba(51, 51, 51, 1);
        opacity: 1;
        vertical-align: middle;
        /*border-radius: 6px 6px 0 0;*/
        line-height: 52px;
    }

    .call-driver-header .title {

        height: 22px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;

        color: rgba(255, 255, 255, 1);
        opacity: 1;
    }

    .show-driver-info {
        /*margin-left: 15px;*/
        /*margin-right: 15px;*/
        background-color: white;
    }

    .driver-list-item {
        min-height: 80px;
    }

    .call-driver-content .driver-list-item .title {
        font-size: 16px;
        font-family: PingFang SC;

        font-weight: bold;
        margin-left: 100px;
        color: rgba(34, 34, 34, 1);
        opacity: 1;
    }

    .call-driver-content .driver-list-item .tag {

        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        padding: 8px 5px;
        color: rgba(255, 255, 255, 1);
        opacity: 1;
        background: rgba(89, 184, 255, 1);
        opacity: 1;
        border-radius: 12px;
    }

    .call-driver-content .driver-info {
        line-height: 52px;
        vertical-align: center;
    }

    .call-driver-content .call-button {
        position: absolute;
        /*width: 50px;*/
        line-height: 50px;
        top: auto;
        bottom: auto;
        right: 16px;

        /*background:rgba(241,241,241,1);*/
        /*border-radius:50%;*/
        /*opacity:1;*/
        /*align-content: center;*/
        /*text-align: center;*/
        /*alignment: center;*/
    }

    .show-driver-info .tip {
        margin-top: 20px;
        font-size: 12px;
        font-family: PingFang SC;
        color: rgb(214, 34, 1);
        opacity: 1;
    }

    .call-button:first-child {
        min-width: 60px;
        min-height: 40px;
    }


    .contents {
        width: 100%;
        height: 100%;
        position: fixed;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        margin-top: auto;
        margin-bottom: auto;
        display: flex;
        flex-direction: column;
    }


    #mymap {
        width: 100%;
        height: 100%;
    }


</style>
