<template>
  <div class="root">
    <van-nav-bar :title="$route.meta.navTitle" fixed placeholder left-arrow @click-left="$router.back()"></van-nav-bar>
    <div class="content-wrapper">


      <div class="lost-info-card">
        <lost-simple-info :order="order"></lost-simple-info>
      </div>

      <div class="lost-info-card" style="margin-top: 20px">
        <div class="section-title">
          <div style="display: flex;align-items: center">
            <img src="../../img/mao_solid@2x.png" style="width:21px" alt="">
            <span class="section-title-value">升级后权益</span>
          </div>
          <div class="section-title-see" @click="returnVip">
            <span>了解VIP服务详情</span>
            <van-icon name="arrow" color="#4C70E4" />
          </div>
        </div>
        <img src="../../img/order_notice@2x.png" style="width:100%" alt="">
      </div>
      <!--<div class="section-title" style="margin-top: 16px;">升级后特权：</div>-->
      <!--<img src="/assets/lost/lost_vip_desc_banner.svg" width="100%">-->

      <div class="lost-info-card" style="margin-top: 20px">
        <div class="section-title">
          <div style="display: flex;align-items: center">
            <img src="../../img/notice@2x.png" style="width:18px" alt="">
            <span class="section-title-value">免责声明</span>
          </div>
          <!--<span class="section-title-see" @click="returnVip">了解VIP服务详情 ></span>-->
        </div>
      </div>

      <div class="agreement-wrapper" style="margin-top: -10px">

        <!--<div class="title">《免责声明》</div>-->

        <ul class="disclaimer">
          <li><span style="color:#E65041">付费升级VIP专属服务仅限本次失物查找订单。</span></li>
          <li>您当次购买的VIP专属服务仅为广西蛋卷科技有限公司为您提供优先的数据查询及人工服务等，不代表一定追回失物。</li>
          <li>如无法追回失物，我司将收取{{
            parseFloat((lostServicePrice ? lostServicePrice.price : '--') -
              (lostServicePrice ? lostServicePrice.paybackPrice : '--')).toFixed(2)
          }}元信息及人工服务费，剩余{{ lostServicePrice ? lostServicePrice.paybackPrice : '--' }}元将会在三个工作日内原路退回；
          </li>
          <li>乘客拿回失物即代表当次服务完成，我司不确保追回失物的完整性。</li>
          <li>我司为独立第三方数据平台，司机的失信行为及其它不当行为与我司无关，但我司会及时向所属出租车公司反馈。</li>
          <li>在相关法律法规允许的情况下，解释权归广西蛋卷科技有限公司所有。</li>
        </ul>
      </div>
    </div>

    <div class="bottom-bar-wrapper">
      <div class="check-agreement">
        <van-checkbox class="checkbox" v-model="checked"><span class="agreement-text1">我已阅读并同意</span><a
            class="agreement-span" @click.stop="onClickBrowserAgreement">《付费失物查找协议》</a>
        </van-checkbox>
      </div>
      <div class="action-button-wrapper">
        <van-row>
          <van-col span="14" class="price-wrapper">
            <span>
              <span class="unit">￥</span></span>
            <span class="price">{{ lostServicePrice ? lostServicePrice.price : '--' }}</span>
            <span class="unit-name">/次</span>
          </van-col>
          <van-col span="10">
            <div :class="['upgrade-button', checked ? 'active' : 'inactive']" @click="onClickUpgrade">
              立即升级
            </div>
          </van-col>
        </van-row>
      </div>
    </div>

    <van-popup v-model="showUpgradeSuccessDialog" class="upgrade-success-dialog" :overlay="true"
      style="display: flex;justify-content: center">
      <div style="position: relative;display: flex;flex-direction: column;align-items: center;width:90%">

        <img src="../../img/success_vip@2x.png" class="bg-img">
        <p class="check-detail-text" @click="onClickOrderDetail">查看订单 ></p>
        <van-icon name="cross" class="btn-close" size="20px" @click="onClickOrderDetail" />
        <!-- <van-icon name="cross" class="btn-close" size="20px" @click="onClickSuccessClose"/> -->
      </div>
    </van-popup>
  </div>
</template>

<script>

import moment from 'moment';
import qs from 'qs';
import axios from 'axios'
import Vue from 'vue'
import { Cell, CellGroup } from 'vant';
import { Field } from 'vant';
import { Picker } from 'vant';
import { ActionSheet } from 'vant';
import { Popup } from 'vant';
import { Toast } from 'vant';
import { DatetimePicker } from 'vant';
import { NoticeBar } from 'vant';
import { Icon } from 'vant';
import { Tab, Tabs } from 'vant';
import { List } from 'vant';
import { Tag } from 'vant';
import { mapState, mapActions } from 'vuex';
import ua from '../../utils/agent';
import * as _ from 'lodash';


import wx from 'weixin-js-sdk';
import { Dialog } from 'vant';

import { Checkbox, CheckboxGroup } from 'vant';
import LostSimpleInfo from "./components/LostSimpleInfo";

Vue.use(Checkbox).use(CheckboxGroup);
Vue.use(Dialog);

Vue.use(Tag);

Vue.use(Icon);
Vue.use(Toast);
Vue.use(NoticeBar);
Vue.use(List);
Vue.use(Tab).use(Tabs);
Vue.use(DatetimePicker);
Vue.use(Popup);
Vue.use(Toast);
Vue.use(ActionSheet);
Vue.use(Picker);
Vue.use(Field);
Vue.use(Cell).use(CellGroup);

export default {
  name: "lostupgrade",
  components: { LostSimpleInfo },
  props: ['lostid'],
  data() {
    return {
      order: {},
      checked: false,

      showUpgradeSuccessDialog: false,
    }
  },
  mounted() {
    // 加载失物查找的服务价格
    this.loadLostServicePrice().then(res => {

    });
  },
  watch: {
    lostid: {
      handler(val, oldVal) {
        console.log('lostid changed', this.lostid, val);

        var order = this.lostOrders.find(item => item.id == val);
        if (order) {
          this.order = order;
        } else {
          this.loadLostOrders({}).then(res => {
          });
        }
      },
      immediate: true
    },
    lostOrders(val) {
      this.order = this.extractLostOrder(val);
    }
  },
  computed: {
    ...mapState({
      openid: 'openid',
      lostOrders: state => state.lostOrders,
      lostServicePrice: state => state.lostServicePrice,
    })
  },
  methods: {
    ...mapActions({
      prepayLostOrder: 'prepayLostOrder',
      loadLostOrders: 'loadLostOrders',
      loadLostServicePrice: 'loadLostServicePrice'
    }),
    returnVip() {
      this.$router.push('/aboutVip')
    },
    extractLostOrder(orders) {
      if (!orders) {
        return null;
      }
      return orders.find(item => item.id === this.lostid);
    },
    onClickOrderDetail() {
      this.$store.dispatch('popRouterPathOnly');
      this.$router.replace(`/lostDetail/${this.lostid}`)
    },
    onClickSuccessClose() {
      this.$store.dispatch('popRouterPath');
    },
    showSuccessDialog() {

      this.$store.dispatch('popRouterPathOnly');
      this.$router.replace(`/lostDetail/${this.lostid}`)
    },
    onUpgradeSuccess() {
      this.showUpgradeSuccessDialog = true;
      this.$store.dispatch('loadLostOrders', {}).then(res => {
      });
    },
    onClickBrowserAgreement(event) {
      if (event) event.preventDefault()
      this.$router.push("/lostAgreement");
    },
    onClickUpgrade: _.debounce(function () {
      console.log('----防抖处理---');
      this.doClickUpgrade()
    }, 300),
    doClickUpgrade() {

      if (ua.macWechat || ua.windowsWechat) {

        // TODO 展示支付二维码

        let platform = ua.macWechat ? "Mac" : (ua.windowsWechat ? 'Windows' : '当前');
        Toast.fail(platform + ' 版微信不支持支付功能，请在手机微信公众号上填单支付。');
        return;
      }

      if (!this.checked) {
        Toast.success('请先同意用户协议');
        return;
      }

      if (_.isEmpty(this.lostid)) {
        Dialog.alert({ message: '无法确定失物查找订单号' });
        return;
      }

      const _this = this;
      Toast.loading({
        mask: true,
        message: '请求中...'
      });
      this.prepayLostOrder(this.lostid)
        .then(res => {
          console.log('创建预支付订单成功', res);
          // 支付确认对话框
          Toast.loading({
            mask: true,
            message: '正在拉起支付.'
          });
          _this.onWeChatReadyToPay(res);
          // _this.wechatPay()
        })
        .catch(reason => {
          Dialog.alert({
            title: '请求支付失败',
            message: reason
          }).then(() => {
            // on close
          });

          Toast.clear();
        })
        .finally(() => {
          // Toast.clear(); // 这里先不去掉loading
        });


    },
    onWeChatReadyToPay(params) {
      const _this = this;
      Toast.loading({
        mask: true,
        message: '正在拉起支付..'
      });
      // https://mp.weixin.qq.com/wiki?t=resource/res_main&id=mp1421141115
      // 所有需要使用JS-SDK的页面必须先注入配置信息，否则将无法调用（同一个url仅需调用一次，对于变化url的SPA的
      // web app可在每次url变化时进行调用,目前Android微信客户端不支持pushState的H5新特性，所以使用pushState来
      // 实现web app的页面会导致签名失败，此问题会在Android6.2中修复）。
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，
        // 可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: params.appId, // 必填，公众号的唯一标识
        timestamp: params.timeStamp, // 必填，生成签名的时间戳
        nonceStr: params.nonceStr, // 必填，生成签名的随机串
        signature: params.paySign,// 必填，签名
        jsApiList: ['chooseWXPay'] // 必填，需要使用的JS接口列表 // https://mp.weixin.qq.com/wiki?t=resource/res_main&id=mp1421141115
      });

      Toast.loading({
        mask: true,
        message: '正在拉起支付...'
      });

      wx.ready(function () {

        console.log("微信wx.ready()被调用");

        // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的
        // 异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。
        // 对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
        wx.chooseWXPay({
          appId: params.appId,
          timestamp: params.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          nonceStr: params.nonceStr, // 支付签名随机串，不长于 32 位
          package: params.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          signType: params.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
          paySign: params.paySign, // 支付签名
          success: function (res) {
            console.log('支付成功', res);

            Toast.clear();

            _this.onUpgradeSuccess();

            // _this.showSuccessDialog();

            // 支付成功后的回调函数
            //window.location.href = 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzU4MzIzMzM3OA==&scene=126#wechat_redirect'// window.location.href = '/wx/lucky/index'
          },
          fail: function (res) {
            console.log('支付失败', res);
            Toast.clear();

            Dialog.alert({
              title: '支付失败',
              message: res.errMsg || '支付失败，可能是当前客户端不支持'
            }).then(() => {
              // on close
            });

            // use($('.forbid'));
            // alert('支付失败，请重新支付');
          },
          cancel: function (res) {
            Toast.clear();

            Dialog.alert({
              title: '支付取消',
              message: '您已取消微信支付，当前订单可以再次支付'
            }).then(() => {
              // on close
            });
            console.log('支付取消', res);
            // use($('.forbid'));
          }
        });
      });
      wx.error(function (res) {
        // config信息验证失败会执行error函数，如签名过期导致验证失败，
        // 具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        console.log('wx.error', res);
        Toast.clear();
        alert('支付失败（Config失败），请重新支付' + res);
      });
    },
  }
}
</script>


<style scoped>
.root {
  text-align: left;
}

.banner {
  padding: 1px;
  height: auto;
  margin-top: 15px;
  margin-left: 10px;
  margin-right: 10px;
  /*background-color: black;*/

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  text-align: center;
}

.banner .title1 {

  margin-top: 31px;
  text-align: center;
  font-size: 20px;
  line-height: 20px;
  font-family: PingFang SC;
  font-weight: bold;

  color: rgba(255, 214, 147, 1);
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
  opacity: 1;
}

.banner .title2 {
  text-align: center;
  height: 20px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 20px;
  margin-top: 14px;
  color: rgba(255, 214, 147, 1);
  opacity: 1;
}

.banner .divider {
  width: 26px;
  height: 0px;
  margin-top: 14px;
  border: 0px solid rgba(255, 214, 147, 1);
  opacity: 1;
  display: inline;
}

.banner .button-wrapper {
  ;
  text-align: center;
}

.banner .button {
  width: 146px;
  height: 30px;
  border: 1px solid rgba(255, 214, 147, 1);
  opacity: 1;
  border-radius: 33px;
  margin-left: auto;
  margin-right: auto;

  margin-top: 10px;
  font-size: 10px;
  font-family: PingFang SC;
  font-weight: 300;
  vertical-align: center;
  color: rgba(255, 214, 147, 1);
  opacity: 1;
  line-height: 30px;
}

.agreement-wrapper ul {}

.agreement-wrapper ul li {
  margin-left: 16px;
  list-style-type: disc;
  list-style-position: outside;
}

.agreement-wrapper {
  padding: 0 0px;
  margin-left: 0;
  margin-right: 0;
  border: 0px solid rgba(49, 49, 64, 1);
  opacity: 1;
  border-radius: 4px;
  padding-bottom: 20px;
  /*background: #ffffff;*/

  /*margin-top: 20px;*/
  margin-bottom: 40px;
}

.agreement-wrapper .title {
  height: 53px;
  background: rgba(66, 66, 78, 1);
  opacity: 1;
  border-radius: 4px 4px 0px 0px;

  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 53px;
  color: rgba(255, 255, 255, 1);

  padding-left: 20px;
}

.bottom-bar-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;

  background: rgba(255, 255, 255, 1);
  opacity: 1;
}

.bottom-bar-wrapper .agreement-text1 {
  height: 20px;
  font-size: 14px;
  font-family: PingFang SC;
  /* font-weight: bold; */
  line-height: 20px;
  color: #222222;
  opacity: 1;
}

.bottom-bar-wrapper .check-agreement {
  display: flex;
  align-items: center;
  height: 60px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  vertical-align: center;
  line-height: 60px;
  margin: 0;
}

.bottom-bar-wrapper .checkbox {
  margin-left: 14px;
  margin-right: 3px;
}

.bottom-bar-wrapper .agreement-span {
  height: 20px;
  font-size: 14px;
  font-family: PingFang SC;
  /* font-weight: bold; */
  line-height: 20px;
  color: #4C70E4;
  opacity: 1;
}

.lost-info-card {
  padding: 1px;
  /*background: rgba(255, 255, 255, 1);*/
  opacity: 1;
  border-radius: 4px;
  /*display: flex;*/
  /*flex-direction: column;*/
  /*align-items: center;*/
}

.section-title {
  margin-bottom: 10px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 17px;
  color: rgba(153, 153, 153, 1);
  opacity: 1;
}

.content-wrapper {
  margin-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 120px;
}

.price-wrapper {
  height: 60px;
  background: rgba(49, 49, 64, 1);
  opacity: 1;
  text-align: center;
  line-height: 60px;
  vertical-align: 60px;
}

.price-wrapper .unit {
  width: 16px;
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 22px;
  color: rgba(255, 214, 147, 1);
  opacity: 1;
}

.price-wrapper .price {
  height: 40px;
  font-size: 29px;
  font-family: PingFang SC;
  font-weight: 800;
  line-height: 40px;
  color: rgba(255, 214, 147, 1);
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
  opacity: 1;
}

.price-wrapper .unit-name {
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 22px;
  color: rgba(255, 214, 147, 1);
  opacity: 1;
}

.action-button-wrapper .upgrade-button :hover {
  opacity: 0.75;
}

.action-button-wrapper .upgrade-button {
  height: 60px;
  background: rgba(255, 71, 71, 1);
  opacity: 1;
  line-height: 60px;
  text-align: center;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
}

.action-button-wrapper .upgrade-button.inactive {
  height: 60px;
  background: rgba(255, 71, 71, 1);
  opacity: 0.65;
  line-height: 60px;
  text-align: center;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
}

.success-dialog-overlay {
  /*background:rgba(0,0,0,0.5);*/
  opacity: 0.5;
}

.upgrade-success-dialog .check-detail-text {
  position: absolute;

  color: white;
  left: 0;
  right: 0;
  bottom: 25px;
  margin: 0;
  padding: 0;
  text-align: center;

  height: 20px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 300;
  line-height: 20px;
  color: rgba(255, 255, 255, 1);
  text-decoration: underline;
  opacity: 1;
}

.upgrade-success-dialog .bg-img {
  margin-left: 16px;
  margin-right: 16px;
  width: 100%;
}

.upgrade-success-dialog .btn-close {
  position: absolute;
  right: 15px;
  top: 15px;
  color: white;
}

.upgrade-success-dialog {
  padding: 10px;
  background-color: transparent;
  width: 100%;
  /*margin: 0 10px;*/
}

.disclaimer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.disclaimer li {
  width: 94%;
  margin-top: 10px;
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 19px;
  color: rgba(112, 112, 117, 1);
}

.section-title {
  display: flex;
  justify-content: space-between;

}

.section-title-value {

  font-size: 16px;
  font-family: PingFang SC;
  /* font-weight: 500; */
  line-height: 22px;
  color: rgba(34, 34, 34, 1);
  opacity: 1;
  margin-left: 10px;
}

.section-title-see {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 20px;
  color: rgba(76, 112, 228, 1);
  opacity: 1;
}
</style>
