import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store/store'
import '@/utils/vconsole.js'
import * as _ from 'lodash';
import callApi from '@/store/callApi';
import SvgIcon from '@/components/SvgIcon' // svg组件
import consts from './utils/consts'
import bizConfig from './config/bizConfig';
import appConfig from "./config/appConfig";
import cache from "@/store/cache";
import auth from "@/store/auth";

Vue.config.productionTip = false;

Vue.mixin({
  data: function () {
    return {
      get consts() {
        return consts;
      },
      get api() {
        return {
          call: callApi
        }
      },
      get appConfig() {
        return appConfig;
      },
      get bizConfig() {
        return bizConfig;
      },
      get loadTestData() {
        return process.env.NODE_ENV === 'development';
        // return false;
      },
      get lo() {
        return _
      },
    }
  }
});

Vue.component('svg-icon', SvgIcon);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');


let appType = cache.getKey('appType');
if (!appType || appType === 'null') {
  store.commit('appType', appType)
}

let token = auth.getToken();
if (!_.isEmpty(token)) {
  store.commit('DRIVER_TOKEN', token);
}
if (!_.isEmpty(cache.getKey('smsToken'))) {
  console.log('main.js==>>', _.isEmpty(cache.getKey('smsToken')))
  store.commit('smsAccessToken', cache.getKey('smsToken'));
}
