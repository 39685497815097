import cache from '@/store/cache'
// TODO naming token key
const DRIVER_TOKEN_KEY = 'taxih5token' + process.env.VUE_APP_DEPLOY_PATH.replace('/', '').replace('/', '');
const OPENID_KEY = 'djcxOpenId';

const auth = {
    getToken() {
        return cache.getKey(DRIVER_TOKEN_KEY)
    },
    setToken(token) {
        cache.setKey(DRIVER_TOKEN_KEY, token)
    },
    getOpenId() {
        return cache.getKey(OPENID_KEY)
    },
    setOpenId(openId) {
        cache.setKey(OPENID_KEY, openId)
    }
};
export default auth;
