<template>
  <div class="root" ref="rootMark">
    <van-nav-bar title="失物列表" style="position: fixed;top: 0;right: 0;left: 0" left-arrow @click-left="$router.go(-1)"/>
    <div class="NavBar flex">
      <div class="item flex" v-for="(item, index) of tabList" :key="index" :class="showActive == index?'active':''"
           @click="checkItem(item, index)">
        {{item.label}}
      </div>
    </div>
    <div class="root-page">
      <div class="find-result-wrapper">
        <div v-if="emptyFindResult && loadedFindResult && validFindResultForm">
          未找到手机号 {{ contactPhone }} 的失物查找结果，订单不能存在或者正在处理，请耐心等待！
        </div>
        <div class="find-result-list" v-if="!emptyFindResult">
          <div class="card" v-for="lost in lostList" :key="lost.lostinfoid">
            <div>
              <div>
                <div class="card-content">
                  <van-row class="card-row">
                    <van-col span="8"><span class="card-item-title">服务类型</span></van-col>
                    <van-col span="16" class="col_value" v-if="lost.serviceType!=0 && lost.isVip">
                      <img class="vip-icon" src="../../img/check_vip@2x.png">
                    </van-col>
                    <van-col span="16" class="col_value"
                             v-if="lost.serviceType!=0 && !lost.isVip && !(lost.serviceType==2 && !lost.isVip) ">
                      <span class="card-item-val">
                        免费
                        <span v-if="bizConfig.enableVip && lost.cityCode != '450200'">
                        （<span style="color:#4C70E4" @click="onClickUpgrade(lost)">升级VIP服务</span>）
                        </span>
                      </span>
                    </van-col>
                    <van-col span="16" class="col_value"
                             v-if="lost.serviceType==0||(lost.serviceType==2 && !lost.isVip)">
                      <span class="card-item-val" style="color:red">
                        未选择
                        <span v-if="!(lost.isHandled)">
                          （<span style="color:#4C70E4" @click="onChoseService(lost)">请选择服务</span>）
                        </span>
                      </span>
                    </van-col>
                  </van-row>
                  <van-row class="card-row">
                    <van-col span="8"><span class="card-item-title">订单编号</span></van-col>
                    <van-col span="16" class="col_value"><span class="card-item-val">{{ lost.id }}</span></van-col>
                  </van-row>
                  <van-row>
                    <van-col span="8"><span class="card-item-title">订单状态</span></van-col>
                    <van-col span="16" class="col_value">
                      <span class="card-item-val">
                        <span :class=" [ 'order-status-text', lost.orderStatusTextClassName] ">{{lost.orderStatusText}}</span>
                      </span>
                    </van-col>
                  </van-row>
                </div>
                <div class="h-divider"></div>
                <div class="card-content">
                  <van-row class="card-row">
                    <van-col span="8"><span class="card-item-title">姓名</span></van-col>
                    <van-col span="16" class="col_value"><span class="card-item-val">{{ lost.owner }}</span>
                    </van-col>
                  </van-row>
                  <van-row class="card-row">
                    <van-col span="8"><span class="card-item-title">物品描述</span></van-col>
                    <van-col span="16" class="col_value"><span class="card-item-val">{{ lost.propertyName }}</span>
                    </van-col>
                  </van-row>
                  <van-row class="card-row" v-if="!lost.invoice">
                    <van-col span="8"><span class="card-item-title">乘车时间</span></van-col>
                    <van-col span="16" class="col_value"><span class="card-item-val">{{ lost.getOnDateStr }}</span>
                    </van-col>
                  </van-row>
                  <van-row class="card-row">
                    <van-col span="8"><span class="card-item-title">上车地点</span></van-col>
                    <van-col span="16" class="col_value"><span class="card-item-val">{{ lost.getOnLocation }}</span>
                    </van-col>
                  </van-row>
                  <van-row class="card-row">
                    <van-col span="8"><span class="card-item-title">下车地点</span></van-col>
                    <van-col span="16" class="col_value"><span class="card-item-val">{{
                        lost.getOffLocation
                      }}</span>
                    </van-col>
                  </van-row>
                </div>
                <div v-if="lost.handleStatus == consts.LOST_HANDLE_STATUS_HANDLED" class="h-divider"></div>
                <div class="card-content"
                     v-if="lost.handleStatus == consts.LOST_HANDLE_STATUS_HANDLED&& lost.handleResult">
                  <div>
                    <span class="card-item-title"></span>
                    <span class="result card-item-title">{{ lost.handleResult }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <van-row style="display: flex;justify-content: center">
                <van-col class="price-wrapper" :span="24">
                  <div :class="[ 'text',(lost.isHandled ? 'unhandled' : 'unhandled')  ]"
                       @click="onClickOrderCard(lost)">{{ lost.isHandled ? '查看详情' : '查看订单进度 >>>' }}
                  </div>
                </van-col>
              </van-row>
            </div>
          </div>
        </div>
        <img @click="returnVip" v-if="bizConfig.enableVip && currentCity.adcode!='450200'" :src="require('@/img/order_banner@2x.png')"
             style="width:90%;margin-top: 60px;margin-bottom: 20px">
      </div>
    </div>
  </div>

</template>

<script>

  import moment from 'moment';
  import qs from 'qs';
  import axios from 'axios'
  import Vue from 'vue'
  import {
    ActionSheet,
    Cell,
    CellGroup,
    DatetimePicker,
    Dialog,
    Field,
    Icon,
    List,
    NavBar,
    NoticeBar,
    Picker,
    Popup,
    Radio,
    RadioGroup,
    Tab,
    Tabs,
    Tag,
    Toast
  } from 'vant';
  import {mapActions, mapState} from 'vuex';
  import ua from '../../utils/agent';
  import * as _ from 'lodash';
  import lostApi from '@/store/lostApi';
  import wx from 'weixin-js-sdk';

  import ClipboardJS from 'clipboard';
  import routeUtil from "@/utils/routeutil";

  Vue.use(NavBar);
  Vue.use(RadioGroup);
  Vue.use(Radio);
  Vue.use(Dialog);
  Vue.use(Tag);
  Vue.use(Icon);
  Vue.use(Toast);
  Vue.use(NoticeBar);
  Vue.use(List);
  Vue.use(Tab).use(Tabs);
  Vue.use(DatetimePicker);
  Vue.use(Popup);
  Vue.use(Toast);
  Vue.use(ActionSheet);
  Vue.use(Picker);
  Vue.use(Field);
  Vue.use(Cell).use(CellGroup);


  export default {
    name: "LostListPage",
    props: ['activeTabs'],
    data() {
      return {
        showActive: 0,
        tabList: [
          {label: '全部', value: 100},
          {label: '已处理', value: 2},
          {label: '处理中', value: 1},
          {label: '未处理', value: 0},
        ],

        form: {},
        contactPhone: '', // 结果查询手机号

        activePage: 'form',
        activeTab: 0,
        activeStep: 0,

        activePageSuccess: false,

        clickedTab: -1,

        submitting: false,
        submittingFind: false,

        losts: [],
        loadingLostOrders: false,
        loadedFindResult: false,

        showLostForm: false,

        showAboutVip: false,

        successRadio: 'noPay',

        orderId: null,

        choseActiveTab: null,

        // lostAllCount: '-',
        lostHandlingCount: '-',

        lostList: [],
        currentCity: {}, //当前城市信息

      }
    },
    mounted() {
      this.getCurrentCity() //获取当前城市信息
      if (this.loadTestData) {
        let loadTestData = true;
        if (loadTestData) {
          this.form.owner = '王家卫';
          this.form.sex = '男';
          this.form.phone = '13400011111';
          this.form.propertyName = '电影剧本';
          this.form.amount = '1';
          this.form.getOnDateMinute = moment().subtract(1, "hours").format("YYYY-MM-DD HH:mm");
          this.form.getOffDateMinute = moment().subtract(1, "minutes").format("YYYY-MM-DD HH:mm");
          this.form.getOnLocation = "国电智能大厦";
          this.form.getOffLocation = "南宁站";
          this.form.line = "大陆";
          this.form.cost = 30;
          this.form.passengerDesc = '1男1女';
          this.form.seat = '后座';
          this.form.propertyPosition = '副驾驶';
          this.form.driverSex = '男';
          this.form.carColor = '绿灰相间';
          this.contactPhone = 13400001111;
        }
      }
      this.initWeChatPay();
      // 加载失物查找服务的价格
      this.loadLostServicePrice();
      var clipboard = new ClipboardJS('.clip-djcx');
      clipboard.on('success', function (e) {
        Toast.success("已复制");
        e.clearSelection();
      });
      clipboard.on('error', function (e) {
        Toast.fail("复制失败");
      });
      var clipboard2 = new ClipboardJS('.clip-djcx2');
      clipboard2.on('success', function (e) {
        Toast.success("已复制");
        e.clearSelection();
      });
      clipboard2.on('error', function (e) {
        Toast.fail("复制失败");
      });
      this.getOrderList();

      this.showActive = this.$route.query.showActive || 0;
    },
    watch: {
      "validFindResultForm": {
        handler(val, oldVal) {
          if (val === false) {
            this.loadedFindResult = false;
          }
        }
      },
      activeTab: {
        handler(val, oldVal) {
          if (val === 0) {
            this.showLostForm = false;
            this.showAboutVip = false;
          }
          this.choseActiveTab = val;
        }
      },
      "choseActiveTab": {
        handler(val, oldVal) {
        }
      },
      'lostOrders': {
        handler(val, oldVal) {
          console.log('LostListPage 失物查找订单列表变化', val);
          if (_.isArray(val)) {
            val.forEach(lost => {
              // 失物查找结果文字解析
              if (_.isEmpty(lost.handleResult)) {
                lost.resultColor = 'red';
              } else {
                if (lost.handleResult.search(/查询不到/)) {
                  lost.resultColor = 'red';
                } else if (lost.b2.search(/现查询到/)) {
                  lost.resultColor = 'green';
                }
              }
              if (lost.handleStatus != null) {
                switch (lost.handleStatus) {
                  case 0:
                    lost.orderStatusText = '未处理';
                    lost.orderStatusTextClassName = 'unhandled';
                    break;
                  case 1:
                    lost.orderStatusText = '处理中';
                    lost.orderStatusTextClassName = 'handling';
                      break;
                    case 2:
                        lost.orderStatusText = '已处理';
                        lost.orderStatusTextClassName = 'handled';
                        break;
                }
              }
                lost.isVip = _.isEqual(lost.payStatus, 1);
            });
          }
            // let losts = _.orderBy(val, 'lost.orderStatusText').reverse()
            let losts = val
            this.losts = losts;
            if (_.isEmpty(this.losts)) {
                return
            }
            if (this.showActive == 1) {
                this.lostList = this.losts.filter(res => res.handleStatus == 2);
            } else if (this.showActive == 2) {
                this.lostList = this.losts.filter(res => res.handleStatus == 1);
            } else if (this.showActive == 3) {
                this.lostList = this.losts.filter(res => res.handleStatus == 0);
          } else {
            this.lostList = this.losts
          }
        },
        immediate: true
      },
      activeTabs: {
        handler(val, oldVal) {
          if (val == 'myOrder') {
            this.activeTab = 1
          } else {
            this.activeTab = 0
          }
        },
        immediate: true
      },
      callInfo: {
        handler(callInfo) {
          console.log('短信信息改变==>>', callInfo.phone)
          if (callInfo && callInfo.phone) {
            this.getOrderList()
          }
        }
      },
      openId: {
        handler(val, oldVal) {
          console.log('openId==>>', val);
          console.log('phone==>>', this.callInfo.phone)
          if (val) {
            this.getOrderList()
          }
        },
        immediate: true
      },
      showActive: {
        handler(newVal, oldVal) {
          if (_.isEmpty(this.losts)){
            console.log('----列表为空不请求---',);
            return
          }
          if (newVal == 1) {
            this.lostList = this.losts.filter(res => res.handleStatus == 2);
          } else if (newVal == 2) {
            this.lostList = this.losts.filter(res => res.handleStatus == 1);
          } else if (newVal == 3) {
            this.lostList = this.losts.filter(res => res.handleStatus == 0);
          } else {
            this.lostList = this.losts
          }
        },
        immediate: true,
        deep: true,
      },
    },
    computed: {
      ...mapState({
        userInfo: 'userInfo',
        openId: state => state.openId,
        lostServicePrice: 'lostServicePrice',
        lostOrders: 'lostOrders',
        callInfo: 'callInfo',
      }),

      lostAllCount() {
        return this.losts ? this.losts.length : '-'
      },
      unhandledCount() {
        if (this.lostOrders) {
          if (this.lostOrders.length === 0) {
            return 0;
          } else {
            return this.lostOrders.filter(item => item.handleStatus === 0).length;
          }
        } else {
          return 0;
        }
      },
      handlingCount() {
        if (this.lostOrders) {
          if (this.lostOrders.length === 0) {
            return 0;
          } else {
            return this.lostOrders.filter(item => item.handleStatus === 1).length;
          }
        } else {
          return 0;
        }
      },
      unchoseServiceCount() {
        if (this.lostOrders) {
          if (this.lostOrders.length === 0) {
            return 0;
          } else {
            return this.lostOrders.filter(item => item.serviceType === 0).length;
          }
        } else {
          return 0;
        }
      },
      isWeChat() {
        console.log('isWeChat=>', ua.wechat)
        return ua.wechat;
      },
      emptyFindResult() {
        return _.isEmpty(this.losts)
      },
      validFindResultForm() {
        return /(0|86|17951)?(13[0-9]|15[0-9]|17[678]|18[0-9]|14[57]|19[0-9]|16[0-9])[0-9]{8}$/
          .test(this.contactPhone);
      },
    },
    methods: {
      ...mapActions({
        prepayLostOrder: 'prepayLostOrder',
        loadLostOrders: 'loadLostOrders',
        loadLostServicePrice: 'loadLostServicePrice'
      }),
      /**
       * 获取当前城市信息
       */
      getCurrentCity(){
        console.log('获取当前位置城市');
        // if(!_.isEmpty(city.value)){
        //   console.log('---缓存有当前城市信息--刷新页面不重新获取当前定位城市---');
        //   return 
        // }
        AMap.plugin("AMap.Geolocation", () => {
        var geolocation = new AMap.Geolocation({
          // 是否使用高精度定位，默认：true
          enableHighAccuracy: true,
          // 设置定位超时时间，默认：无穷大
          timeout: 10000,
        });
        //只能获取当前用户所在城市和城市的经纬度
        geolocation.getCityInfo((status, result) => {  
          if (status == "complete") {
            // console.log("---城市和城市的经纬度---",result)
            this.currentCity =  {
              adcode: result.adcode,
              name: result.city,
              province: result.province,
              location:{
                longitude: result.position[0],
                latitude: result.position[1],
              }
            }
            console.log('---当前城市信息---',this.currentCity);
          }
        })
        //获取用户当前的精确位置
        geolocation.getCurrentPosition((status, result) => {  
          console.log('精准位置',status,result);
          if (status == "complete") {
            console.log("精准位置",result)
          }
        })
        })
      },


      //  选择
      checkItem(item, index) {
        this.showActive = index;
      },


      // 加载订单列表
      getOrderList() {
        let params = {
          phone: this.callInfo.phone
        }
        this.loadingLostOrders = true;
        this.loadLostOrders({...params})
          .then(res => {
          })
          .finally(() => {
            this.loadingLostOrders = false;
          });
      },
      /**
       * 高度坐标转百度
       * @param gg_lng
       * @param gg_lat
       * @returns {{bd_lng: number, bd_lat: number}}
       */
      bd_encrypt(gg_lng, gg_lat) {
        var X_PI = Math.PI * 3000.0 / 180.0;
        var x = gg_lng, y = gg_lat;
        var z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * X_PI);
        var theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * X_PI);
        var bd_lng = z * Math.cos(theta) + 0.0065;
        var bd_lat = z * Math.sin(theta) + 0.006;
        return {
          bd_lat: bd_lat,
          bd_lng: bd_lng
        }
      },
      onChoseService(lostItem) {
        const isVipNotPay = (lostItem.serviceType==2 && !lostItem.isVip)? '1' : '0'
        console.log('--订单是否为vip且没付钱--', isVipNotPay);
        this.$router.push(routeUtil.getCreateLostOrderPath('result', lostItem.invoiceImg ? 'yes' : 'no', lostItem.id, isVipNotPay))
      },
      onRouteCreateOrder(invoice) {
        let _this = this;
        if (this.unchoseServiceCount > 0) {
          Dialog.alert({
            title: '温馨提示',
            message: '您有未选择服务的订单，请选择您需要的服务类型！'
          }).then(() => {
            // on close
            _this.activeTab = 1;
          });
          return;
        }
        this.$router.push({path: routeUtil.getCreateLostOrderPath('form', 'no', 'null'), params: {invoice}})
      },
      returnVip() {
        this.$router.push('/aboutVip')
      },
      returnServeNotice() {
        this.$router.push('/serveNotice')
      },
      aboutVip() {
        this.activeTab = 1;
      },
      initWeChatPay() {
      },
      // 在蛋卷出行系统中创建失物查找订单
      commitLostOrder() {
        this.submittedLostOrder = null;
        this.submitting = true;

        //高德坐标转百度坐标
        let onLocation = this.bd_encrypt(this.form.getOnLng, this.form.getOnLat);
        this.form.getOnLat = onLocation.bd_lat;
        this.form.getOnLng = onLocation.bd_lng;
        let offLocation = this.bd_encrypt(this.form.getOffLng, this.form.getOffLat);
        this.form.getOffLat = offLocation.bd_lat;
        this.form.getOffLng = offLocation.bd_lng;
        const _this = this;
        axios({
          method: 'post',
          url: '/wx/lost/orders',
          baseURL: this.$store.state.taxiApiUrl,
          headers: {"content-type": "application/json"},
          data: this.form
        }).then(resp => {
          console.log('创建失物查找订单结果', resp);
          if (resp.data.status === "0") {
            _this.activeStep = 2;
            _this.orderId = resp.id;
            _this.activePageSuccess = true;
            _this.submittedLostOrder = resp.data.data;
            _this.getRoadData();
            _this.reloadLostOrders();
          } else {
            Dialog.alert({
              message: resp.data.message
            }).then(() => {
              // on close
            });
          }
        }).finally(() => {
          this.submitting = false;
        });
      },
      // 根据提交上下车点获取司机线路
      getRoadData() {
        this.activePage = "result";
      },
      // 在GPS调度系统中创建失物查找订单
      commitLostOrderGPS() {
        let form = {
          phone: this.form.phone,
          lostOwner: this.form.owner,
          getOnDateMinute: moment(this.form.getOnDateMinute).format("YYYY-MM-DD HH:mm:ss"),
          getOnLocation: this.form.getOnLocation,
          getOffDateMinute: moment(this.form.getOffDateMinute).format("YYYY-MM-DD HH:mm:ss"),
          getOffLocation: this.form.getOffLocation,
          carColor: this.form.carColor,
          goodsName: this.form.propertyName,
          goodsAmount: this.form.amount,
          goodsPosition: this.form.propertyPosition,
          seat: this.form.seat,
          line: this.form.line,
          sex: this.form.sex,
          passengerDescription: this.form.passengerDesc,
          driverSex: this.form.driverSex,
          cost: this.form.cost
        };

        this.submitting = true;
        axios({
          method: 'post',
          baseURL: this.$store.state.cheApiUrl,
          url: '/api/LostInfo/CommitOrder',
          headers: {"content-type": "application/x-www-form-urlencoded"},
          data: qs.stringify(form)
        }).then(resp => {
          console.log(resp);
          if (resp.data.status == "0") {
            this.activePage = "result"
          } else {
            Toast.fail(resp.data.message);
          }
        }).finally(() => {
          this.submitting = false;
        });
      },
      onClickTab(index, title) {
        if (this.clickedTab === index) {
          if (index === 1) {
            this.reloadLostOrders();
          }
        } else {
          this.clickedTab = index;
        }
      },
      onClickViewVipDetail() {
      },
      async loadFirstOrderDetail(value) {
        var _this = this;
        if (_this.orderId) {
          alert('获取不到orderId，请重试');
          return;
        }
        let toast = Toast.loading({
          mask: true,
          message: '加载中...',
          duration: 0,
        });

        let form = {
          value: value, //0.未选择（默认） 1.免费服务 2.付费服务
        }
        try {
          let {status, message, data} = await lostApi.ownerServiceType(_this.orderId, form);

          if (status === '0') {
            if (value == 2) {
              this.$router.push('/lostUpgrade/' + _this.orderId);
            } else {
              this.$router.push('/lostDetail/' + _this.orderId);
            }
          } else {
            Dialog.confirm({message: '修改服务类型失败：' + message});
          }
        } catch (e) {
          Dialog.confirm({message: '修改服务类型异常：' + e});
        } finally {
          // 加载状态结束
          toast.clear();
        }
      },
      onClickUpgrade(lost) {
        console.log('onClickUpgrade', lost);
        this.$router.push(`/lostUpgrade/${lost.id}`);
        // window.location.href=`/lostUpgrade/${lost.id}`;
      },
      onClickOrderCard(lost) {
        if (lost.serviceType == 0) {
          Dialog.alert({
            title: '温馨提示',
            message: '当前订单未选择服务，请先选取服务类型！'
          }).then(() => {
            // on close
            this.$router.push({path: routeUtil.getCreateLostOrderPath('result', 'no', lost.id)})
          });
          return;
        }
        this.$router.push({
          path: `/lostDetail/${lost.id}`,
        })
        // this.$router.push('/driverReward');
        // window.location.href=`/lostDetail/${lost.id}`;
      },
      onClickPreviousStep() {
        this.activePage = 'form';
        this.activeStep = 0;
      },
      onFormConfirm(form) {
        console.log('onFormConfirm', form);
        this.form = form;
        this.activePage = 'confirm';
        this.activeStep = 1;
      },
      onClickConfirmAndSubmit() {
        this.commitLostOrder();
      },
      onClickBuyVIPService(lostId) {
        Toast.loading("正在提交...");
        let _this = this;
        this.prepayLostOrder(lostId)
          .then(res => {
            console.log('创建预支付订单成功', res);
            // 支付确认对话框
            _this.onWeChatReadyToPay(res);
            // _this.wechatPay()
          })
          .catch(reason => {
            Dialog.alert({
              title: '支付失败',
              message: reason
            }).then(() => {
              // on close
            });
          })
          .finally(() => {
            Toast.clear();
          });
      },
      onWeChatReadyToPay(params) {
        // https://mp.weixin.qq.com/wiki?t=resource/res_main&id=mp1421141115
        // 所有需要使用JS-SDK的页面必须先注入配置信息，否则将无法调用（同一个url仅需调用一次，对于变化url的SPA的
        // web app可在每次url变化时进行调用,目前Android微信客户端不支持pushState的H5新特性，所以使用pushState来
        // 实现web app的页面会导致签名失败，此问题会在Android6.2中修复）。
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，
          // 可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: params.appId, // 必填，公众号的唯一标识
          timestamp: params.timeStamp, // 必填，生成签名的时间戳
          nonceStr: params.nonceStr, // 必填，生成签名的随机串
          signature: params.paySign,// 必填，签名
          jsApiList: ['chooseWXPay'] // 必填，需要使用的JS接口列表 // https://mp.weixin.qq.com/wiki?t=resource/res_main&id=mp1421141115
        });

        wx.ready(function () {
          console.log("微信wx.ready()被调用");
          // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的
          // 异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。
          // 对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
          wx.chooseWXPay({
            appId: params.appId,
            timestamp: params.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
            nonceStr: params.nonceStr, // 支付签名随机串，不长于 32 位
            package: params.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
            signType: params.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
            paySign: params.paySign, // 支付签名
            success: function (res) {
              console.log('支付成功', res);
              // use($('.forbid'));
              // 支付成功后的回调函数
              //window.location.href = 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzU4MzIzMzM3OA==&scene=126#wechat_redirect'
              // window.location.href = '/wx/lucky/index'
            },
            fail: function (res) {
              console.log('支付失败', res);
              // use($('.forbid'));
              // alert('支付失败，请重新支付');
            },
            cancel: function (res) {
              console.log('支付取消', res);
              // use($('.forbid'));
            }
          });
        });
        wx.error(function (res) {
          // config信息验证失败会执行error函数，如签名过期导致验证失败，
          // 具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
          console.log('wx.error', res);
          alert('支付失败（Config失败），请重新支付' + res);
        });
      },
      wechatPay(params) {
        function onBridgeReady() {
          WeixinJSBridge.invoke(
            'getBrandWCPayRequest', {
              appId: params.appId,
              timestamp: params.timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
              nonceStr: params.nonceStr, // 支付签名随机串，不长于 32 位
              package: params.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
              signType: params.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
              paySign: params.paySign, // 支付签名
            },
            function (res) {
              if (res.err_msg == "get_brand_wcpay_request:ok") {
                // 使用以上方式判断前端返回,微信团队郑重提示：
                //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              }
            });
        }

        if (typeof WeixinJSBridge == "undefined") {
          if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
          } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
            document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
          }
        } else {
          onBridgeReady();
        }
      },
      upgradeSubmittedLostOrder() {
        if (this.submittedLostOrder && this.submittedLostOrder.id) {
          this.$router.push("/lostUpgrade/" + this.submittedLostOrder.id);
        }
      },
      reloadLostOrders() {
        console.log("重新加载失物查找订单");
        this.loadLostOrders({});
      },
      onLoadFindResult() {
      },
      onSubmitFind() {
        this.submittingFind = true;
        this.loadedFindResult = false;
        this.loadLostOrders({phone: this.contactPhone})
          .then(res => {
            this.loadedFindResult = true;
          })
          .catch(reason => {
            Toast.fail(reason);
          })
          .finally(() => {
            this.submittingFind = false;
          });
      }
    }
  }
</script>


<style lang="scss" scoped>
  .NavBar {
    position: fixed;
    top: 46px;
    left: 0;
    right: 0;
    min-height: 50px;
    background-color: #fff;
    align-items: center;
    justify-content: space-evenly;

    .item {
      width: 66px;
      height: 33px;
      background-color: grey;
      color: white;
      border-radius: 5px;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      cursor: pointer;
    }

    .active {
      background-color: #002aff;
    }
  }

  .result-vip-card {
    padding: 1px;
    width: auto;
    margin-left: 15px;
    margin-right: 15px;
    height: 153px;
    background: linear-gradient(180deg, rgba(66, 66, 78, 1) 0%, rgba(30, 30, 39, 1) 100%);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.16);
    opacity: 1;
    border-radius: 4px;
  }

  .result-vip-card .title {
    padding: 0;
    margin-top: 20px;
    position: relative;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: bold;
    color: rgba(255, 214, 147, 1);
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
    opacity: 1;
  }

  .result-vip-card .title2 {
    margin-top: 10px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 300;
    line-height: 20px;
    color: rgba(255, 214, 147, 1);
    opacity: 1;
  }

  .result-title {

    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 25px;
    color: rgba(34, 34, 34, 1);
    opacity: 1;
  }

  .result-text {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 26px;
    color: rgba(153, 153, 153, 1);
    opacity: 1;
  }

  .notice-item {
    margin: 10px 10px;

  }

  .notice-item .van-button {
    min-width: 200px;
  }

  .order-exist-notice {
    margin-top: 20px;
  }

  .dot {
    border-radius: 50%;
    height: 16px;
    width: 16px;
    padding: 2px;
    font-size: 8px;
    display: inline-block;
    background: #c66d03;
    vertical-align: center;
    margin-right: 16px;
  }

  .dot-off {
    border-radius: 50%;
    height: 16px;
    width: 16px;
    padding: 2px;
    font-size: 8px;
    display: inline-block;
    background: #13a831;
    vertical-align: center;
    margin-right: 16px;
  }

  .dot-text {
    display: block;
    color: #FFFFFF;
    height: 20px;
    line-height: 20px;
    font-size: 8px;
    vertical-align: center;
    margin: 0;
    text-align: center
  }

  .card-item-val {
    // font-weight: bold;
    font-size: 14px;
    font-family: PingFang SC;
    line-height: 20px;
    color: rgba(34, 34, 34, 1);
    opacity: 1;

  }

  .card-item-time {
    color: darkgray;
  }

  .card-item-title {
    height: 20px;
    font-size: 14px;
    font-family: PingFang SC;

    line-height: 20px;
    color: rgba(153, 153, 153, 1);
    opacity: 1;
  }

  .card-item-title.result {
    //color: rgb(76, 112, 228);
    color: black;
  }

  .find-result-wrapper {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .result-wrapper {
    padding-top: 30px;
    padding-left: 20px;
    text-align: center;
    padding-right: 20px;
  }

  .call-success-text {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: bold;
    text-align: center;
    line-height: 25px;
    color: rgba(95, 203, 167, 1);
    opacity: 1;
  }

  .call-success-text2 {
    font-size: 16px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 25px;
    color: rgba(34, 34, 34, 1);
    opacity: 1;
  }

  .call-success-text3 {
    font-size: 14px;
    font-family: PingFang SC;
    line-height: 25px;
    color: rgba(153, 153, 153, 1);
    opacity: 1;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    width: 252px;
  }

  .not-wechat-notice {
    margin-left: 16px;
    margin-right: 16px;
  }

  .not-wechat-notice .guide {
    font-size: 14px;
  }

  .not-wechat-notice .keyword {
    font-size: 18px;
    font-weight: bold;
    color: goldenrod;
  }

  .popup {
    background-color: green;
  }


  .card {
    background: white;
    margin-top: 20px;
    border-radius: 5px;

    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 5px 10px rgba(188, 187, 180, 0.16);
    opacity: 1;
    border-radius: 6px;
  }

  .card:active {
    background-color: #f3f3f3ff;
  }

  .card-content {
    padding: 15px;
  }

  .card-footer {
    height: 60px;
    background: #ffffff;
    box-shadow: 0px 5px 10px rgba(188, 187, 180, 0.16);
    opacity: 1;
    border-radius: 0px 0px 6px 6px;
    border-top: 1px solid rgba(241, 241, 241, 1);;
  }

  .card-footer .text {
    margin: 0;
    alignment: center;
    text-align: center;
    height: 60px;
    line-height: 60px;

    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: rgba(77, 114, 233, 1);
    opacity: 1;
  }

  .card-footer .text.unhandled {

  }

  .card-footer .text.handled {
    background: rgba(204, 204, 204, 1);
    box-shadow: 0px 5px 10px rgba(188, 187, 180, 0.16);
    opacity: 1;
    color: rgba(255, 255, 255, 1);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    font-size: 16px;
  }

  .card-footer .upgrade-button {
    height: 60px;
    background: rgba(255, 71, 71, 1);
    opacity: 1;
    line-height: 60px;
    text-align: center;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
  }

  .card-footer .upgrade-button:active {
    opacity: 0.75;
  }

  .content-header {
    display: flex;
    align-items: center;
    height: 53px;
    background: rgba(230, 80, 65, 1);
    opacity: 1;
    border-radius: 4px 4px 0px 0px;
    padding-left: 15px;
    margin: 0;
  }

  .vip-icon {
    width: 44px;
    opacity: 1;
  }

  .vip-text {
    margin-left: 4px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 22px;
    color: rgba(255, 255, 255, 1);
    opacity: 1;
  }

  .order-status-text.unhandled {
    color: #dbaf5f;
  }

  .order-status-text.handling {
    height: 20px;
    font-size: 14px;
    font-family: PingFang SC;
    line-height: 20px;
    color: rgba(255, 71, 71, 1);
    opacity: 1;
  }

  .order-status-text.handled {
    color: #6aad04;
  }

  .divider {
    padding: 8px 16px;
    background-color: #f5f5f5;
  }

  .h-divider {
    height: 1px;
    background-color: #f5f5f5;
  }

  .root {
    text-align: left;
    alignment: left;
    height: 100%;
    padding: 96px 0 10px;
  }

  .card-row {
    margin-bottom: 5px;
  }

  .back-button:active {
    opacity: 0.75;
  }

  .back-button {
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-bottom: 30px;
    height: 50px;

    border: 1px solid rgba(77, 114, 233, 1);
    box-shadow: 0px 15px 30px rgba(188, 187, 180, 0.2);
    border-radius: 4px;

    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 50px;
    text-align: center;
    vertical-align: center;
    color: rgba(77, 114, 233, 1);
    opacity: 1;
  }

  .submit:hover {
    opacity: 0.75;
  }

  .submit:focus {
    opacity: 0.85;
  }

  .submit {
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-bottom: 30px;

    height: 50px;
    background: rgba(77, 114, 233, 1);
    box-shadow: 0px 15px 30px rgba(188, 187, 180, 0.2);
    opacity: 1;
    border-radius: 4px;


    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 22px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    vertical-align: center;

  }

  .footer {
    width: 100%;
    height: 60px;
    background: rgba(255, 214, 147, 1);
    opacity: 1;
    text-align: center;
    line-height: 60px;
  }

  .footer_text {

    height: 22px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 22px;
    color: rgba(34, 34, 34, 1);
    opacity: 1;
  }

  .notice-add {
    width: 95%;
    background: rgba(77, 114, 233, 1);
    opacity: 1;
    border-radius: 4px;
    color: white
  }

  .notice-button {
    width: 95%;
    border-radius: 4px;

  }

  .notice-foot {
    height: 20px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 20px;
    color: rgba(230, 80, 65, 1);
    opacity: 1;
  }

  .popup_img {
    width: 70px;
    height: 70px;
    background: rgba(95, 203, 167, 1);
    border-radius: 50%;
    opacity: 1;
    /*margin-top: 20px;*/
  }

  .popup_t1 {
    width: 90px;
    height: 25px;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 25px;
    color: rgba(34, 34, 34, 1);
    opacity: 1;
    margin-top: 15px;
  }

  .popup_t2 {
    width: 170px;
    height: 44px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 22px;
    color: rgba(255, 102, 45, 1);
    opacity: 1;
    margin-top: 10px;
    text-align: center;
  }

  .circle {
    width: 14px;
    height: 14px;
    background: rgba(230, 80, 65, 1);
    border-radius: 50%;

    text-align: center;
    font-size: 10px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 14px;
    color: rgba(255, 255, 255, 1);

    display: inline-block;
    margin-left: 6px;
    margin-top: -3px;
  }

  .col_value {
    text-align: right;
  }

  .footer_phone {

    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    font-family: PingFang SC;

    color: rgba(159, 159, 167, 1);
    opacity: 1;
    //position: absolute;


    //transform: translateX(-125px);

  }

  .stepImg {
    width: 100%;
  }

  .success_card {
    /*height:155px;*/
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04);
    opacity: 1;
    border-radius: 4px;
  }

  .success_card_title {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 22px;
    color: rgba(34, 34, 34, 1);
    opacity: 1;
    border-bottom: 2px solid rgba(241, 241, 241, 1);
  }

  .successRadio_right {
    height: 16px;
    font-size: 11px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 16px;
    color: rgba(255, 255, 255, 1);
    opacity: 1;
    padding: 2px 6px;
    margin-left: 5px;
    background: gray;
    border: 1px;
  }

  .notice_title {

    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 30px;
    color: rgba(123, 123, 123, 1);
    opacity: 1;
  }

  .notice_line {
    width: 100%;
    height: 0px;
    border: 1px solid rgba(159, 159, 167, 1);
    opacity: 1;
  }

  .content_title {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 26px;
    color: rgba(123, 123, 123, 1);
    opacity: 1;
    text-align: left;
    margin-top: 5px;
  }

  .content_value {
    font-size: 13px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 19px;
    color: rgba(159, 159, 167, 1);
    opacity: 1;
    text-align: left;
  }

  .c-c {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .find-result-list {
    width: 90%;
  }

  .root-page {
    height: 100%;
    width: 100%;

    .banner {
      height: 160px;
      background: #06a0d4;
      opacity: 1;
    }

    .content-wrapper {
      position: absolute;
      width: 100%;
      top: 110px;
    }

    .content {
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  .order-count-card {
    display: flex;
    flex-direction: row;
    align-content: space-between;
    padding: 0px;
    align-items: center;

    height: 100px;

    background: #FFFFFF;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    .divider {
      background-color: #F1F1F1;
      height: 43px;
      padding: 0;
      width: 1px;
    }

    .card-item {
      padding: 4px;
      display: flex;
      flex-grow: 1;
      margin-bottom: 12px;
      flex-direction: column;
      align-items: center;
      align-content: center;
    }

    .count {
      margin-top: 18px;
      font-size: 30px;
      font-weight: bold;
      line-height: 42px;
    }

    .arrow {
      margin-left: 6px;
      display: inline-block;
      width: 6px;
      height: 9px;
      background-image: url(../../assets/img/lost/arrow-right.svg);
      background-repeat: no-repeat;
    }

    .red {
      color: #E65041;
    }

    .title {
      margin-top: 5px;
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;
      color: #909194;
    }
  }

  .content {
    .btn {
      height: 55px;
      width: 100%;
      border-radius: 8px;
    }

    .btn.primary {
      @extend .btn;
      background: #4C70E4;
      color: white;
    }

    .btn.snd {
      @extend .btn;
      margin-top: 15px;
      background: #FE6A4D;
      color: white;
    }
  }

  .btn-contact {
    align-content: center;
    text-align: center;
    width: 100%;
    height: 66px;
    line-height: 66px;
    alignment: center;
    align-content: center;

    .img {
      display: inline-block;
      width: 133px;
      height: 100%;
      line-height: 66px;
      background-image: url(../../assets/img/lost/contact.png);
      background-repeat: no-repeat;
      background-size: 100%;
    }
  }

</style>
