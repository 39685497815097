<template>
  <div>
    <div class="root">
      <van-nav-bar :title="$route.meta.navTitle" fixed placeholder left-arrow @click-left="onBack()">
        <template #right>
          <div class="divider_right" @click="onRefresh">
            <img src="../../img/reset@2x.png" alt="">
            <span>刷新</span>
          </div>
        </template>
      </van-nav-bar>
      <div v-if="layout === 'showInfo'">
        <div class="banner" v-if="bizConfig.enableVip && order && !order.isVip && order.cityCode != '450200'" @click="onUpgrade">
          <span class="header">升级VIP服务服务，更快找回失物 >>></span>
        </div>
        <section class="content-header" v-if="order && order.isVip">
          <img src="../../img/mao@2x.png" style="width:23px;height:18px" alt="">
          <span class="vip-text">订单已升级VIP服务</span>
        </section>
        <div class="divider">
          <span>最新进度</span>
        </div>
        <lost-steps :order="order"></lost-steps>
        <lost-order-info :order="order"></lost-order-info>

        <template v-if="order.handleStatus !== null && order.handleStatus !=2 && order.handleStatus !=3">
          <div class="button-box">
            <van-button class="button-item button-l" icon="phone-o" @click="onCall">
              联系客服
            </van-button>
            <van-button class="button-item button-r" icon="edit" @click="onEditOrder">
              修改信息
            </van-button>
          </div>
        </template>
      </div>
      <div v-else-if="layout === 'showForm'">
        <div>
          <lost-form :data="order" @on-cancel="onFormCancel" @on-confirm="onFormConfirm" :show-back-button="true"
            confirmText="保存修改"></lost-form>
        </div>
      </div>
      <div v-else-if="layout === 'simple'">
        <div>
          <lost-form :data="order" @on-cancel="onFormCancel" @on-confirm="onFormConfirm" :show-back-button="true"
            :layout="layout" confirmText="保存修改"></lost-form>
        </div>
      </div>
      <div class="bottom-pad">
        <div style="font-size: 9px; line-height: 38px; vertical-align: middle; text-align: center; color: darkgray">
          本服务由广西蛋卷科技技术有限公司提供
        </div>
      </div>
      <van-popup v-model="showMatchDriver" style="border-radius: 4px">
        <div class="pop_content">
          <span class="pop_title">温馨提示</span>

          <div v-if="bizConfig.enableVip">
            <span class="pop_match">
              平台已匹配到（<span style="color:#4C70E4">{{ order.matchPlateNumber }}</span><span>）车辆， 请选择下一步操作：</span>
            </span>

            <van-radio-group v-model="popRadio" class="pop_radio">
              <template v-if="order.cityCode != '450200'">
                <van-radio name="1" style="margin-bottom: 5px">
                  <span style="color: #222222;font-size: 16px;">立即让坐席联系司机</span>
                </van-radio>
                <span class="radio_text">将自动跳转升级VIP服务页面</span>
              </template>
              <van-radio name="2" style="margin-top: 14px;margin-bottom: 20px">
                <span style="color: #222222;font-size: 16px;">转交出租车公司处理</span>
              </van-radio>
            </van-radio-group>
          </div>
          <div v-else>
            <div class="pop_match">平台已匹配到（<span
                style="color:#E65041">{{ order.matchPlateNumber }}</span>）车辆，并且已通知出租车公司，由出租车公司与司机核实是否找到失物，请您耐心等待公司与您联系。
            </div>
          </div>

          <div class="pop_footer">
            <span @click="showMatchDriver = false">关闭</span>
            <span @click="onSubmitPop" style="color:#4C70E4">确定</span>
          </div>
        </div>
      </van-popup>
      
    </div>
    <van-dialog v-model="isDialog" confirm-button-text="好的" confirm-button-color="#4C70E4">
      <div class="dialog-content">
        <img class="dialog-content-img" src="../../assets/img/lost/success-icon.png" alt="">
        <div class="dialog-content-text">
          信息已转交至出租车公司，由出租车公司与司机核实是否找到失物，请您耐心等待公司与您联系。
        </div>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import moment from 'moment';
import Vue from 'vue'
import {
  ActionSheet,
  Cell,
  CellGroup,
  DatetimePicker,
  Dialog,
  Field,
  Icon,
  List,
  NoticeBar,
  Picker,
  Popup,
  PullRefresh,
  Tab,
  Tabs,
  Tag,
  Toast
} from 'vant';
import { mapActions, mapState } from 'vuex';
import store from '@/store/store'
import * as _ from 'lodash';
import LostOrderInfo from "./components/LostInfo";
import LostSteps from "./LostSteps";
import LostForm from "./components/LostForm";
import lostApi from "../../store/lostApi";
Vue.use(PullRefresh);
Vue.use(Dialog);
Vue.use(Tag);
Vue.use(Icon);
Vue.use(Toast);
Vue.use(NoticeBar);
Vue.use(List);
Vue.use(Tab).use(Tabs);
Vue.use(DatetimePicker);
Vue.use(Popup);
Vue.use(Toast);
Vue.use(ActionSheet);
Vue.use(Picker);
Vue.use(Field);
Vue.use(Cell).use(CellGroup);
export default {
  name: "lostdetail",
  components: { 
    LostForm, 
    LostOrderInfo, 
    LostSteps,
    [Dialog.Component.name]: Dialog.Component,
  },
  props: ['lostid'],
  data() {
    return {
      order: {},
      from: null,
      isLoading: false,

      layout: 'showInfo', // showInfo showForm

      showMatchDriver: false,
      showMatchDriverCount: 0,
      popRadio: null,
      isDialog: false,
    }
  },
  created() {
    const from = this.$route.query.from;
    if (from) {
      this.from = 'lostUpgrade'
    }
  },
  mounted() {
    // 清空路由栈，留下首页
    store.commit('CHANGE_ROUTER', [...new Set(this.routerPaths)]);
    console.warn('路由栈：', this.routerPaths)
  },
  watch: {
    lostid: {
      handler(val, oldVal) {
        console.log('lostid changed', this.lostid, val);
        if (_.isEmpty(val)) {
          Toast.fail("请传递lostid属性给组件");
        } else {
          this.reloadOrders();
        }
      },
      immediate: true
    },
    order(val, oldVal) {
      console.log('订单详情', val);

    }
  },
  computed: {
    ...mapState({
      openid: 'openid',
      routerPaths: 'routerPaths'
    }),
  },
  destroyed() {
    // window.removeEventListener('popstate', this.back(), false);//false阻止默认事件
  },
  methods: {
    ...mapActions({
      loadLostOrders: 'loadLostOrders',
      pushRouterPath: 'pushRouterPath',
      popRouterPath: 'popRouterPath'
    }),

    // 返回
    back() {
      console.warn('开始返回：', this.routerPaths)
      console.warn('去重后的路由栈：', this.routerPaths)
      if (_.isArray(this.routerPaths) && this.routerPaths.length > 4) {
        console.warn('路由栈超过3个')
        store.commit('CHANGE_ROUTER', []);
        this.$router.replace('/lost')
      }
    },
    //导航栏返回
    onBack(){
      let lastRoutePath = this.routerPaths[this.routerPaths.length - 2] //上一个路由
      if(_.startsWith(lastRoutePath, '/lostUpgrade/') || _.startsWith(lastRoutePath, '/createOrder/result/')){
        store.commit('CHANGE_ROUTER', []);
        this.$router.replace('/lost')
      }else{
        this.$router.back()
      }
    },
    onUpgrade() {
      if (this.order && this.order.id) {
        this.$router.push('/lostUpgrade/' + this.order.id)
      } else {
        Dialog.alert({ message: '获取不到orderId跳转失败，请刷新页面' });
      }

    },

    onSubmitPop() {

      if (this.bizConfig.enableVip) {
        if (this.popRadio == 1) {
          this.$router.push('/lostUpgrade/' + this.order.id);
          this.showMatchDriver = false;
        } else if (this.popRadio == 2) {
          this.isDialog = true
          // Dialog.alert({ message: '信息已转交至出租车公司，由出租车公司与司机核实是否找到失物，请您耐心等待公司与您联系。' });
          this.showMatchDriver = false;
        } else {
          Dialog.alert({ message: '无法提交，请选择操作' });
        }
      } else {
        this.showMatchDriver = false;
      }
    },

    showPopSuccess(order) {
      if (order.matchStatus == this.consts.LOST_MATCH_STATUS_MATCHED && !order.isVip && this.showMatchDriverCount == 0) {
        this.showMatchDriver = true;
        this.showMatchDriverCount = 1;
      }
    },
    onRefresh() {
      const _this = this;
      let toast = Toast.loading({
        mask: true,
        message: '刷新中...',
        duration: 0,
      });
      this.loadLostOrders({ phone: null })
        .then(res => {
          console.log('单个订单获取')
          let order = res.find(lost => lost.id == _this.lostid);
          order.isVip = _.isEqual(order.payStatus, 1);
          _this.order = order;

          _this.showPopSuccess(order);
          Toast.success({
            message: '刷新成功',
            duration: 3000,
          })
        })
        .catch(reason => {
          Toast.fail(reason);
        })
        .finally(() => {
          toast.clear()
        });
    },
    onaboutVip() {
      this.$router.push('/aboutVip')
    },
    onCall(){
      const a = document.createElement('a');
      a.href = 'tel: 0771-5883093';
      a.click()
    },
    // 重新编辑信息
    onEditOrder() {
      if (this.order) {
        this.order.getOnDateMinute = moment(this.order.getOnDate).format("YYYY-MM-DD HH:mm");
        this.order.getOffDateMinute = moment(this.order.getOffDate).format("YYYY-MM-DD HH:mm");
      }
      if (this.order.invoice) {
        this.layout = 'simple'
        return
      }
      this.layout = 'showForm';
    },
    onFormCancel() {
      this.layout = 'showInfo';
    },
    async onFormConfirm(form) {
      console.log('onFormConfirm', form);
      if (form.id) {
        Toast.loading("保存中...");
        try {
          let { status, message } = await lostApi.addOrder(form);
          Toast.clear();
          if (status === '0') {
            this.layout = 'showInfo';
            Dialog.confirm({ message: '保存修改成功' });
            this.reloadOrders();
          } else {
            Dialog.confirm({ message: '保存失败：' + message });
          }
        } catch (e) {
          Dialog.confirm({ message: '异常：' + e });
        } finally {
          Toast.clear();
        }
      } else {
        Dialog.alert({ message: '无法提交，ID为空' });
      }
    },
    reloadOrders() {
      const _this = this;
      const loading = Toast.loading({
        overlay: true,
        message: 'loading...',
        duration: 0
      })
      this.loadLostOrders({ phone: null })
        .then(res => {
          console.log('单个订单获取')
          let order = res.find(lost => lost.id == _this.lostid);
          order.isVip = _.isEqual(order.payStatus, 1);
          _this.order = order;
          _this.showPopSuccess(order);
        })
        .catch(reason => {
          Toast.fail(reason);
        })
        .finally(() => {
          loading.clear()
        });
    },
    onClickUpgradeButton() {
      if (_.isEmpty(this.lostid)) {
        Toast.fail('信息异常，无法提交');
      } else {
        this.$router.push('/lostUpgrade/' + this.lostid);
      }
    }
  }
}
</script>


<style lang="scss" scoped>
.submit {
  width: 90%;

  font-size: 16px;

  // font-weight: bold;
  line-height: 50px;
  // color: rgba(255, 255, 255, 1);

  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;

  height: 50px;
  // background: rgba(77, 114, 233, 1);
  // box-shadow: 0px 15px 30px rgba(188, 187, 180, 0.2);
  opacity: 1;
  border-radius: 4px;
  color: #4C70E4;
  border: 1px solid #4C70E4;
  background-color: transparent;
}

.root {
  text-align: left;
}

.vip-icon {
  width: 23px;
  height: 20px;
  display: inline-block;
  margin-top: auto;
  margin-bottom: auto;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../../public/assets/lost/vip@2x.png');
}

.vip-text {
  font-size: 14px;
  font-family: PingFang SC;
  margin-left: 8px;
  line-height: 20px;
  color: #FFCE7D;
  opacity: 1;
}

.content-header {

  height: 50px;
  background: #34343C;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  /*margin-bottom: 25px;*/
}


.banner {
  height: 50px;
  background: #34343C;
  opacity: 1;
  text-align: center;
}

.banner .header {

  font-size: 16px;

  font-weight: 400;
  line-height: 50px;
  color: rgba(255, 206, 125, 1);
  opacity: 1;
}

.banner .button-wrapper {
  text-align: right;
  alignment: center;
  line-height: 50px;
}

.banner .button {
  display: inline-block;
  width: 80px;
  height: 30px;
  text-align: center;
  vertical-align: center;
  background: rgba(51, 51, 51, 1);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
  opacity: 1;
  border-radius: 33px;

  margin-right: 15px;
  margin-top: auto;
  margin-bottom: auto;

  font-size: 12px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 30px;
  color: rgba(255, 255, 255, 1);
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
}

.cell-title {
  height: 20px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 20px;
  color: rgba(153, 153, 153, 1);
  opacity: 1;
}

.cell-value {
  height: 20px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 20px;
  color: rgba(34, 34, 34, 1);
  opacity: 1;
}

.step-dot {
  width: 10px;
  height: 10px;
  border: 3px solid rgba(51, 51, 51, 1);
  border-radius: 50%;
  opacity: 1;
}

.step-item h3 {
  margin: 0;
  font-size: 14px;
}

.step-item-title {
  margin: 0;
}

.divider {
  width: 100%;
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 16px;
  background-color: #E6E6E7;
  font-weight: bold;
  font-size: 16px;
  color: rgba(34, 34, 34, 1);
  /*margin-top: -25px;*/
}
.button-box{
  display: flex;
  width: 100%;
  padding: 0 18px;
  margin-top: 30px;
  box-sizing: border-box;
  .button-item{
    flex: 1;
    height: 50px;
    box-shadow: 0px 15px 30px 1px rgba(188,187,180,0.2);
    border-radius: 4px 4px 4px 4px;
    color: #fff;
    font-size: 15px;
  }
  .button-l{
    background: #FF7458;
  }
  .button-r{
    margin-left: 20px;
    background: #4C70E4;
  }

}

.divider_right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.divider_right img {
  width: 16px;
}

.divider_right {
  font-size: 14px;

  font-weight: 400;
  line-height: 20px;
  color: rgba(76, 112, 228, 1);
  opacity: 1;
  margin-left: 3px;
}

.bottom-pad {
  height: 38px;
}

.pop_content {
  width: 295px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 22px;
  box-sizing: border-box;
}

.pop_title {
  font-size: 18px;

  /* font-weight: 500; */
  line-height: 25px;
  color: rgba(34, 34, 34, 1);
  opacity: 1;

  margin: 20px 0 10px;
}

.pop_match {
  /* padding: 0 16px; */
  font-size: 14px;

  /* font-weight: 500; */
  line-height: 33px;
  color: rgba(52, 52, 60, 1);
  opacity: 1;
  width: 90%;
}

.pop_radio {
  width: 90%;
  font-size: 16px;

  font-weight: 500;
  line-height: 22px;
  color: rgba(34, 34, 34, 1);
  opacity: 1;

  margin-top: 5px;
}

.radio_text {
  font-size: 14px;

  font-weight: 400;
  line-height: 20px;
  color: #E65041;
  opacity: 1;
  margin-left: 30px;
}

.pop_footer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.pop_footer span {
  width: 50%;
  height: 55px;

  font-size: 16px;

  font-weight: 400;
  line-height: 55px;
  color: rgba(34, 34, 34, 1);
  opacity: 1;
  text-align: center;
  border: 1px solid rgba(241, 241, 241, 1);
}
.dialog-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 30px 20px;
  .dialog-content-img{
    width: 70px;
    height: 70px;
  }
  .dialog-content-text{
    margin-top: 10px;
    font-size: 16px;
    font-family: PingFang SC;
    color: #34343C;
  }
}
</style>
