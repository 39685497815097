<template>
    <div>
        <img style="width:100%" src="../img/serve-notice-bg.png" alt="">
    </div>
</template>

<script>
    export default {
        name: "ServeNotice"
    }
</script>

<style scoped>

</style>