<template>
    <div class="container">
        <div class="row">
            <div class="column_align_center">
                <img src="../../img/step_green@2x.png" class="img_green" alt="">
                <span class="line_green"></span>
            </div>
            <div class="column">
                <span class="right_title" style="margin-top: -3px">订单提交</span>
                <span class="right_time">{{order.createdOnStr}}</span>
            </div>
        </div>


        <!--        Flex row ： 从左到右排-->
        <div class="row" v-if="order.isVip">
            <!--            状态进度线-->
            <div class="column_align_center">
              <img src="../../img/step_green@2x.png" class="img_green" alt="">
                <span class="line_green"></span>
            </div>
            <!--            步骤的内容-->
            <div class="column">
                <span class="right_title" style="margin-top: -3px">订单升级VIP服务</span>
                <span class="right_time">{{order.payVipDateStr}}</span>
            </div>
        </div>

        <!--        Flex row ： 从左到右排-->
        <div class="row">
            <!--            灰色圆点和竖线-->
            <!--            绿色圆点和竖线-->
          <div v-if="order.handleStatus >= consts.LOST_HANDLE_STATUS_HANDLED" class="column_align_center"><img
              src="../../img/step_green@2x.png" class="img_green" alt=""> <span
              class="line_green"></span>
          </div>

          <div v-else class="column_align_center">
            <img src="../../img/step_green@2x.png" class="img_green" alt=""> <span class="line_gray"></span>
          </div>


            <div class="column"
                 :style="order.handleStatus != consts.LOST_HANDLE_STATUS_HANDLED ? {}:{}">

                <span v-if="order.handleStatus != consts.LOST_HANDLE_STATUS_HANDLED" class="right_title"
                      style="color:#34343C;margin-top: -3px">客服处理中<span v-if="order.handleStartAccount!=null"
                                                                                 style="font-size: 14px;color:gray">{{'（受理客服工号：'+order.handleStartAccount+'）'}}</span></span>
                <span v-else class="right_title" style="color:#34343C;margin-top: -3px">客服处理中<span
                        style="font-size: 14px;color:orangered" v-if="order.handleStartAccount!=null">{{'（受理客服工号：'+order.handleStartAccount+'）'}}</span></span>
                <span v-if="order.handleStartDateStr" class="right_time">{{order.handleStartDateStr}}</span>

                <!--v-if="order.matchResult==null"-->

                <!--                从上到下一行一行显示-->
                <div class="step-group normal-steps" style="margin-top: 15px;">
                    <div class="status-line">
                      <img v-if="order.matchStatus != null" src="../../img/blue_success@2x.png"
                           style="width:16px;height:16px" alt="">
                      <img v-else src="../../img/success_gray@2x.png" style="width:16px;height:16px" alt="">

                        <div
                                v-if="order.matchStatus == null"
                                :style="order.matchResult==null?'color:#ccc;':'color:#34343C'"
                                class="statusText">车辆匹配
                        </div>
                        <div
                                v-if="order.matchStatus == consts.LOST_MATCH_STATUS_UNMATCHED"
                                :style="order.matchStatus !== consts.LOST_MATCH_STATUS_UNMATCHED?'color:#ccc;':'color:#34343C'"
                                class="statusText">未匹配到车辆
                        </div>
                        <div
                                v-if="order.matchStatus == consts.LOST_MATCH_STATUS_MATCHED"
                                :style="order.matchStatus !== consts.LOST_MATCH_STATUS_MATCHED?'color:#ccc;':'color:#34343C'"
                                class="statusText">匹配到车辆
                        </div>
                    </div>
                    
                    <div v-if="order.matchStatus != null && order.matchTimeStr" class="right_time"
                         style="margin-left: 24px;margin-bottom: 6px">{{order.matchTimeStr}}
                    </div>
                    <!-- <div v-if="order.matchStatus == consts.LOST_MATCH_STATUS_MATCHED" style="font-size: 14px;margin-left: 24px;">
                        <span style="color: #34343C;">已匹配到<span>（1）</span>辆车行驶轨迹符合条件，订单将转至出租车公司与司机核实是否找到失物</span>
                        <span style="color: #E65041;" v-if="order.serviceType !== 2 && order.payStatus !== 1">（如您着急寻找失物，可升级VIP进入24小时VIP服务通道，享有优先加急、司机免费送还失物的服务。）</span>
                    </div> -->
                    <div v-if="order.matchStatus != null && order.matchResult"
                       style="color:#34343C;;font-size: 14px;margin-left: 24px;padding-right: 15px">{{order.matchResult}}
                    </div>

                    <!--<div class="check" v-else>-->
                    <!--<img src="../img/success_green@2x.png" style="width:16px;height:16px" alt="">-->
                    <!--<span style="color:#ccc;font-size: 14px;margin-left: 8px">{{order.matchResult}}</span>-->
                    <!--</div>-->
                </div>

                <!--                VIP专属的进度-->
                <div class="step-group vip-steps" v-if="bizConfig.enableVip">
                    <div class="status-line" style="margin-bottom: 10px;">

                      <img v-if="order.propertyConfirm==null" src="../../img/success_gray@2x.png" style="width:16px;height:16px" alt="">
                      <img v-else src="../../img/blue_success@2x.png" style="width:16px;height:16px" alt="">

                        <span :style="order.propertyConfirm==null?'color:#ccc;':'color:#34343C'" class="statusText">坐席联系司机，确认失物</span>

                        <span v-if="order && !order.isVip " style="color:#ccc;font-size: 14px;margin-left: 8px">
                            <template v-if="order.cityCode != '450200'">
                                （需要<span style="color:#4C70E4" @click="onClickUpgrade">升级服务</span>)
                            </template>
                        </span>
                      <img v-else src="../../img/check_vip@2x.png" style="width:44px;height:18px;margin-left: 8px" alt="">
                        <!--<div style="width:100%"><span>{{order.propertyConfirm}}</span></div>-->
                    </div>

                    <span class="right_time" v-if="order.propertyConfirm!=null" style="margin-left: 24px;margin-bottom: 6px">{{order.propertyConfirmTimeStr}}</span>
                    <div v-if="order.propertyConfirm!=null" style="color:#34343C;font-size: 14px;margin-left: 24px;margin-bottom: 18px">{{order.propertyConfirm}}</div>


                    <div class="status-line">
                      <img v-if="order.propertyReturn==null" src="../../img/success_gray@2x.png" style="width:16px;height:16px" alt="">
                      <img v-else src="../../img/blue_success@2x.png" style="width:16px;height:16px" alt="">
                        <span :style="order.propertyReturn==null?'color:#ccc;':'color:#34343C'" class="statusText">失物送还</span>
                        <span v-if="order && !order.isVip " style="color:#ccc;font-size: 14px;margin-left: 8px">
                            <template v-if="order.cityCode != '450200'">
                                （需要<span style="color:#4C70E4" @click="onClickUpgrade">升级服务</span>)
                            </template>
                        </span>
                      <img v-else src="../../img/check_vip@2x.png" style="width:44px;height:18px;margin-left: 8px" alt="">
                        <!--<div style="width:100%"><span>{{order.propertyReturn}}</span></div>-->
                    </div>

                    <span class="right_time" v-if="order.propertyReturn!=null" style="margin-left: 24px;margin-bottom: 6px">{{order.propertyReturnTimeStr}}</span>
                    <div v-if="order.propertyReturn" style="color:#34343C;font-size: 14px;margin-left: 24px;margin-bottom: 18px">{{order.propertyReturn}}</div>
                </div>

            </div>
        </div>

        <!--        订单结果进度-->
        <div class="row">

            <div class="column_align_center" v-if="order.handleStatus >= consts.LOST_HANDLE_STATUS_HANDLED">
              <img src="../../img/step_green@2x.png" class="img_green" alt="">
            </div>

            <div class="column_align_center" v-else>
              <img src="../../img/step_gray@2x.png" class="img_green" alt="">
            </div>

            <div class="column">

                <span class="right_title"
                      style="color:#34343C;margin-top:-3px">
                    订单结果
                    <span style="font-size: 13px;color:rgba(31,31,31,1)"></span>
                </span>
                <!--<span class="right_time">{{order.propertyConfirm}}</span>-->
                <span class="right_time">{{order.handleDateStr}}</span>

                <div class="step-group vip-steps" v-if="bizConfig.enableVip" style="margin-top: 15px;">
                    <!--v-if="order.handleStatus == 2"-->
                    <div class="status-line">
                        <img v-if="order.ownerConfirm==null" src="../../img/success_gray@2x.png" style="width:16px;height:16px" alt="">
                        <img v-else src="../../img/blue_success@2x.png" style="width:16px;height:16px" alt="">
                        <span :style="order.ownerConfirm==null?'color:#ccc;':'color:#34343C'" class="statusText">乘客确认</span>
                        <span v-if="order && !order.isVip " style="color:#ccc;font-size: 14px;margin-left: 8px">
                            <template v-if="order.cityCode != '450200'">
                                （需要 <span style="color:#4C70E4" @click="onClickUpgrade">升级服务</span>)
                            </template>
                        </span>
                    </div>
                    <span class="right_time" v-if="order.ownerConfirm!=null" style="margin-left: 24px;margin-bottom: 6px">{{order.ownerConfirmDateStr}}</span>
                    <div v-if="order.ownerConfirm!==null" style="color:#34343C;font-size: 14px;margin-left: 24px">
                        {{order.ownerConfirm==0?'乘客确认未找回失物':order.ownerConfirm==1?'乘客确认已找回失物':order.ownerConfirm==2?'未联系乘客确认':''}}
                    </div>
                </div>

                <!--<div class="check" v-else>-->
                <!--<img src="../img/success_gray@2x.png" style="width:16px;height:16px" alt="">-->
                <!--<span style="color:#ccc;font-size: 14px;margin-left: 8px">乘客确认</span>-->
                <!--</div>-->


                <div class="step-group normal-steps">

                    <!--v-if="order.handleStatus == 2"-->
                    <div class="status-line">
                      <img v-if="order.handleResult" src="../../img/blue_success@2x.png" style="width:16px;height:16px"
                           alt="">
                      <img v-else src="../../img/success_gray@2x.png" style="width:16px;height:16px" alt="">
                        <span :style="order.handleResult==null?'color:#ccc;':'color:#34343C'"
                              class="statusText">订单结果</span>
                      <!--<img src="../../img/check_vip@2x.png" style="width:44px;height:18px;margin-left: 8px" alt="">-->
                    </div>
                    <span v-if="order.handleResult" class="right_time" style="margin-left: 24px;margin-bottom: 6px">{{order.handleDateStr}}</span>
                    <div v-if="order.handleResult" style="color:#34343C;font-size: 14px;margin-left: 24px">
                        {{order.handleResult}}
                    </div>
                    <!--<div class="check" v-else>-->
                    <!--<img src="../img/success_gray@2x.png" style="width:16px;height:16px" alt="">-->
                    <!--<span style="color:#ccc;font-size: 14px;margin-left: 8px">订单结果</span>-->
                    <!--</div>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "LostSteps",
        props: {
            order: Object
        },
        data() {
            return {}
        },
        mounted() {
        },
        watch: {
            order: {
                handler(val) {
                    console.log('LostSteps 订单详情', val);
                }
            }
        },
        computed: {},
        methods: {
            onClickUpgrade() {

                this.$router.push(`/lostUpgrade/${this.order.id}`);

            },
        }
    }
</script>

<style scoped>
    .container {
        padding-left: 20px;
        padding-right: 10px;
        padding-top: 20px;
        padding-bottom: 20px;
        background: #ffffff;
    }

    .img_green {
        width: 18px;
        height: 18px;
    }

    .img_blue {
        width: 36px;
        height: 36px;
        margin-left: -9px;
    }

    .line_blue {
        width: 2px;
        flex: 1;
        background: rgba(204, 204, 204, 1);
        opacity: 1;
        margin-left: -9px;
    }

    .line_green {
        width: 2px;
        flex: 1;
        background: #5FCBA7;
        opacity: 1;

    }

    .line_gray {
        width: 2px;
        flex: 1;
        background: rgba(204, 204, 204, 1);
        opacity: 1;
        /*margin-left: -9px;*/
    }

    .right_title {
        font-size: 18px;
        font-family: PingFang SC;
        /* font-weight: 500; */
        line-height: 25px;
        color: #34343C;
        opacity: 1;
    }

    .right_time {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 17px;
        color: rgba(187, 187, 187, 1);
        opacity: 1;
    }

    .row {
        display: flex;
    }


    .column {
        display: flex;
        /* flex-direction 取值：
            row（默认）：主轴水平方向，起点在左端；
            row-reverse：主轴水平方向，起点在右端；
            column：主轴垂直方向，起点在上边沿；
            column-reserve：主轴垂直方向，起点在下边沿。*/
        flex-direction: column;

        align-items: flex-start;
        margin-bottom: 20px;
        margin-left: 22px;
    }

    .column_align_center {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .status-line {
        width: 100%;
        display: -webkit-flex;
        display: flex;
        vertical-align: top;
        flex-direction: row;
        align-items: center;
        /* align-items: flex-start; */
        margin: 0;
    }

    .statusText {
        font-size: 14px;
        line-height: 14px;
        padding: 0;
        margin-left: 8px

    }

    .normal-steps {
        /* background-color: whitesmoke; */
        border-radius: 10px;
        padding: 3px 6px;
        margin-bottom: 6px;
    }

    .vip-steps {
        /* background-color: rgba(255, 0, 0, 0.08); */
        padding: 6px;
        font-size: 12px;
    }

    .step-group {
        width: 100%;
        padding: 6px 10px;
        border-radius: 4px;
    }
</style>
