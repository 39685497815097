import Vue from 'vue'
import Vuex from 'vuex'

import driverApi from './driverApi';
import appConfig from '../config/appConfig'
import axios from 'axios'
import auth from '@/store/auth';
import geoutil from '../utils/geoutil'
import * as _ from 'lodash'
import ua from "../utils/agent";
import moment from 'moment';
import router from '../router/router'
import consts from '@/utils/consts'
import taxiApi from "@/store/taxiApi";
import callApi from "@/store/callApi";
import cache from '@/store/cache'

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    taxiApiUrl: appConfig.taxiApiUrl,
    cheApiUrl: appConfig.cheApiUrl,
    appVersion: process.env.VUE_APP_VERSION,
    packageName: process.env.VUE_APP_PACKAGE_NAME,
    isWechatAgent: ua.wechat, // 当前是不是在微信中打开
    token: undefined,
    driverToken: undefined,
    driver: null,
    cars: [], // 周围空车
    showMenu: false,
    userInfo: null,
    lostServicePrice: null,
    needWechatAgent: false, // 是否需要微信浏览器
    errorMessage: '',
    openId: '',
    lostOrders: [], // 当前公众号的失物查找订单
    routerPaths: [],
    driverLostRewards: null,

    bankCards: null, // 银行卡号，账号已经脱敏

    enableVip: false, // 是否启用VIP功能
    taxiActivities: [],
    showTabMenu: [],

    callInfo: {}, // 来自短信链接跳转的呼叫用户信息：phone, callTime
    smsAccessToken: null,  // 来自短信链接里面的访问令牌
    appType: 'single', // single : 单页面不显示导航， full: 底部显示Tab菜单

    //活动列表
    activityList: [],

  },

  mutations: {
    appType(state, data) {
      state.appType = data;
    },
    callInfo(state, data) {
      state.callInfo = data;
    },
    smsAccessToken(state, data) {
      cache.setKey('appType', 'single')
      cache.setKey('smsToken', data)
      state.appType = 'single'
      state.smsAccessToken = data;
    },
    CHANGE_ROUTER(state, data) {
      state.routerPaths = data || []
    },
    RESET_DRIVER_TOKEN(state) {
      auth.setToken(null);
      state.driverToken = null;
    },
    DRIVER_INFO(state, data) {
      console.log('DRIVER_INFO', data);
      state.driver = data;
    },
    DRIVER_TOKEN(state, data) {
      console.log('DRIVER_TOKEN', data);
      auth.setToken(data);
      state.driverToken = data;
    },
    DRIVER_LOST_REWARDS(state, data) {
      state.driverLostRewards = data;
    },
    BANK_CARDS(state, data) {
      console.log('BANK_CARDS', data);
      state.bankCards = data;
    },
    setLostOrders(state, data) {
      state.lostOrders = data;
    },
    OPEN_ID(state, data) {
      console.log('OPEN_ID', data);
      auth.setOpenId(data);
      axios.defaults.headers.common["openid"] = data;
      state.openId = data;
    },
    setCars(state, data) {
      state.cars = data;
    },
    USER_INFO(state, data) {
      state.userInfo = data;
    },
    LOST_SERVICE_PRICE(state, data) {
      state.lostServicePrice = data;
    },
    SHOW_MENU(state, data) {
      state.showMenu = data;
    },
    // 判断是否显示底部菜单栏
    SHOW_TAB_MENU(state, data) {
      state.showTabMenu = data
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
    TAXI_ACTIVITIES(state, data) {
      state.taxiActivities = data;
    },
    SET_ACTIVITY_LIST(state, data){
      state.activityList = data || []
      // console.log('-设置活动数据--',state.activityList );
    }
  },
  getters: {
    appFullName: state => {
      return state.packageName + 'v' + state.appVersion;
    },
    driverToken: state => state.driverToken,
    driverName: state => state.driver ? state.driver.name : ''
  },
  actions: {

    async loadSmsCallInfo({state, commit}) {
      if (!state.smsAccessToken) {
        return Promise.reject('empty access token')
      }
      let {success, data: callInfo, message} = await callApi.fetchCallInfo(state.smsAccessToken);
      if (success) {
        console.log('callInfo', callInfo)
        commit('callInfo', callInfo);
        return callInfo;
      } else {
        return Promise.reject(message);
      }
    },

    async loadTaxiActivities({commit}) {
      let {status, data, message} = await taxiApi.loadActivities();
      if (status === '0') {
        let ret = data || []
        commit('TAXI_ACTIVITIES', ret);
        return ret;
      } else {
        return Promise.reject('加载活动数据失败' + message);
      }
    },

    async loadBankCards({commit}) {
      let {status, message, data} = await driverApi.loadBankCards();
      if (status === '0') {
        commit('BANK_CARDS', data || []);
        return data;
      } else {
        return Promise.reject(new Error('获取银行卡失败，' + message));
      }
    },

    async loginDriver({commit}, loginForm) {
      function setCookie(name, value, day) {
        let Days = day;
        let exp = new Date();
        exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 30);
        document.cookie = name + "=" + escape(value) + ";expires=" + exp.toGMTString();
      }

      let resp = await driverApi.login(loginForm);
      if (resp.status === '0') {
        let {token} = resp.data;
        if (!_.isEmpty(token)) {
          setCookie('token', token, 30)
          commit('DRIVER_TOKEN', token);
          return resp.data;
        } else {
          return Promise.reject('登录成功，没有返回TOKEN');
        }
      } else {
        return Promise.reject('请求失败，' + resp.message);
      }

    },

    async loadDriver({commit}) {
      let res = await driverApi.loadDriverInfo();
      if (res.status === '0') {
        commit('DRIVER_INFO', res.data);
        return res.data;
      } else {
        return Promise.reject(new Error('加载司机信息失败，' + res.message));
      }

    },

    async loadLostRewards({commit}) {
      let {status, data, message} = await driverApi.loadLostRewards();
      if (status === '0') {

        if (_.isArray(data)) {
          let filtered = data.filter(reward => reward.money !== null);
          commit('DRIVER_LOST_REWARDS', filtered);
        } else {
          commit('DRIVER_LOST_REWARDS', null);
        }

        return data;
      } else {
        return Promise.reject('加载司机失物查找奖励信息失败，' + message);
      }
    },

    pushRouterPath({commit, state}, path) {
      if (state.routerPaths.length > 0) {
        let lastRoutePath = state.routerPaths[state.routerPaths.length - 1] //最后的路由
        console.log('----上一个路由----',lastRoutePath);
        console.log('----目前路由----',path);
        //最后一个路由与当前的路由不匹配 - 追加路由
        if (lastRoutePath != path) {
          if(_.startsWith(lastRoutePath, '/createOrder/')){ //创建订单页
            if(!state.routerPaths.some(val=>val === path)){  //处理创建订单页无限追加路由问题
              state.routerPaths.push(path);
            }else{
              console.log('---不塞--');
            }

          }else{
            state.routerPaths.push(path);
          }
        }
      }
      console.log('vuex路由栈：', state.routerPaths);
    },
    popRouterPath({commit, state}, param) {
      if (state.routerPaths.length > 1) {
        state.routerPaths.pop();
      }
      if (state.routerPaths.length >= 1) {
        router.push(state.routerPaths[state.routerPaths.length - 1]);
      }
    },
    popRouterPathOnly({commit, state}, param) {
      state.routerPaths.pop();
    },
    async loadLostOrders({commit, state}, param) {
      let params = {
        phone: param.phone,
        current: 1,
        size: 100
      };

      let {status, data, message} = await driverApi.loadLostOrders(params);
      if (status === '0') {
        let lostOrders = data;
        if (lostOrders) {

          lostOrders.forEach(lost => {
            // 订单时间
            if (_.isNumber(lost.createdOn)) {
              try {
                lost.createdOnStr = moment(lost.createdOn).format("YYYY-MM-DD HH:mm");
              } catch (e) {
                lost.createdOnStr = "-";
              }
            } else {
              lost.createdOnStr = "";
            }

            // 上下车时间
            lost.getOnDateStr = moment(lost.getOnDate).format("YYYY-MM-DD HH:mm");
            lost.getOffDateStr = moment(lost.getOffDate).format("YYYY-MM-DD HH:mm");

            try {
              if (_.isNumber(lost.handleStartDate)) {
                lost.handleStartDateStr = moment(lost.handleStartDate).format("YYYY-MM-DD HH:mm");
              } else if (_.isString(lost.handleStartDate)) {
                lost.handleStartDateStr = moment(lost.handleStartDate).format("YYYY-MM-DD HH:mm");
              }

              if (_.isNumber(lost.handleDate)) {
                lost.handleDateStr = moment(lost.handleDate).format("YYYY-MM-DD HH:mm");
              } else if (_.isString(lost.handleDate)) {
                lost.handleDateStr = moment(lost.handleDate).format("YYYY-MM-DD HH:mm");
              }

              if (lost.matchTime != null) {
                lost.matchTimeStr = moment(lost.matchTime).format("YYYY-MM-DD HH:mm");
              } else {
                lost.matchTimeStr = '';
              }
              if (lost.propertyConfirmTime != null) {
                lost.propertyConfirmTimeStr = moment(lost.propertyConfirmTime).format("YYYY-MM-DD HH:mm");
              } else {
                lost.propertyConfirmTimeStr = '';
              }

              if (lost.propertyReturnTime != null) {
                lost.propertyReturnTimeStr = moment(lost.propertyReturnTime).format("YYYY-MM-DD HH:mm");
              } else {
                lost.propertyReturnTimeStr = '';
              }

              if (lost.handleDate != null) {
                lost.handleDateStr = moment(lost.handleDate).format("YYYY-MM-DD HH:mm");
              } else {
                lost.handleDateStr = '';
              }
              if (lost.ownerConfirmTime != null) {
                lost.ownerConfirmDateStr = moment(lost.ownerConfirmTime).format("YYYY-MM-DD HH:mm");
              } else {
                lost.ownerConfirmDateStr = '';
              }

            } catch (e) {
              console.log(e);
            }

            lost.isHandled = lost.handleStatus === consts.LOST_HANDLE_STATUS_HANDLED;
            lost.isHandling = lost.handleStatus === consts.LOST_HANDLE_STATUS_HANDLING;
            lost.isUnhandled = lost.handleStatus === consts.LOST_HANDLE_STATUS_UNHANDLED;

            lost.isVip = _.isEqual(lost.payStatus, 1);
            if (_.isNumber(lost.payTime)) {
              lost.payVipDateStr = moment(lost.payTime).format("YYYY-MM-DD HH:mm");
            }
          });
        } else {
          lostOrders = [];
        }
        // lostOrders.sort((f, s) => {
        //   let fWeight = 0;
        //   if (f.handleStatus) {
        //     if (f.handleStatus === 0) {
        //       fWeight = 3;
        //     } else if (f.handleStatus === 1) {
        //       fWeight = 2;
        //     } else if (f.handleStatus === 2) {
        //       fWeight = 1;
        //     }
        //   } else {
        //     fWeight = 3;
        //   }
        //
        //   let sWeight = 0;
        //   if (s.handleStatus) {
        //     if (s.handleStatus === 0) {
        //       sWeight = 3;
        //     } else if (s.handleStatus === 1) {
        //       sWeight = 2;
        //     } else if (s.handleStatus === 2) {
        //       sWeight = 1;
        //     }
        //   } else {
        //     sWeight = 3;
        //   }
        //   return sWeight - fWeight;
        // });

        // let sortedLostOrders = _.sortBy(lostOrders, ['createdOn' ]);
        // commit("setLostOrders", _.reverse(lostOrders));
        commit("setLostOrders", lostOrders);
        return lostOrders;
      } else {
        return Promise.reject('加载订单失败，' + message);
      }
    },
    /**
     * 购买失物查找服务
     * @param commit
     * @param state
     * @param param
     */
    async prepayLostOrder({commit, state}, lostId) {
      let {status, data, message} = await driverApi.submitPay({lostId: lostId});
      if (status === '0') {
        return data;
      } else {
        return Promise.reject("创建支付订单失败：" + message);
      }
    },
    async loadLostServicePrice({commit}) {
      let {status, message, data} = await driverApi.loadServicePrice();
      if (status === '0') {
        commit('LOST_SERVICE_PRICE', data);
        return data;
      } else {
        return Promise.reject('获取失物查找服务价格失败' + message);
      }
    },

    async loadUserInfoByOpenId({commit}, param) {
      let {status, message, data} = await driverApi.loadUserInfoByOpenId(param);
      if (status === '0') {
        commit('USER_INFO', data);
        return data;
      } else {
        return Promise.reject("加载用户信息失败：" + message)
      }
    },
    async loadUserInfo({commit}, param) {

      console.log('loadUseInfo', param);

      let {status, message, data} = await driverApi.loadUserInfo({code: param.code});
      if (status === '0') {
        auth.setOpenId(data.openId);

        commit('USER_INFO', data);
        return data;
      } else {
        return Promise.reject('获取用户资料失败' + message);
      }

    },
    loadCarsInCircle({commit, state}, param) {
      return new Promise((resolve, reject) => {
        axios({
          url: 'api/taxi/vehicle/surround',
          baseURL: state.cheApiUrl,
          method: 'get',
          params: {
            lng: param.lng,
            lat: param.lat,
            distance: param.distance / 1000,
            groupId: 0
          },
        }).then(res => {
          if (res.data.status === 200) {
            let cars = res.data.data;
            if (!_.isEmpty(cars)) {

              cars.forEach(item => {
                item.distanceKM = geoutil.getDistance(param.lat, param.lng, parseFloat(item.y), parseFloat(item.x));
                item.distance = item.distanceKM * 1000;
              });

              cars.sort((first, snd) => {
                return first.distance - snd.distance;
              })
            }
            commit('setCars', res.data.data);
          } else {
            reject(res.data.message);
          }
        }).catch((reason) => {
          reject(reason);
        });
      })
    },
    //获取banner数据
    async getBannerList({commit, state}){
      try {
          const { status, data } = await taxiApi.loadActivities() 
          if(status == '0'){
              commit('SET_ACTIVITY_LIST', data);
              // console.log('活动轮播图数据', state.activityList);
          }
      
      } catch (error) {
          Toast.fail('获取轮播数据异常：', error)
          console.error('获取轮播数据异常：',error);
      }
  },
  },
});

export default store;
