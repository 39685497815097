<template>
    <div>
        <van-nav-bar :title="$route.meta.navTitle" fixed placeholder left-arrow @click-left="$router.back()"></van-nav-bar>
        <img class="bg-img" src="../../img/about-vip.png" style="width:100%" alt="">
        <div class="footer">
            <span class="footer_text" @click="returnMyOrder">查看我的订单</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "LostAboutVip",
        methods:{
            returnMyOrder(){
              this.$router.push('/lostList/null')
            }
        },
    }
</script>

<style scoped>
    .bg-img{
        display: block;
    }
    .footer{
        width:100%;
        height:60px;
        background:rgba(255,214,147,1);
        opacity:1;
        text-align: center;
        line-height: 60px;
        margin-bottom: 50px;
    }
    .footer_text{

        height:22px;
        font-size:16px;
        font-family:PingFang SC;
        /* font-weight:500; */
        line-height:22px;
        color:rgba(34,34,34,1);
        opacity:1;
    }
</style>
